import { PluginService } from "./index";

const bankService = PluginService("/payment/bank");

export default {
  getBankList(params) {
    return bankService({
      method: "GET",
      url: "/",
      params
    });
  },
  getBankById(id) {
    return bankService({
      method: "GET",
      url: `/${id}/`
    });
  },
  addNewBank(params) {
    return bankService({
      method: "POST",
      url: "/",
      data: params
    });
  },
  updateBank(params, id) {
    return bankService({
      method: "PUT",
      url: `/${id}/`,
      data: params
    });
  }
};
