<template>
  <v-dialog v-model="dialog" width="1200">
    <v-card class="text-center">
      <v-card-title
        class="oo-dialog-title justify-center headline"
        style="text-transform: capitalize;"
      >
        {{ $t("Notification Alert") }}
      </v-card-title>
      <v-card-text class="dialog-title">
        {{
          $t(
            "Notifications will be sent for the following invoices. Do you wish to proceed?"
          )
        }}
      </v-card-text>
      <v-card flat>
        <v-card-text class="dialog-table overflow-data">
          <v-data-table
            :headers="headers"
            :items="invoices"
            class="re-table"
            hide-actions
            :no-data-text="$t('No data available')"
          >
            <template v-slot:items="props">
              <tr>
                <td class="text-xs-center">
                  {{ props.item.contract_id }}
                </td>
                <td class="text-xs-center">
                  {{ formatDate(props.item.contract_start_date) }}
                </td>
                <td class="text-xs-center">
                  {{ formatDate(props.item.contract_end_date) }}
                </td>
                <td class="text-xs-center">
                  {{ formatDate(props.item.payment_due_date) }}
                </td>
                <td class="text-xs-center">
                  {{ props.item.net_amount }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-card-actions class="justify-center dialog-buttons text-capitalize">
        <v-btn
          class="confirm-btn btn-refund"
          color="refund-btn"
          text
          @click="$emit('acceptDialog')"
        >
          {{ $t("Accept") }}
        </v-btn>
        <v-btn class="cancel-btn" @click="$emit('cancelDialog')">
          {{ $t("Cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defaultFormat } from "../../helper";

export default {
  name: "invoice-notification-dialog",
  props: {
    showDialog: Boolean,
    invoices: {
      type: Array,
      default: () => []
    },
    isDialogLoading: Boolean
  },
  data() {
    return {
      dialog: this.showDialog
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("Contract ID"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Start Date"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("End Date"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Due Date"),
          align: "center",
          sortable: false
        },
        { text: this.$t("Net Amount"), align: "center", sortable: false }
      ];
    }
  },
  watch: {
    showDialog(value) {
      this.dialog = value;
    }
  },
  methods: {
    formatDate(value, containsTime) {
      return defaultFormat(value, containsTime);
    }
  }
};
</script>

<style scoped>
.v-dialog .headline {
  max-width: 100% !important;
}
.oo-dialog-title {
  background: #e97c18;
  color: white;
}
.refund-btn {
  background: #e97c18 !important;
  color: white !important;
}
.dialog-title {
  font-size: 14px;
  font-weight: 400;
  color: black;
}
.btn-refund {
  height: 36px !important;
  padding: 0 !important;
}
.overflow-data {
  max-height: 30rem;
  height: auto;
}
</style>
