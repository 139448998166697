<template>
  <v-dialog v-model="dialog" max-width="420">
    <v-card>
      <v-card-title
        class="headline d-flex justify-center form-title"
        primary-title
      >
        <span class="text-start"> {{ $t("Advance Payment") }} </span>
        <v-icon class="d-flex justify-end" @click="dialog = false">
          close
        </v-icon>
      </v-card-title>
      <v-tabs v-model="active" slider-color="blue">
        <v-tab v-for="tab in tabs" :key="tab.id" ripple>
          {{ tab.text }}
        </v-tab>
        <v-tab-item key="contract">
          <v-card flat>
            <v-card-text>
              <v-list dense>
                <v-list-tile>
                  <v-list-tile-content>{{
                    $t("Contract ID")
                  }}</v-list-tile-content>
                  <v-list-tile-content class="orange-c">{{
                    selectedPayment.contract_id
                  }}</v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>{{ $t("Created") }}</v-list-tile-content>
                  <v-list-tile-content>{{
                    formatDate(selectedPayment.created, true)
                  }}</v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>{{
                    $t("Initiator")
                  }}</v-list-tile-content>
                  <v-list-tile-content class="orange-c">{{
                    selectedPayment.initiator
                  }}</v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>{{
                    $t("Tenant Name")
                  }}</v-list-tile-content>
                  <v-list-tile-content class="orange-c">{{
                    selectedPayment.tenant_name
                  }}</v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>{{
                    $t("Property")
                  }}</v-list-tile-content>
                  <v-list-tile-content>{{
                    selectedPayment.property_name
                  }}</v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>{{ $t("Units") }}</v-list-tile-content>
                  <v-list-tile-content>{{
                    selectedPayment.unit_number
                  }}</v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>{{
                    $t("Contract Reference")
                  }}</v-list-tile-content>
                  <v-list-tile-content>{{
                    selectedPayment.contract_reference
                  }}</v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>{{
                    $t("Contract Start Date")
                  }}</v-list-tile-content>
                  <v-list-tile-content>{{
                    formatDate(selectedPayment.contract_start_date)
                  }}</v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>{{
                    $t("Contract End Date")
                  }}</v-list-tile-content>
                  <v-list-tile-content>{{
                    formatDate(selectedPayment.contract_end_date)
                  }}</v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>{{
                    $t("Net Amount")
                  }}</v-list-tile-content>
                  <v-list-tile-content
                    >{{ selectedPayment.net_amount }}
                  </v-list-tile-content>
                </v-list-tile>
                <!-- <v-list-tile>
                  <v-list-tile-content>{{ $t("Status") }}</v-list-tile-content>
                  <v-list-tile-content :class="{ ongoing: true }">
                    {{ selectedPayment.status }}
                  </v-list-tile-content>
                </v-list-tile> -->
                <!-- Is Manual Payment -->
                <v-list-tile>
                  <v-list-tile-content>{{
                    $t("Is Manual Payment")
                  }}</v-list-tile-content>
                  <v-list-tile-content>
                    <v-checkbox
                      v-model="selectedPayment.is_manual_payment"
                      disabled
                    />
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>{{
                    $t("Payment Method")
                  }}</v-list-tile-content>
                  <v-list-tile-content>{{
                    selectedPayment.payment_method
                  }}</v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>{{ $t("Bank") }}</v-list-tile-content>
                  <v-list-tile-content>{{
                    selectedPayment.bank?.bank_name
                  }}</v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>{{
                    $t("Cheque Number")
                  }}</v-list-tile-content>
                  <v-list-tile-content>{{
                    selectedPayment.cheque_number
                  }}</v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>{{
                    $t("Cheque Date")
                  }}</v-list-tile-content>
                  <v-list-tile-content>{{
                    formatDate(selectedPayment.cheque_date)
                  }}</v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>{{
                    $t("Cash Treasury")
                  }}</v-list-tile-content>
                  <v-list-tile-content>{{
                    selectedPayment.cash_treasury?.cash_treasury_name
                  }}</v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>{{
                    $t("Reference Number")
                  }}</v-list-tile-content>
                  <v-list-tile-content>{{
                    selectedPayment.reference_number
                  }}</v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>{{
                    $t("Receipt Voucher Number")
                  }}</v-list-tile-content>
                  <v-list-tile-content>{{
                    selectedPayment.receipt_voucher_reference
                  }}</v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item key="invoices">
          <v-data-table
            :headers="headers"
            :items="paidMonthYearList"
            item-key="payment_due_date"
            hide-actions
          >
            <template v-slot:items="props">
              <tr>
                <td class="text-xs-center">
                  {{ formatDate(props.item.payment_due_date) }}
                </td>
                <td class="text-xs-center">
                  {{ props.item.net_amount }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-dialog>
</template>

<script>
import { defaultFormat, reformatInvoicesListWithDecimals } from "../../helper";

export default {
  name: "advance-list-dialogue",
  props: ["value", "selectedPayment"],
  emits: ["input"],
  data: () => ({
    dialog: false,
    active: null
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("Due Date"), align: "center", sortable: false },
        { text: this.$t("Net Amount"), align: "center", sortable: false }
      ];
    },
    tabs() {
      return [
        { text: this.$t("Contract"), id: 1 },
        { text: this.$t("Paid Invoices"), id: 2 }
      ];
    },
    paidMonthYearList() {
      return reformatInvoicesListWithDecimals(
        this.selectedPayment.paid_month_year || []
      );
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.dialog = val;
      }
    },
    dialog(val) {
      if (!val) {
        this.$emit("input", false);
      }
    }
  },
  methods: {
    formatDate(value, addTime) {
      return defaultFormat(value, addTime);
    }
  }
};
</script>

<style scoped>
.v-list__tile__content {
  flex: 1 1 50% !important;
}
</style>
