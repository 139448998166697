<template>
  <div class="manual-payment">
    <v-layout class="pt-5">
      <v-flex grow pa-1 xs6>
        <h2 class="display-2 pl-4">{{ $t("Properties") }}</h2>
      </v-flex>
      <v-flex shrink pa-1 xs6 text-xs-right>
        <v-btn dark class="mt-4 r-m-end" @click="globalGoBack"
          >← {{ $t("Back") }}</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout row class="r-p-24px">
      <v-flex class="flex-wrap" xs12 pa-1>
        <v-breadcrumbs :items="breadItemsSetLang()" class="pl-4">
          <template v-slot:divider>
            <v-icon>fiber_manual_record</v-icon>
          </template>
        </v-breadcrumbs>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-layout row wrap justify-center>
        <v-flex xs12 sm12 lg9>
          <v-card v-show="!isLoading">
            <v-card-title>
              <h3>{{ $t("Manual Payment") }}</h3>
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-autocomplete
                  v-model="payment.property"
                  v-validate="'required'"
                  :label="$t('Property Name')"
                  item-text="property_name"
                  item-value="id"
                  data-vv-name="property_name"
                  :disabled="disableContractSelectors"
                  :error-messages="errors.collect('property_name')"
                  :items="properties"
                  :no-data-text="
                    `${
                      isLoadingProperty
                        ? $t('Loading...')
                        : $t('No data available')
                    }`
                  "
                  @input.native="fetchProperties($event.target.value)"
                  @change="loadContracts()"
                />

                <v-autocomplete
                  v-model="payment.tenant"
                  v-validate="'required'"
                  item-text="tenant_name"
                  item-value="id"
                  data-vv-name="tenant_name"
                  :disabled="disableContractSelectors"
                  :label="$t('Tenant Name')"
                  :error-messages="errors.collect('tenant_name')"
                  :items="tenants"
                  :no-data-text="
                    `${
                      isLoadingTenants
                        ? $t('Loading...')
                        : $t('No data available')
                    }`
                  "
                  @input.native="fetchTenants($event.target.value)"
                  @change="loadContracts()"
                />

                <v-autocomplete
                  v-model="payment.contract"
                  pb-3
                  :label="$t('Contracts')"
                  data-vv-name="contract"
                  item-text="id"
                  item-value="id"
                  :disabled="disableContractSelectors"
                  :items="contracts"
                  @input.native="fetchContracts"
                  :no-data-text="
                    `${
                      isLoadingContracts
                        ? $t('Loading...')
                        : $t('No data available')
                    }`
                  "
                  :error-messages="errors.collect('contracts')"
                  @change="setContract()"
                >
                  <template v-slot:selection="data">
                    <span>
                      {{ $t("Contract ID") }}: {{ data.item.id }},
                      {{ $t("from date") }}:
                      {{ formatDate(data.item.contract_start_date) }}
                      {{ $t("to date") }}:
                      {{ formatDate(data.item.contract_end_date) }}
                    </span>
                  </template>

                  <template v-slot:item="data">
                    <span>
                      {{ $t("Contract ID") }}: {{ data.item.id }},
                      {{ $t("from date") }}:
                      {{ formatDate(data.item.contract_start_date) }}
                      {{ $t("to date") }}:
                      {{ formatDate(data.item.contract_end_date) }}
                    </span>
                  </template>
                </v-autocomplete>

                <SelectedUnits
                  :selectedUnits="selectedUnits"
                  :units="selectedUnits"
                  disableUnitsSelect
                />
                <v-select
                  v-model="selectedInvoice"
                  :label="$t('Not Paid Invoices')"
                  required
                  :disabled="disableInvoices"
                  :items="notPaidInvoices"
                  item-text="payment_due_date"
                  return-object
                  :no-data-text="
                    `${
                      isLoadingInvoices
                        ? $t('Loading...')
                        : $t('No data available')
                    }`
                  "
                  @change="setPayment()"
                />

                <date-picker
                  v-model="payment.payment_date"
                  v-validate="'required'"
                  :label="$t('Payment Date')"
                  data-vv-name="payment_date"
                  :changed="payment.payment_date"
                  :initialValue="payment.payment_date"
                  required
                  :error-messages="errors.collect('payment_date')"
                />
                <v-select
                  v-model="payment.payment_method"
                  :label="$t('Payment Methods')"
                  required
                  item-text="display_name"
                  item-value="value"
                  :items="allowedPaymentMethods"
                  @change="clearPaymentMethodDetails"
                  v-validate="'required'"
                  data-vv-name="payment_method"
                  :error-messages="errors.collect('payment_method')"
                />
                <v-autocomplete
                  v-model="payment.bank"
                  :label="$t('Bank')"
                  clearable
                  :items="banks"
                  item-text="bank_name"
                  item-value="id"
                  :no-data-text="
                    `${
                      isLoadingBanks
                        ? $t('Loading...')
                        : $t('No data available')
                    }`
                  "
                  @input.native="fetchBanks($event.target.value)"
                  v-validate="{
                    required: isChequePayment || isBankTransferPayment
                  }"
                  data-vv-name="bank"
                  :disabled="isCashPayment || isDigitalPayment"
                  :error-messages="errors.collect('bank')"
                />
                <v-text-field
                  v-model="payment.cheque_number"
                  :label="$t('Cheque No')"
                  v-validate="{ required: isChequePayment }"
                  data-vv-name="cheque_number"
                  :disabled="
                    isCashPayment || isBankTransferPayment || isDigitalPayment
                  "
                  :error-messages="errors.collect('cheque_number')"
                />
                <date-picker
                  v-model="payment.cheque_date"
                  :label="$t('Cheque Date')"
                  v-validate="{ required: isChequePayment }"
                  data-vv-name="cheque_date"
                  :changed="payment.cheque_date"
                  :error-messages="errors.collect('cheque_date')"
                  :disabled="
                    isCashPayment || isBankTransferPayment || isDigitalPayment
                  "
                />
                <v-autocomplete
                  v-model="payment.cash_treasury"
                  :label="$t('Cash Treasury')"
                  clearable
                  :items="cashTreasuries"
                  item-text="cash_treasury_name"
                  item-value="id"
                  :no-data-text="
                    `${
                      isLoadingCashTreasuries
                        ? $t('Loading...')
                        : $t('No data available')
                    }`
                  "
                  @input.native="fetchCashTreasuries($event.target.value)"
                  v-validate="{ required: isCashPayment }"
                  data-vv-name="cash_treasury"
                  :disabled="
                    isChequePayment || isBankTransferPayment || isDigitalPayment
                  "
                  :error-messages="errors.collect('cash_treasury')"
                />
                <v-text-field
                  v-model="payment.reference_no"
                  :label="$t('Reference No')"
                />
                <v-text-field
                  v-model="payment.net_amount"
                  :label="$t('Net Amount')"
                  disabled
                />
                <v-text-field
                  required
                  data-vv-name="voucher_reference"
                  v-validate="'required|alpha_num'"
                  v-model="payment.receipt_voucher_reference"
                  :error-messages="errors.collect('voucher_reference')"
                  :label="$t('Receipt Voucher Reference')"
                />
              </v-form>
            </v-card-text>
          </v-card>
          <confirmation-dialog
            v-model="isConfirmed"
            :label="$t('Confirmation')"
            :message="
              $t('Are you sure that you want to do this manual transaction?')
            "
            @acceptDialog="storePayment"
          />
          <vue-skeleton-loader
            rounded
            class="w-full"
            type="rect"
            v-if="isLoading"
            :height="'700px'"
            :width="'100%'"
            animation="fade"
          />
        </v-flex>
      </v-layout>
    </v-layout>
    <div class="text-xs-center mt-4">
      <v-btn
        v-if="!isLoading"
        :disabled="!hasPayment"
        style="color:white!important"
        class="re-orangtbtn"
        @click="confirmPayment"
      >
        {{ $t("Save") }}
      </v-btn>
      <v-btn v-if="!isLoading" dark @click="globalGoBack">
        {{ $t("Cancel") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import moment from "moment";

import SelectedUnits from "../components/Contract/SelectedUnits.vue";
import ConfirmationDialog from "../components/dialog/ConfirmationDialog.vue";
import DatePicker from "../components/picker/DatePicker.vue";
import { ContractEnums } from "../enums";
import {
  formatDateForPicker,
  transformContractUnit,
  defaultFormat,
  reformatInvoiceDetailsWithDecimals,
  reformatInvoicesListWithDecimals
} from "../helper";
import bankService from "../services/plugins/bank";
import cashTreasuryService from "../services/plugins/cash_treasury";
import contractService from "../services/plugins/contract";
import paymentService from "../services/plugins/payment";
import propertyService from "../services/plugins/properties";
import tenantServices from "../services/plugins/tenant";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";
import paginationMixin from "@/mixins/paginationMixin";

export default {
  name: "ManualPayment",
  $_veeValidate: {
    validator: "new"
  },
  mixins: [breadItemsSetLang, paginationMixin],
  components: {
    DatePicker,
    ConfirmationDialog,
    SelectedUnits
  },
  data: () => ({
    properties: [],
    tenants: [],
    hasPayment: false,
    isConfirmed: false,
    units: [],
    disableContractSelectors: false,
    disableInvoices: false,
    payment: {
      payment_method: ""
    },
    isLoading: false,
    isLoadingProperty: false,
    isLoadingTenants: false,
    isLoadingBanks: false,
    isLoadingCashTreasuries: false,
    isLoadingContracts: false,
    isLoadingInvoices: false,
    fetchProperties: null,
    fetchTenants: null,
    fetchBanks: null,
    fetchCashTreasuries: null,
    fetchContracts: null,
    notPaidInvoices: [],
    paymentMethods: [],
    contracts: [],
    selectedUnits: [
      {
        unit_id: "--",
        unit_number: "--",
        unit_type_id: "--"
      }
    ],
    contractPaymentMethods: []
  }),
  watch: {
    "$i18n.locale": function() {
      this.$validator.localize(this.$i18n.locale, this.dictionary);
    }
  },
  async created() {
    this.fetchProperties = debounce(this.loadProperties, 500);
    this.fetchTenants = debounce(this.loadTenants, 500);
    this.fetchBanks = debounce(this.loadBanks, 500);
    this.fetchCashTreasuries = debounce(this.loadCashTreasuries, 500);
    this.fetchContracts = debounce(this.loadContracts, 500);

    this.isLoading = true;
    this.$validator.localize("en", this.dictionary);
    this.fetchPaymentOptions();
    const params = this.$route.params;

    this.loadBanks();
    this.loadCashTreasuries();

    if (params.contract_id) {
      this.disableContractSelectors = true;
      await this.fetchContractById(params.contract_id);
      await this.fetchNotPaidInvoices();
    } else {
      this.loadTenants();
      this.loadProperties();
    }

    if (params.isRent) {
      this.disableInvoices = true;
      this.selectedInvoice = this.notPaidInvoices.find(e => {
        return (
          e.payment_year == params.payment_year &&
          e.payment_month ==
            moment()
              .month(params.payment_month)
              .format("M")
        );
      });
      this.setPayment();
    }
    this.isLoading = false;
  },
  mounted() {
    this.payment.payment_date = moment(this.payment.payment_date).format(
      "YYYY-MM-DD"
    );
  },
  computed: {
    dictionary() {
      return {
        custom: {
          property_name: {
            required: () => this.$t("Property name is required")
          },
          unit_number: {
            required: () => this.$t("Unit number is required")
          },
          tenant_name: {
            required: () => this.$t("Tenant name is required")
          },
          payment_month: {
            required: () => this.$t("Payment month is required")
          },
          payment_year: {
            required: () => this.$t("Payment year is required")
          },
          payment_date: {
            required: () => this.$t("Payment Date is required")
          },
          voucher_reference: {
            required: () =>
              this.$t(
                "Reference number for the receipt voucher is required when performing manual payment"
              )
          },
          bank: {
            required: () => this.$t("Bank is required")
          },
          cash_treasury: {
            required: () => this.$t("Cash treasury is required")
          },
          cheque_date: {
            required: () => this.$t("Cheque date is required")
          },
          cheque_number: {
            required: () => this.$t("Cheque number is required")
          },
          payment_method: {
            required: () => this.$t("Payment method is required")
          }
        }
      };
    },
    allowedPaymentMethods() {
      return this.paymentMethods.filter(e =>
        this.contractPaymentMethods.includes(e.value)
      );
    },
    isCashPayment() {
      return this.payment.payment_method === "cash";
    },
    isChequePayment() {
      return this.payment.payment_method === "cheque";
    },
    isBankTransferPayment() {
      return this.payment.payment_method === "bank transfer";
    },
    isDigitalPayment() {
      return ["payment link", "pos (debit/ credit)"].includes(
        this.payment.payment_method
      );
    }
  },
  methods: {
    formatDate(value, containsTime) {
      return defaultFormat(value, containsTime);
    },
    async confirmPayment() {
      if (await this.$validator.validateAll()) {
        this.isConfirmed = true;
      }
    },
    async storePayment() {
      try {
        this.isLoading = true;
        const params = {
          ...this.payment,
          payment_date: formatDateForPicker(this.payment.payment_date, true),
          is_manual_payment: true,
          reason: ContractEnums.ManualPayment
        };
        await this.$processReq(paymentService.updatePayment(params, params.id));
        this.isLoading = false;
        this.globalGoBack();
      } catch (error) {
        this.isLoading = false;
        console.log("Error: Unable to update store payment - ", error);
      }
    },
    async fetchPropertyById(id) {
      try {
        const response = await this.$processReq(
          propertyService.getPropertyDetails(id),
          false
        );

        this.properties = [response.data?.body];
      } catch (err) {
        console.log("Error: Unable to get property details - ", err);
      }
    },
    async loadProperties(search) {
      this.isLoadingProperty = true;
      try {
        const params = {
          ...this.setPaginationParams(),
          search
        };

        const resp = await this.$processReq(
          propertyService.getPropertiesList(params),
          false
        );

        this.properties = resp.data?.body?.results;
      } catch (error) {
        console.log("Error: Unable to get property list - ", error);
      }
      this.isLoadingProperty = false;
    },
    async loadTenants(search) {
      this.isLoadingTenants = true;
      try {
        const params = {
          ...this.setPaginationParams(),
          search
        };

        const resp = await this.$processReq(
          tenantServices.getTenantsList(params),
          false
        );

        this.tenants = resp.data?.body?.results;
      } catch (error) {
        console.log("Error: Unable to get tenant list - ", error);
      }
      this.isLoadingTenants = false;
    },
    async loadBanks(search) {
      this.isLoadingBanks = true;
      try {
        const params = {
          ...this.setPaginationParams(),
          search
        };

        const resp = await this.$processReq(
          bankService.getBankList(params),
          false
        );

        this.banks = resp.data?.body?.results;
      } catch (error) {
        console.log("Error: Unable to get bank list - ", error);
      }
      this.isLoadingBanks = false;
    },
    async loadCashTreasuries(search) {
      this.isLoadingCashTreasuries = true;
      try {
        const params = {
          ...this.setPaginationParams(),
          search
        };

        const resp = await this.$processReq(
          cashTreasuryService.getCashTreasuryList(params),
          false
        );

        this.cashTreasuries = resp.data?.body?.results;
      } catch (error) {
        console.log("Error: Unable to get tresury list - ", error);
      }
      this.isLoadingCashTreasuries = false;
    },
    async fetchContractById(contract_id) {
      try {
        const res = await this.$processReq(
          contractService.fetchContractById(contract_id),
          false
        );

        const result = res.data.body;

        this.properties = [result.property];
        this.tenants = [result.tenant];
        this.contracts = [result];
        this.payment.contract = result.id;

        this.payment.property = result.property.id;
        this.payment.tenant = result.tenant?.id;
        this.contractPaymentMethods = result.allowed_pyament_methodes;
        this.payment.payment_method = this.contractPaymentMethods[0]
          ? this.contractPaymentMethods[0]
          : null;
        if (result.contractunit_set?.length) {
          this.selectedUnits = transformContractUnit(result.contractunit_set);
        }
      } catch (err) {
        console.log("Error: Unable to fetch contract by ID - ", err);
      }
    },
    async loadContracts(event) {
      this.isLoadingContracts = true;
      if (this.payment.property && this.payment.tenant) {
        try {
          this.resetPaymentDetails();
          this.contracts = [];
          const params = {
            property: this.payment.property,
            tenant: this.payment.tenant,
            id: event?.target?.value
          };
          const res = await this.$processReq(
            contractService.fetchContractListMiniDetails(params),
            false
          );

          this.contracts = res.data?.body?.results;
        } catch (err) {
          console.log("Error: Unable to fetch contracts - ", err);
        }
      }
      this.isLoadingContracts = false;
    },
    async checkPayment() {
      try {
        this.isLoading = true;
        let params = {
          contract_id: this.payment.contract,
          payment_month: this.payment.payment_month,
          payment_year: this.payment.payment_year
        };

        const resp = await this.$processReq(
          paymentService.checkPayment(params),
          false
        );

        const data = reformatInvoiceDetailsWithDecimals(resp.data?.body);

        if (!data.id) {
          this.hasPayment = false;
        } else {
          this.hasPayment = true;
          this.payment.amount = data.amount;
          this.payment.net_amount = data.net_amount;
          this.payment.id = data.id;
        }

        this.isLoading = false;
      } catch (err) {
        this.hasPayment = false;
        this.isLoading = false;
        console.log("Error: Unable to check payment - ", err);
      }
    },
    async fetchNotPaidInvoices() {
      this.isLoadingInvoices = true;
      try {
        this.selectedInvoice = null;
        const status = ["Expired", "Pending"];

        const params = {
          is_advanced_payment: 0,
          is_advanced_payment_while_contracting: 0,
          is_grace_period: 0,
          is_missed: 0,
          is_open_balance: 0,
          status: status.join()
        };
        const resp = await this.$processReq(
          paymentService.getContractPayments(this.payment.contract, params),
          false
        );
        const notPaidInvoicesList = resp.data?.body || [];
        this.notPaidInvoices = reformatInvoicesListWithDecimals(
          notPaidInvoicesList
        );
      } catch (err) {
        console.log("Error: Unable to fetch invoices - ", err);
      }
      this.isLoadingInvoices = false;
    },
    async fetchPaymentOptions() {
      try {
        const resp = await this.$processReq(
          paymentService.getPaymentOptions(),
          false
        );
        this.paymentMethods =
          resp.data?.body?.actions?.POST?.payment_method?.choices;
      } catch (err) {
        console.log("Error: Unable to fetch payment options - ", err);
      }
    },
    setPayment() {
      this.payment.payment_month = this.selectedInvoice.payment_month;
      this.payment.payment_year = this.selectedInvoice.payment_year;
      this.payment.discount = this.selectedInvoice.discount;
      this.payment.paid_amount_before_discount = this.selectedInvoice.paid_amount_before_discount;
      this.checkPayment();
    },
    async setContract() {
      if (!this.payment.contract) {
        this.resetContractDetails();
        return;
      }
      try {
        this.isLoading = true;
        const res = await this.$processReq(
          contractService.fetchContractById(this.payment.contract),
          false
        );

        const result = res.data.body;
        await this.$nextTick();
        if (result?.contractunit_set?.length) {
          this.selectedUnits = transformContractUnit(result.contractunit_set);
        }
        this.contractPaymentMethods = result.allowed_pyament_methodes;
        this.payment.payment_method = this.contractPaymentMethods[0]
          ? this.contractPaymentMethods[0]
          : null;
        this.fetchNotPaidInvoices();
      } catch (err) {
        this.payment.contract = "";
        this.resetContractDetails();
        console.log("Error: Unable to fetch contract by ID - ", err);
      }
      this.isLoading = false;
    },
    resetPaymentDetails() {
      this.selectedInvoice = null;
      this.selectedUnits = [
        {
          unit_id: "--",
          unit_number: "--",
          unit_type_id: "--"
        }
      ];
      this.payment.contract = null;
      this.payment.discount = null;
      this.payment.paid_amount_before_discount = null;
      this.contractPaymentMethods = [];
    },
    clearPaymentMethodDetails() {
      if (this.isCashPayment) {
        this.payment.bank = null;
        this.payment.cheque_date = null;
        this.payment.cheque_number = null;
      } else if (this.isChequePayment) {
        this.payment.cash_treasury = null;
      } else if (this.isBankTransferPayment) {
        this.payment.cheque_date = null;
        this.payment.cheque_number = null;
        this.payment.cash_treasury = null;
      } else if (this.isDigitalPayment) {
        this.payment.bank = null;
        this.payment.cheque_date = null;
        this.payment.cheque_number = null;
        this.payment.cash_treasury = null;
      }
    }
  }
};
</script>
