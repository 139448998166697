import { PluginService } from "./index";

const propertyService = PluginService("/properties");
const cityService = PluginService("/cities");
const regionService = PluginService("/regions");
const subRegionService = PluginService("/subregions");

export default {
  getPropertiesList(params) {
    return propertyService({
      method: "GET",
      url: "/",
      params
    });
  },
  getPropertyDetails(id) {
    return propertyService({
      method: "GET",
      url: `/${id}`
    });
  },
  addNewProperty(data) {
    return propertyService({
      method: "POST",
      url: "/",
      data
    });
  },
  updateProperty(data, id) {
    return propertyService({
      method: "PUT",
      url: `/${id}/`,
      data
    });
  },
  getRegionsList(params) {
    return regionService({
      method: "GET",
      url: "/",
      params
    });
  },
  getCitiesList(params) {
    return cityService({
      method: "GET",
      url: "/",
      params
    });
  },
  getSubRegionsList(params) {
    return subRegionService({
      method: "GET",
      url: "/",
      params
    });
  },
  getPopertyContractsList(id) {
    return propertyService({
      method: "GET",
      url: `/${id}/contracts/`
    });
  },
  getPopertyUnitsList(id, params) {
    return propertyService({
      method: "GET",
      url: `/${id}/units/`,
      params
    });
  }
};
