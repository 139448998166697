<template>
  <v-card>
    <v-card-title>
      <h3>{{ $t("Plugin Configuration") }}</h3>
    </v-card-title>
    <v-card-text>
      <v-form data-vv-scope="plugin-config">
        <v-text-field
          v-model="payment_gen_day"
          v-validate="
            `required|numeric|between:${
              (parseInt(payment_suspend_day) || 0) + 1
            },28` // the value shouldn't be greater than 28
          "
          required
          class="mb-2"
          data-vv-name="genarate_payment_links"
          :label="$t('Day of the month to generate payment links')"
          :error-messages="
            errors.collect('plugin-config.genarate_payment_links')
          "
        />

        <v-text-field
          v-model="payment_suspend_day"
          v-validate="'required|numeric|min_value:1|max_value:31'"
          required
          class="mb-2"
          data-vv-name="suspend_payment_links"
          :label="$t('Day of the month to suspend accessing payment links')"
          :error-messages="
            errors.collect('plugin-config.suspend_payment_links')
          "
        />

        <v-text-field
          v-model="number_of_days_of_stopping"
          v-validate="`required|between:0,${parseInt(payment_suspend_day) - 1}`"
          data-vv-name="number_of_days_of_stopping"
          class="mb-2"
          :label="
            $t(
              'Number of days of stopping the generation worker before the expiration day'
            )
          "
          :error-messages="
            errors.collect('plugin-config.number_of_days_of_stopping')
          "
        />

        <v-select
          v-model="activation_month"
          clearable
          v-validate="`${is_activated ? 'required' : ''}`"
          data-vv-name="activation_month"
          :disabled="disableActivation"
          :label="$t('Activation Month')"
          :items="months"
          :error-messages="errors.collect('plugin-config.activation_month')"
        />

        <v-select
          v-model="activation_year"
          v-validate="`${is_activated ? 'required' : ''}`"
          data-vv-name="activation_year"
          clearable
          :disabled="disableActivation"
          :items="getYears()"
          :label="$t('Activation Year')"
          :error-messages="errors.collect('plugin-config.activation_year')"
        />

        <v-checkbox
          v-model="is_activated"
          :label="$t('Is Activated')"
          :disabled="disableActivation"
        />
        <v-checkbox
          v-model="is_manual_payment_generate"
          :label="$t('Manual generating payment records')"
        />
        <v-checkbox v-model="is_vat_enabled" :label="$t('Is Vat Enabled')" />
        <span> {{ $t("Due Date") }}: </span>
        <v-layout class="align-center mb-2 ml-3">
          <v-radio-group v-model="due_date_of_the_month">
            <v-radio
              v-for="option in options.due_date_of_the_month?.choices"
              :label="option.display_name"
              :value="option.value"
              :key="option.value"
              :disabled="disableActivation"
              @change="validatePluginConfigSelections()"
            />
          </v-radio-group>
        </v-layout>
        <v-select
          v-model="payment_request_expires_for_all_contracts"
          :items="options.payment_request_expires_for_all_contracts?.choices"
          item-text="display_name"
          item-value="value"
          :item-disabled="
            option =>
              this.due_date_of_the_month === 'any day of the month' &&
              ['expiry for all contracts', 'case by case'].includes(
                option.value
              )
          "
          :label="$t('Expiry for contracts')"
          @change="validatePluginConfigSelections(true)"
        />
        <v-select
          v-model="payment_in_sequence_for_all_contracts"
          :items="options.payment_in_sequence_for_all_contracts?.choices"
          item-text="display_name"
          item-value="value"
          :label="$t('Payment in Sequence')"
          @change="validatePluginConfigSelections(true)"
        />
        <v-select
          v-model="country"
          v-validate="'required'"
          clearable
          data-vv-name="country"
          item-text="display_name"
          item-value="value"
          :items="options.country?.choices"
          :label="$t('Country')"
          :disabled="true"
          :error-messages="errors.collect('country')"
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";

import { getMonthLabels } from "../../lib/date_time";

export default {
  name: "plugin-configuration",
  emits: ["input"],
  props: {
    value: Object,
    disableActivation: Boolean,
    options: Object
  },
  inject: ["parentValidator"],
  created() {
    this.$validator = this.parentValidator;
  },
  data() {
    return {
      months: getMonthLabels(),
      isPluginConfigValid: true
    };
  },
  methods: {
    getYears() {
      const min = new Date().getFullYear();
      const max = min + 20;
      const years = [];

      for (let i = min; i <= max; i++) {
        years.push(i);
      }
      return years;
    },
    async validatePluginConfigSelections(isPaymentSequenceSelection) {
      let isPaymentSequenceValid = true;
      let isDueDateValid = true;

      await this.$nextTick();
      if (
        this.payment_request_expires_for_all_contracts ===
          "expiry for all contracts" &&
        this.payment_in_sequence_for_all_contracts ===
          "no payment in sequence for all contracts"
      ) {
        isPaymentSequenceValid = false;
        if (isPaymentSequenceSelection) {
          this.$root.$emit("snackbar", {
            text: this.$t(
              "Expiry on all contracts cannot be selected with no sequence"
            ),
            color: "error"
          });
        }
      }

      if (
        ["expiry for all contracts", "case by case"].includes(
          this.payment_request_expires_for_all_contracts
        ) &&
        this.due_date_of_the_month === "any day of the month"
      ) {
        isDueDateValid = false;
        if (!isPaymentSequenceSelection) {
          this.$root.$emit("snackbar", {
            text: this.$t(
              "Expiry on all contracts or case by case cannot be selected with due date any day of the month"
            ),
            color: "error"
          });
        }
      }
      this.isPluginConfigValid = isDueDateValid && isPaymentSequenceValid;
    }
  },
  computed: {
    payment_suspend_day: {
      get() {
        return this.value.payment_suspend_day;
      },
      set(val) {
        this.$emit("input", { ...this.value, payment_suspend_day: val });
      }
    },
    payment_gen_day: {
      get() {
        return this.value.payment_gen_day;
      },
      set(val) {
        this.$emit("input", { ...this.value, payment_gen_day: val });
      }
    },
    is_manual_payment_generate: {
      get() {
        return this.value.is_manual_payment_generate;
      },
      set(val) {
        this.$emit("input", { ...this.value, is_manual_payment_generate: val });
      }
    },
    number_of_days_of_stopping: {
      get() {
        return this.value.number_of_days_of_stopping;
      },
      set(val) {
        this.$emit("input", { ...this.value, number_of_days_of_stopping: val });
      }
    },
    activation_month: {
      get() {
        return (
          this.value.activation_month &&
          moment()
            .month(parseInt(this.value.activation_month) - 1)
            .format("MMM")
        );
      },
      async set(val) {
        await this.$nextTick();
        this.$emit("input", {
          ...this.value,
          activation_month: val
            ? parseInt(moment().month(val).format("M"))
            : null
        });
        await this.$nextTick();
      }
    },
    activation_year: {
      get() {
        return this.value.activation_year;
      },
      async set(val) {
        this.$emit("input", { ...this.value, activation_year: val || null });
      }
    },
    profile_count_limit: {
      get() {
        return this.value.profile_count_limit;
      },
      async set(val) {
        this.$emit("input", {
          ...this.value,
          profile_count_limit: val || null
        });
      }
    },
    is_activated: {
      get() {
        return this.value.is_activated;
      },
      set(val) {
        this.$emit("input", { ...this.value, is_activated: val });
      }
    },
    due_date_of_the_month: {
      get() {
        return this.value.due_date_of_the_month || null;
      },
      set(val) {
        this.$emit("input", { ...this.value, due_date_of_the_month: val });
      }
    },
    payment_in_sequence_for_all_contracts: {
      get() {
        return this.value.payment_in_sequence_for_all_contracts;
      },
      set(val) {
        this.$emit("input", {
          ...this.value,
          payment_in_sequence_for_all_contracts: val
        });
      }
    },
    payment_request_expires_for_all_contracts: {
      get() {
        return this.value.payment_request_expires_for_all_contracts;
      },
      set(val) {
        this.$emit("input", {
          ...this.value,
          payment_request_expires_for_all_contracts: val
        });
      }
    },
    country: {
      get() {
        return this.value.country;
      },
      set(val) {
        this.$emit("input", { ...this.value, country: val });
      }
    },
    is_vat_enabled: {
      get() {
        return this.value.is_vat_enabled;
      },
      set(val) {
        this.$emit("input", { ...this.value, is_vat_enabled: val });
      }
    }
  },
  watch: {
    isPluginConfigValid() {
      this.$emit("isValidConfig", this.isPluginConfigValid);
    }
  }
};
</script>
