<template>
  <div class="new-contract">
    <v-layout class="pt-5">
      <v-flex grow pa-1 xs6>
        <h2 class="display-2 pl-4">{{ $t("Property") }}</h2>
      </v-flex>
      <v-flex shrink pa-1 xs6 text-xs-right>
        <v-btn dark class="mt-4 r-m-end" @click="globalGoBack"
          >← {{ $t("Back") }}</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout class="re-mm-24px">
      <v-flex class="flex-wrap" xs12 pa-1>
        <v-breadcrumbs :items="breadItemsSetLang()" class="pl-4">
          <template v-slot:divider>
            <v-icon>fiber_manual_record</v-icon>
          </template>
        </v-breadcrumbs>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-layout row wrap justify-center>
        <v-flex xs12 sm12 lg9>
          <v-card v-show="!isLoading">
            <v-tabs v-model="tab" @change="onTabChange">
              <v-tab>
                <v-card-title>
                  <h3>{{ $t("Renew Contract") }}</h3>
                </v-card-title>
              </v-tab>
              <v-tab :disabled="isLoadingInvoices">
                <v-card-title>
                  <h3>{{ $t("Invoices") }}</h3>
                </v-card-title>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card-text>
                  <v-form data-vv-scope="renew_contract_scope">
                    <v-autocomplete
                      v-model="contract.property"
                      v-validate="'required'"
                      :label="$t('Property Name')"
                      data-vv-name="property"
                      item-text="property_name"
                      item-value="id"
                      clearable
                      :disabled="disableContractSelectors"
                      :error-messages="
                        errors.collect('renew_contract_scope.property')
                      "
                      :items="properties"
                      @input.native="fetchProperties"
                      :no-data-text="`${
                        isLoadingProperty
                          ? $t('Loading...')
                          : $t('No data available')
                      }`"
                      @change="getPropertyTenants()"
                    />
                    <v-autocomplete
                      v-model="contract.tenant"
                      v-validate="'required'"
                      pb-3
                      clearable
                      :label="$t('Tenant Name')"
                      data-vv-name="tenant"
                      item-text="tenant_name"
                      item-value="id"
                      :items="tenants"
                      @change="getContracts()"
                      @input.native="fetchTenants"
                      :disabled="disableContractSelectors"
                      :error-messages="
                        errors.collect('renew_contract_scope.tenant')
                      "
                      :no-data-text="generateTenantsEmptyMessage()"
                    />
                    <v-autocomplete
                      v-model="contract.parent_contract"
                      pb-3
                      v-validate="'required'"
                      clearable
                      :label="$t('Parent Contract')"
                      data-vv-name="parent_contract"
                      item-text="id"
                      item-value="id"
                      :items="contracts"
                      @input.native="fetchContracts"
                      @change="setContract()"
                      :disabled="disableContractSelectors"
                      :error-messages="
                        errors.collect('renew_contract_scope.parent_contract')
                      "
                      :no-data-text="generateContractsEmptyMessage()"
                    >
                      <template v-slot:selection="data">
                        <span>
                          {{ $t("Contract ID") }}: {{ data.item.id }},
                          {{ $t("from date") }}:
                          {{ formatDate(data.item.contract_start_date) }}
                          {{ $t("to date") }}:
                          {{ formatDate(data.item.contract_end_date) }}
                        </span>
                      </template>

                      <template v-slot:item="data">
                        <span>
                          {{ $t("Contract ID") }}: {{ data.item.id }},
                          {{ $t("from date") }}:
                          {{ formatDate(data.item.contract_start_date) }}
                          {{ $t("to date") }}:
                          {{ formatDate(data.item.contract_end_date) }}
                        </span>
                      </template>
                    </v-autocomplete>
                    <SelectedUnits
                      :selectedUnits="selectedUnits"
                      :units="selectedUnits"
                      disableUnitsSelect
                    />
                    <v-select
                      v-model="contract.payment_frequency"
                      :label="$t('Payment Period')"
                      item-text="display_name"
                      item-value="value"
                      :items="paymentPeriods"
                      v-validate="'required'"
                      name="payment_frequency"
                      data-vv-name="payment_frequency"
                      :error-messages="
                        errors.collect('renew_contract_scope.payment_frequency')
                      "
                      disabled
                    />
                    <date-picker
                      v-model="contract.contract_start_date"
                      disabled
                      name="contract_start_date"
                      :label="$t('Old Contract Start Date')"
                      data-vv-name="contract_start_date"
                      :default_date="contract.contract_start_date"
                      :error-messages="
                        errors.collect(
                          'renew_contract_scope.contract_start_date'
                        )
                      "
                    />

                    <date-picker
                      v-model="contract.contract_end_date"
                      disabled
                      name="contract_end_date"
                      :label="$t('Old Contract End Date')"
                      data-vv-name="contract_end_date"
                      :default_date="contract.contract_end_date"
                      :error-messages="
                        errors.collect('renew_contract_scope.contract_end_date')
                      "
                    />

                    <v-text-field
                      v-model="contract.amount"
                      :label="$t('Old Contract Amount')"
                      class="custom"
                      disabled
                      required
                    />

                    <v-text-field
                      v-model="contract.original_amount"
                      class="custom"
                      required
                      disabled
                      data-vv-name="contract_original_amount"
                      :label="$t('Old Original Contract Amount')"
                    />
                    <date-picker
                      v-model="contract.new_contract_start_date"
                      v-validate="'date_format:yyyy-MM-dd'"
                      disabled
                      name="new_contract_start_date"
                      :label="$t('New Contract Start Date')"
                      data-vv-name="new_contract_start_date"
                      :default_date="contract.new_contract_start_date"
                      :error-messages="
                        errors.collect(
                          'renew_contract_scope.new_contract_start_date'
                        )
                      "
                    />

                    <date-picker-without-buttons
                      v-model="contract.new_contract_end_date"
                      v-validate="
                        `required|date_format:yyyy-MM-dd|date_between:${contract.contract_end_date},2100-01-01`
                      "
                      name="new_contract_end_date"
                      :label="$t('New Contract End Date')"
                      data-vv-name="new_contract_end_date"
                      :changed="contract.new_contract_end_date"
                      :allowedDates="
                        $event =>
                          allowPaymentPeriodDates(
                            $event,
                            contract.payment_frequency,
                            contract.new_contract_start_date
                          )
                      "
                      :initialValue="contract.new_contract_end_date"
                      :error-messages="
                        errors.collect(
                          'renew_contract_scope.new_contract_end_date'
                        )
                      "
                    />

                    <v-layout>
                      <v-flex>
                        <v-text-field
                          v-model="contract.new_amount"
                          :label="$t('New Contract Amount')"
                          class="custom"
                          v-validate="`required|decimal:${getDecimalCount}`"
                          name="new_contract_amount"
                          data-vv-name="new_contract_amount"
                          :error-messages="
                            errors.collect(
                              'renew_contract_scope.new_contract_amount'
                            )
                          "
                          @change="checkOriginalAmount"
                        />
                      </v-flex>
                    </v-layout>

                    <v-text-field
                      v-model="contract.new_original_amount"
                      v-validate="
                        `required|decimal:${getDecimalCount}|min_value:${
                          contract.new_amount || 0
                        }`
                      "
                      class="custom"
                      required
                      data-vv-name="new_contract_original_amount"
                      :label="$t('New Original Contract Amount')"
                      :error-messages="
                        errors.collect(
                          'renew_contract_scope.new_contract_original_amount'
                        )
                      "
                      @change="checkOriginalAmount"
                    />

                    <v-text-field
                      v-model="contract.reference"
                      class="custom"
                      :label="$t('Contract Reference')"
                    />

                    <v-layout class="align-center mb-2 ml-3">
                      <span class="re-upload__span">{{
                        $t("Upload Attachement")
                      }}</span>
                      <input
                        type="file"
                        id="myUpload"
                        style="display: none"
                        ref="uploadContract"
                        accept="image/*"
                        @change="event => uploadImage(event)"
                      />
                      <v-btn
                        @click="$refs.uploadContract.click()"
                        class="re-custom-file-input"
                        >{{ $t("Upload") }}</v-btn
                      >
                      <span v-if="contract.attachment">
                        {{ getImageName(contract.attachment.contract_file) }}
                        <v-btn
                          flat
                          icon
                          color="blue"
                          @click="downloadImage(contract.attachment)"
                        >
                          <v-icon>download</v-icon>
                        </v-btn>
                      </span>
                    </v-layout>
                    <span> {{ $t("Payment Methods") }}: </span>
                    <v-layout class="align-center mb-2 ml-3">
                      <v-checkbox
                        v-for="option in contractPaymentMethods"
                        v-model="contract.allowed_pyament_methodes"
                        :label="option.display_name"
                        :value="option.value"
                        :key="option"
                        disabled
                        multiple
                      />
                    </v-layout>

                    <v-checkbox
                      :label="$t('Payment is not in sequence')"
                      v-model="contract.is_payment_made_in_sequence"
                      disabled
                      :true-value="false"
                      :false-value="true"
                    />
                    <v-checkbox
                      :label="$t('No expiration on payment links')"
                      v-model="contract.is_payment_links_expiration_enabled"
                      disabled
                      :true-value="false"
                      :false-value="true"
                    />
                  </v-form>
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-card-text>
                  <invoice
                    v-show="tab === 1"
                    :title="$t('Contract Invoices')"
                    :selectedInvoices="selectedInvoices"
                    :isDialogLoading="isLoadingInvoices"
                    :isRenew="true"
                    :isAction="true"
                    :isShowColors="tab === 1"
                    :contractPaymentMethods="paymentMethods"
                    :isSelectedPropertySupportsVat="
                      isSelectedPropertySupportsVat
                    "
                  />
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
          <vue-skeleton-loader
            rounded
            class="w-full"
            type="rect"
            v-if="isLoading"
            :width="'100%'"
            :height="'750px'"
            animation="fade"
          />
        </v-flex>
      </v-layout>
    </v-layout>

    <div class="text-xs-center mt-4">
      <v-btn
        class="re-orangtbtn"
        :disabled="isLoading || isUploadingFile || isLoadingInvoices"
        @click="saveData"
      >
        {{ $t("Save") }}
      </v-btn>
      <v-btn dark :disabled="isLoading" @click="globalGoBack">
        {{ $t("Cancel") }}
      </v-btn>
    </div>
    <confirmation-dialog
      v-model="pendingDialog"
      :label="$t('Confirmation')"
      :message="
        $t(
          'There is a pending amount that should be paid, are you sure that you want to continue.'
        )
      "
      :saveMessage="$t('Yes')"
      :cancelMessage="$t('No')"
      @acceptDialog="doAction"
    />
    <confirmation-dialog
      v-model="confirmationDialog"
      :label="$t('Confirmation')"
      :message="$t('Are you sure that you want to renew contract?')"
      :saveMessage="$t('Yes')"
      :cancelMessage="$t('No')"
      @acceptDialog="doAction"
    />
  </div>
</template>

<script>
import { debounce } from "lodash";
import isEqual from "lodash/isEqual";
import moment from "moment";
import { mapGetters } from "vuex";

import SelectedUnits from "../../components/Contract/SelectedUnits.vue";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog.vue";
import Invoice from "../../components/Invoices/Invoice.vue";
import DatePicker from "../../components/picker/DatePicker.vue";
import DatePickerWithoutButtons from "../../components/picker/DatePickerWithoutButtons.vue";
import { ContractEnums } from "../../enums";
import {
  formatDateForPicker,
  defaultFormat,
  recalculateAndtransformContractUnit,
  reformatAmountWithDecimals
} from "../../helper";
import contractService from "../../services/plugins/contract";
import invoiceService from "../../services/plugins/invoices";
import propertiesService from "../../services/plugins/properties";
import tenantServices from "../../services/plugins/tenant";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";
import contractPymtMethodMixin from "@/mixins/contractPymtMethodMixin";
import dateMixins from "@/mixins/date_mixins";

export default {
  name: "renew_contract",
  components: {
    Invoice,
    DatePicker,
    ConfirmationDialog,
    DatePickerWithoutButtons,
    SelectedUnits
  },
  $_veeValidate: {
    validator: "new"
  },
  mixins: [breadItemsSetLang, contractPymtMethodMixin, dateMixins],
  data() {
    return {
      tab: 0,
      isLoading: false,
      pendingDialog: false,
      confirmationDialog: false,
      contract: {},
      properties: [],
      selectedInvoices: [],
      tenants: [],
      disableContractSelectors: false,
      isLoadingProperty: false,
      fetchContracts: null,
      fetchProperties: null,
      fetchTenants: null,
      isUploadingFile: false,
      showInvoiceTab: false,
      isLoadingInvoices: false,
      showReset: false,
      contracts: [],
      isLoadingContracts: false,
      isLoadingTenants: false,
      selectedUnits: [],
      isSelectedPropertySupportsVat: false
    };
  },
  async created() {
    this.fetchContracts = debounce(this.getContracts, 500);
    this.fetchProperties = debounce(this.loadProperties, 500);
    this.fetchTenants = debounce(this.updateTenants, 500);
    this.initializeData();
    this.$validator.localize("en", this.dictionary);
  },
  provide() {
    return {
      parentValidator: this.$validator
    };
  },
  computed: {
    dictionary() {
      const messages = {};
      for (let i = 0; i < this.selectedInvoices.length; i++) {
        messages[`actual_paid_amount_${i}`] = {
          required: () => this.$t("This field is required"),
          max_value: (fName, params) =>
            `${this.$t(
              "Actual paid amount should be less than or equal to the original amount"
            )} ${params[0]}`,
          decimal: () =>
            this.$t("Decimal value can contain upto") +
            ` (${this.getDecimalCount}) ` +
            this.$t("digits")
        };
      }
      return {
        custom: {
          tenant: {
            required: () => this.$t("Tenant Name is required")
          },
          property: {
            required: () => this.$t("Property Name is required")
          },
          contract_end_date: {
            required: () => this.$t("Contract end date is required"),
            date_between: () =>
              this.$t(
                "Kindly, enter a contract end date which is greater than the contract start date"
              )
          },
          payment_start_date: {
            required: () => this.$t("Payment start date is required"),
            date_between: () =>
              this.$t(
                "Kindly, enter a contract payment start date which is greater than the contract start date"
              )
          },
          contract_start_date: {
            required: () => this.$t("Contract start date is required")
          },
          new_contract_start_date: {
            required: () => this.$t("New contract start date is required"),
            date_between: () =>
              this.$t(
                "Kindly, select a date which is greater than the old contract end date"
              )
          },
          new_contract_end_date: {
            required: () => this.$t("New contract end date is required"),
            date_between: () =>
              this.$t(
                "Kindly, select a date which is greater than the old contract end date"
              )
          },
          contract_original_amount: {
            required: () =>
              this.$t("Contract original amount is a required field")
          },
          new_contract_original_amount: {
            required: () => this.$t("New original contract amount is required"),
            min_value: min =>
              `${this.$t(
                "New contract amount cannot be greater than the new original contract amount"
              )} ${min}`
          },
          parent_contract: {
            required: () => this.$t("This field is required")
          },
          payment_frequency: {
            required: () => this.$t("This field is required")
          },
          new_contract_amount: {
            required: () => this.$t("This field is required")
          },
          ...messages
        }
      };
    },
    duplicateContract() {
      return Object.assign({}, this.contract);
    },
    ...mapGetters("config", ["getDecimalCount"])
  },
  watch: {
    "$i18n.locale": function () {
      this.initializeData();
      this.$validator.localize(this.$i18n.locale, this.dictionary);
    },
    async duplicateContract(newVal, oldVal) {
      if (isEqual(newVal, oldVal)) return;

      if (newVal.new_original_amount !== oldVal.new_original_amount) {
        return;
      }

      if (
        !(newVal.new_contract_end_date && newVal.original_amount && newVal.id)
      ) {
        return;
      }

      if (newVal.new_contract_end_date !== oldVal.new_contract_end_date) {
        await this.callInvoices();
      }
    },
    "contract.new_amount": function (value) {
      this.contract.new_original_amount = value;
    },
    "selectedInvoices.length"() {
      this.$validator.localize(this.$i18n.locale, this.dictionary);
    }
  },
  methods: {
    async updateTenants(event) {
      if (!this.contract.property) return;
      this.isLoadingTenants = true;
      try {
        let params = {
          property_id: this.contract.property,
          limit: 10,
          offset: 0
        };
        if (event) {
          params = {
            ...params,
            search: event.target.value
          };
        } else {
          params.search = "";
        }
        const response = await this.$processReq(
          tenantServices.getTenantsList(params),
          false
        );
        this.tenants = response.data.body.results;
      } catch (err) {
        console.log("Error: Unable to fetch tenant list  - ", err);
      }
      this.isLoadingTenants = false;
    },
    async checkOriginalAmount() {
      if (
        !(
          this.duplicateContract.new_contract_end_date &&
          this.duplicateContract.id
        )
      ) {
        return;
      }

      await this.callInvoices();
    },
    async onTabChange(tab) {
      if (
        tab === 1 &&
        !(await this.$validator.validateAll("renew_contract_scope"))
      ) {
        this.tab = 0;
        return;
      }
      this.tab = tab;
    },
    async callInvoices() {
      // checking only 'renew_contract_scope'
      if (await this.$validator.validateAll("renew_contract_scope")) {
        await this.fetchInvoices();
        if (this.showReset) {
          this.$root.$emit("snackbar", {
            text: this.$t("Your invoice changes has been reset."),
            color: "success"
          });
        }
      }

      if (!this.showReset) {
        this.showReset = true;
      }
    },
    async initializeData() {
      this.isLoading = true;
      const params = this.$route.params;

      if (params.contract_id) {
        this.disableContractSelectors = true;
        await this.fetchContractById(params.contract_id);
      } else {
        await this.loadProperties();
      }
      this.isLoading = false;
    },
    async getContracts(event) {
      this.isLoadingContracts = true;
      this.contracts = [];
      try {
        let params = {
          property: this.contract.property,
          tenant: this.contract.tenant,
          id: event?.target?.value,
          limit: 10,
          offset: 0
        };
        const res = await this.$processReq(
          contractService.fetchContractListMiniDetails(params),
          false
        );

        this.contracts = res.data.body.results;
      } catch (err) {
        console.log("Error: Unable to fetch contract list  - ", err);
      }
      this.isLoadingContracts = false;
    },
    async saveData() {
      if (await this.checkTabsValidationAndRefocus()) {
        if (this.contract.is_payment_pending) {
          this.pendingDialog = true;
        } else {
          this.confirmationDialog = true;
        }
      }
    },
    async doAction() {
      try {
        this.isLoading = true;
        let contract_units = this.selectedUnits.map(unit => {
          return {
            unit: unit.id,
            occupied_meters: unit.occupied_meters,
            meter_price: unit.unittype.is_rentable_by_meter ? unit.price : null,
            unit_price: unit.invoice_amount
          };
        });
        const data = {
          ...this.contract,
          contract_units,
          attachment: this.contract.attachment
            ? this.contract.attachment.id
            : null,
          contract_start_date: formatDateForPicker(
            this.contract.contract_start_date
          ),
          new_contract_start_date: formatDateForPicker(
            this.contract.new_contract_start_date
          ),
          new_contract_end_date: formatDateForPicker(
            this.contract.new_contract_end_date
          ),
          contract_end_date: formatDateForPicker(
            this.contract.contract_end_date
          ),
          payment_start_date: formatDateForPicker(
            this.contract.payment_start_date
          ),
          modified_payments: this.selectedInvoices.map(invoice => {
            invoice = {
              ...invoice,
              is_advanced_payment:
                invoice.is_advanced_payment_after_contracting,
              payment_date: formatDateForPicker(invoice.payment_date, true),
              status: invoice.is_paid ? "Paid" : "Expired",
              amount: invoice.actual_paid_amount
            };

            delete invoice.is_paid;
            delete invoice.actual_paid_amount;
            delete invoice.allowed_date;
            delete invoice.is_advanced_payment_after_contracting;
            return invoice;
          }),
          is_payment_links_expiration_enabled:
            !this.contract.is_payment_links_expiration_enabled,
          reason: ContractEnums.RenewContract
        };

        delete data.updated_by;
        delete data.leave_notice_date;
        delete data.actual_leave_date;
        delete data.advanced_payments_count;
        delete data.initiator;
        delete data.merchant_id;
        delete data.modified;
        delete data.contractunit_set;

        await this.$processReq(
          contractService.renewContract(data, this.contract.id),
          false
        );
        this.$root.$emit("snackbar", {
          text: this.$t("Contract Renewed successfully"),
          color: "success"
        });
        this.isLoading = false;
        this.globalGoBack();
      } catch (err) {
        this.isLoading = false;
        console.log("Error: Unable to update contract - ", err);
      }
    },
    async loadProperties(event) {
      try {
        this.isLoadingProperty = true;
        let params = {
          limit: 10,
          offset: 0
        };
        if (event) {
          params = {
            ...params,
            search: event.target.value
          };
        } else {
          params.search = "";
        }

        const response = await this.$processReq(
          propertiesService.getPropertiesList(params),
          false
        );

        this.isLoadingProperty = false;
        this.properties = response.data.body.results;
      } catch (err) {
        this.isLoadingProperty = false;
        console.log("Error: Unable to get properties list - ", err);
      }
    },
    async fetchContractById(contract_id) {
      try {
        const res = await this.$processReq(
          contractService.fetchContractById(contract_id),
          false
        );

        const result = res.data.body;

        this.properties = [result.property];
        this.tenants = [result.tenant];
        this.contracts = [result];
        await this.$nextTick();
        this.setContractDetails(result);
        this.contract.parent_contract = result.id;
      } catch (err) {
        console.log("Error: Unable to fetch contract by ID - ", err);
      }
    },
    getImageName(imageUrl) {
      if (imageUrl) {
        let url = new URL(imageUrl);

        // eslint-disable-next-line no-useless-escape
        url = url.pathname.replace(/^.*(\\|\/|\:)/, "");
        return url;
      }

      return "";
    },
    async downloadImage(image) {
      try {
        this.isLoading = true;
        var link = document.createElement("a");

        const resp = await contractService.downloadImage(image.id);

        var blob = new Blob([resp.data], { type: "image/*" });
        link.href = window.URL.createObjectURL(blob);
        link.target = "_blank";

        // eslint-disable-next-line no-useless-escape
        link.download = image.contract_file.replace(/^.*(\\|\/|\:)/, "");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log("Error: Unable to download image - ", error);
      }
    },
    async uploadImage(e) {
      try {
        this.isUploadingFile = true;
        const file = e.target.files[0];

        const formData = new FormData();
        formData.append("contract_file", file);

        const response = await this.$processReq(
          contractService.uploadImages(formData),
          false
        );
        this.$root.$emit("snackbar", {
          text: this.$t("File Uploaded Successfully"),
          color: "success"
        });
        this.isUploadingFile = false;

        this.contract = {
          ...this.contract,
          attachment: response.data.body
        };
      } catch (err) {
        this.isUploadingFile = false;
        this.$root.$emit("snackbar", {
          text: this.$t("Something went wrong while uploading file"),
          color: "error"
        });
        console.log("Error: Unable to Upload image - ", err);
      }
    },
    async fetchInvoices() {
      try {
        this.isLoadingInvoices = true;
        let params = {
          contract_start_date: this.contract.new_contract_start_date,
          contract_end_date: this.contract.new_contract_end_date,
          payment_start_date: this.contract.new_contract_start_date,
          original_amount: this.contract.new_original_amount,
          amount: this.contract.new_amount,
          number_of_advance_payments: 0,
          is_payment_made_in_sequence:
            this.contract.is_payment_made_in_sequence,
          is_payment_links_expiration_enabled:
            this.contract.is_payment_links_expiration_enabled,
          payment_frequency: this.contract.payment_frequency,
          property_id: this.contract.property
        };

        const response = await this.$processReq(
          invoiceService.fetchInvoices(params),
          false
        );

        const invoices = response.data.body;
        await this.$validator.reset("invoices_scope");
        this.selectedInvoices = invoices.open_balance_missing_payments.map(
          payment => {
            return {
              ...payment,
              is_paid: payment.status == this.$t("Paid") ? true : false,
              amount: reformatAmountWithDecimals(
                payment.contract_original_amount
              ),
              paid_amount_before_discount: reformatAmountWithDecimals(
                payment.paid_amount_before_discount
              ),
              discount: reformatAmountWithDecimals(payment.discount),
              paid_amount: reformatAmountWithDecimals(payment.paid_amount),
              actual_paid_amount: reformatAmountWithDecimals(payment.amount),
              payment_date: payment.payment_due_date,
              allowed_date: moment(payment.payment_date).format("YYYY-MM-DD"),
              is_payment_made_in_sequence:
                this.contract.is_payment_made_in_sequence,
              payment_method: "payment link",
              receipt_voucher_reference: null,
              cash_treasury: null,
              bank: null,
              cheque_number: null,
              cheque_date: null,
              reference_number: null,
              is_advanced_payment_after_contracting: false,
              is_late_payment: false
            };
          }
        );

        this.isLoadingInvoices = false;
      } catch (err) {
        this.isLoadingInvoices = false;
        console.log("Error: Unable to show Invoices - ", err);
      }
    },
    generateTenantsEmptyMessage() {
      if (this.contract.property) {
        if (this.isLoadingTenants) {
          return this.$t("Loading...");
        }
        return this.$t("No data available");
      } else {
        return this.$t("Please select a property to load tenants");
      }
    },
    generateContractsEmptyMessage() {
      if (this.contract.property && this.contract.tenant) {
        if (this.isLoadingContracts) {
          return this.$t("Loading...");
        }
        return this.$t("No data available");
      } else {
        return this.$t("Please select a property and tenant to load contracts");
      }
    },
    getPropertyTenants() {
      this.contract = {
        property: this.contract.property
      };
      this.tenants = [];
      this.contracts = [];
      this.fetchTenants();
    },
    getMatchedPaymentFrequencyRate(paymentFrequency) {
      switch (paymentFrequency) {
        case "monthly":
          return 1;
        case "quarterly":
          return 3;
        case "semi-annually":
          return 6;
        case "annually":
          return 12;
        default:
          return 1;
      }
    },
    async checkTabsValidationAndRefocus() {
      // checking both scopes[renew_contract_scope, invoices_scope]
      const isContractDetailsFormValid = await this.$validator.validateAll(
        "renew_contract_scope"
      );
      const isInvoicesDetailsFormValid = await this.$validator.validateAll(
        "invoices_scope"
      );

      if (!isContractDetailsFormValid) {
        this.tab = 0; // focus on contract details tab
        return false;
      } else if (!isInvoicesDetailsFormValid) {
        this.tab = 1; // focus on Invoices details tab
        return false;
      }
      // otherwise all scopes are valid
      return true;
    },
    calculateContractNewAmount(contract) {
      let new_contract_amount = reformatAmountWithDecimals(0);
      const selectedFrequencyRate = this.getMatchedPaymentFrequencyRate(
        contract.payment_frequency
      );
      this.selectedUnits.forEach(unit => {
        if (unit.invoice_amount !== "") {
          const calculatedUnitAmount =
            reformatAmountWithDecimals(unit.invoice_amount) *
            selectedFrequencyRate;
          new_contract_amount = reformatAmountWithDecimals(
            +new_contract_amount + calculatedUnitAmount
          );
        }
      });
      return new_contract_amount;
    },
    resetContractDetails() {
      this.contract = {
        tenant: this.contract.tenant,
        property: this.contract.property
      };
      this.selectedUnits = [];
    },
    async setContract() {
      if (!this.contract.parent_contract) {
        this.resetContractDetails();
        return;
      }
      try {
        this.isLoading = true;
        const res = await this.$processReq(
          contractService.fetchContractById(this.contract.parent_contract),
          false
        );

        const result = res.data.body;
        await this.$nextTick();
        this.setContractDetails(result);
      } catch (err) {
        this.isLoading = false;
        this.contract.parent_contract = "";
        this.resetContractDetails();
        console.log("Error: Unable to fetch contract by ID - ", err);
      }
    },
    setContractDetails(contract) {
      this.selectedUnits = recalculateAndtransformContractUnit(
        contract.contractunit_set
      );
      this.isSelectedPropertySupportsVat = contract.property.is_vat_enabled;
      this.contract = {
        ...this.contract,
        ...contract,
        tenant: contract.tenant.id,
        property: contract.property.id,
        contract_start_date: formatDateForPicker(contract.contract_start_date),
        contract_end_date: formatDateForPicker(contract.contract_end_date),
        payment_start_date: formatDateForPicker(contract.payment_start_date),
        new_contract_start_date: formatDateForPicker(
          moment(contract.contract_end_date).add(1, "days")
        ),
        new_contract_end_date: undefined,
        amount: reformatAmountWithDecimals(contract.amount),
        original_amount: reformatAmountWithDecimals(contract.original_amount),
        new_amount: this.calculateContractNewAmount(contract),
        new_original_amount: this.calculateContractNewAmount(contract),
        reference: undefined,
        attachment: undefined,
        is_payment_made_in_sequence: contract.is_payment_made_in_sequence,
        is_payment_links_expiration_enabled:
          contract.is_payment_links_expiration_enabled
      };
      this.isLoading = false;
    },
    formatDate(value, containsTime) {
      return defaultFormat(value, containsTime);
    }
  }
};
</script>

<style scoped>
.v-dialog .headline {
  max-width: 100% !important;
}
.re-orangtbtn {
  color: white !important;
}

.re-orangtbtn:disabled {
  background-color: #f5f5f5 !important;
  color: black !important;
}
</style>
