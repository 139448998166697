<template>
  <div>
    <v-layout class="mb-4">
      <v-flex md4>
        <vue-skeleton-loader
          rounded
          class="w-full"
          type="rect"
          v-if="isLoading"
          :height="'125px'"
          :width="'300px'"
          animation="fade"
        />
        <v-card class="pb-3" v-if="!isLoading">
          <v-toolbar class="re-toolbar re-justify-center" dense>
            <h4 class="re-green-c font-weight-medium">
              {{ $t("Net Paid") }}
              <span v-if="getIsCountrySupportsVat" class="re-red-c">
                | {{ $t("Total Paid") }}
              </span>
            </h4>
          </v-toolbar>
          <v-card-text>
            <div class="re-amount">
              {{ reformatAmount(payment.total_net_paid || 0) }}
              {{ $t(getCurrencyCode) }}
              <span
                v-if="getIsCountrySupportsVat"
                class="d-inline-block re-red-c"
              >
                |
                {{ reformatAmount(payment.total_paid || 0) }}
                {{ $t(getCurrencyCode) }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex md4 class="ml-2">
        <vue-skeleton-loader
          rounded
          class="w-full"
          type="rect"
          v-if="isLoading"
          :height="'125px'"
          :width="'300px'"
          animation="fade"
        />
        <v-card class="pb-3" v-if="!isLoading">
          <v-toolbar class="re-toolbar re-justify-center" dense>
            <h4 class="re-lime-c font-weight-medium">
              {{ $t("Net Pending") }}
              <span v-if="getIsCountrySupportsVat" class="re-red-c">
                | {{ $t("Total Pending") }}
              </span>
            </h4>
          </v-toolbar>
          <v-card-text>
            <div class="re-amount">
              {{ reformatAmount(payment.pending_payment_net || 0) }}
              {{ $t(getCurrencyCode) }}
              <span
                v-if="getIsCountrySupportsVat"
                class="d-inline-block re-red-c"
              >
                | {{ reformatAmount(payment.pending_payment || 0) }}
                {{ $t(getCurrencyCode) }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex md4 class="ml-2">
        <vue-skeleton-loader
          rounded
          class="w-full"
          type="rect"
          v-if="isLoading"
          :height="'125px'"
          :width="'300px'"
          animation="fade"
        />
        <v-card class="pb-3" v-if="!isLoading">
          <v-toolbar class="re-toolbar re-justify-center" dense>
            <h4 class="font-weight-medium re-grey-c">
              {{ $t("Net Amount") }}
              <span v-if="getIsCountrySupportsVat" class="re-red-c">
                | {{ $t("Total Amount") }}
              </span>
            </h4>
          </v-toolbar>
          <v-card-text>
            <div class="re-amount">
              {{ reformatAmount(payment.total_net_amount || 0) }}
              {{ $t(getCurrencyCode) }}
              <span
                v-if="getIsCountrySupportsVat"
                class="d-inline-block re-red-c"
              >
                | {{ reformatAmount(payment.total_amount || 0) }}
                {{ $t(getCurrencyCode) }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex md4 class="ml-2">
        <vue-skeleton-loader
          rounded
          class="w-full"
          type="rect"
          v-if="isLoading"
          :height="'125px'"
          :width="'300px'"
          animation="fade"
        />
        <v-card class="pb-3" v-if="!isLoading">
          <v-toolbar class="re-toolbar re-justify-center" dense>
            <h4 class="re-red-c font-weight-medium">
              {{ $t("Late Payments") }}
            </h4>
          </v-toolbar>
          <v-card-text>
            <div class="re-amount">
              {{ reformatAmount(payment.late_payment || 0) }}
              {{ $t(getCurrencyCode) }}
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout class="mb-4">
      <v-flex md4>
        <vue-skeleton-loader
          rounded
          class="w-full"
          type="rect"
          v-if="isLoading"
          :height="'125px'"
          :width="'300px'"
          animation="fade"
        />
        <v-card class="pb-3" v-if="!isLoading">
          <v-toolbar class="re-toolbar re-justify-center" dense>
            <h4 class="re-green-c font-weight-medium">
              {{ $t("Total Paid Count") }}
            </h4>
          </v-toolbar>
          <v-card-text>
            <div class="re-amount">{{ payment.total_paid_count }}</div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex md4 class="ml-2">
        <vue-skeleton-loader
          rounded
          class="w-full"
          type="rect"
          v-if="isLoading"
          :height="'125px'"
          :width="'300px'"
          animation="fade"
        />
        <v-card class="pb-3" v-if="!isLoading">
          <v-toolbar class="re-toolbar re-justify-center" dense>
            <h4 class="re-lime-c font-weight-medium">
              {{ $t("Total Pending Count") }}
            </h4>
          </v-toolbar>
          <v-card-text>
            <div class="re-amount">{{ payment.pending_payment_count }}</div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex md4 class="ml-2">
        <vue-skeleton-loader
          rounded
          class="w-full"
          type="rect"
          v-if="isLoading"
          :height="'125px'"
          :width="'300px'"
          animation="fade"
        />
        <v-card class="pb-3" v-if="!isLoading">
          <v-toolbar class="re-toolbar re-justify-center" dense>
            <h4 class="font-weight-medium re-grey-c">
              {{ $t("Total Count") }}
            </h4>
          </v-toolbar>
          <v-card-text>
            <div class="re-amount">{{ payment.total_count }}</div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex md4 class="ml-2">
        <vue-skeleton-loader
          rounded
          class="w-full"
          type="rect"
          v-if="isLoading"
          :height="'125px'"
          :width="'300px'"
          animation="fade"
        />
        <v-card class="pb-3" v-if="!isLoading">
          <v-toolbar class="re-toolbar re-justify-center" dense>
            <h4 class="font-weight-medium re-grey-c">
              {{
                $t("(Grace, Open, Missed, In Advance While Contracting) Amount")
              }}
            </h4>
          </v-toolbar>
          <v-card-text>
            <div class="re-amount">
              {{ reformatAmount(payment.total_others_amount || 0) }}
              {{ $t(getCurrencyCode) }}
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { reformatAmountWithDecimals } from "../../helper";
export default {
  name: "cards",
  props: {
    payment: Object,
    isLoading: Boolean
  },
  computed: {
    ...mapGetters("config", ["getCurrencyCode", "getIsCountrySupportsVat"])
  },
  methods: {
    reformatAmount(amount) {
      return reformatAmountWithDecimals(amount);
    }
  }
};
</script>
<style scoped>
.re-grey-c {
  color: #808080 !important;
}
</style>
