<template>
  <div class="edit-unit-type">
    <v-layout class="pt-5">
      <v-flex grow pa-1 xs6>
        <h2 class="display-2 pl-4">{{ $t("Profiles") }}</h2>
      </v-flex>
      <v-flex shrink pa-1 xs6 text-xs-right>
        <v-btn
          dark
          class="mt-4 r-m-end"
          :disabled="isLoading"
          @click="globalGoBack"
          >← {{ $t("Back") }}</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex class="flex-wrap" xs12 pa-1>
        <v-breadcrumbs :items="breadItemsSetLang()" class="pl-4">
          <template v-slot:divider>
            <v-icon>fiber_manual_record</v-icon>
          </template>
        </v-breadcrumbs>
      </v-flex>
    </v-layout>
    <v-form>
      <v-layout row wrap justify-center>
        <v-layout row wrap justify-center>
          <v-flex xs12 sm12 lg9>
            <vue-skeleton-loader
              rounded
              class="w-full"
              type="rect"
              v-if="isLoading"
              :height="'358px'"
              :width="'100%'"
              animation="fade"
            />
            <v-card v-if="!isLoading">
              <v-card-title>
                <h3>
                  {{ isEdit ? $t("Edit") : $t("Add") }} {{ $t("Profile") }}
                </h3>
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="profile.profile_name_en"
                  v-validate="'required'"
                  :label="$t('Profile Name (EN)')"
                  required
                  class="mb-2 pb-2"
                  data-vv-name="profile_name_en"
                  :error-messages="errors.collect('profile_name_en')"
                />

                <v-text-field
                  v-model="profile.profile_name_ar"
                  v-validate="'required'"
                  :label="$t('Profile Name (AR)')"
                  required
                  class="mb-2 pb-2"
                  data-vv-name="profile_name_ar"
                  :error-messages="errors.collect('profile_name_ar')"
                />
                <vue-phone-number-input
                  v-model="profile.phone"
                  name="phone_number"
                  :translations="translations"
                  :only-countries="phoneInputPreferredCountries"
                  :default-country-code="
                    profile.country_code
                      ? profile.country_code.replace(/[0-9]/g, '')
                      : defaultCountryCode
                  "
                  required
                  :error="!isValid"
                  :error-message="$t('This field is required')"
                  @update="updateCountryCode($event)"
                  @phone-number-focused="isTouched = true"
                />
                <span
                  v-if="!profile.phone && isTouched"
                  class="red-text"
                  style="display: flex"
                  >{{ $t("Phone number is required") }}</span
                >
                <v-text-field
                  v-model="profile.email"
                  v-validate="'required|email'"
                  :label="$t('Email Address')"
                  class="mb-2 pb-2"
                  data-vv-name="email"
                  :error-messages="errors.collect('email')"
                />
                <v-layout class="align-center mb-3">
                  <span class="re-upload__span">{{
                    $t("Upload Profile Picture")
                  }}</span>
                  <input
                    type="file"
                    id="uploadProfilePicture"
                    style="display: none"
                    ref="uploadProfilePicture"
                    accept="image/*"
                    @change="
                      event => uploadImage(event, uploadingTypeEnum.logo)
                    "
                  />
                  <v-btn
                    @click="$refs.uploadProfilePicture.click()"
                    class="re-custom-file-input"
                    >{{ $t("Upload") }}</v-btn
                  >
                  <span
                    v-if="!profile.logo && showError"
                    style="color: #ff0000"
                    >{{ $t("This field is required") }}</span
                  >
                  <span v-if="profile.logo && profile.logo.logo">
                    {{ getImageName(profile.logo.logo) }}
                    <v-btn
                      flat
                      icon
                      color="blue"
                      @click="downloadImage(profile.logo)"
                    >
                      <v-icon>download</v-icon>
                    </v-btn>
                  </span>
                </v-layout>
                <v-layout class="align-center mb-3">
                  <span class="re-upload__span">{{
                    $t("Upload Signature")
                  }}</span>
                  <input
                    type="file"
                    id="uploadSignature"
                    style="display: none"
                    ref="uploadSignature"
                    accept="image/*"
                    @change="
                      event => uploadImage(event, uploadingTypeEnum.signature)
                    "
                  />
                  <v-btn
                    @click="$refs.uploadSignature.click()"
                    class="re-custom-file-input"
                    >{{ $t("Upload") }}</v-btn
                  >
                  <span
                    v-if="!profile.signature_icon && showError"
                    style="color: #ff0000"
                    >{{ $t("This field is required") }}</span
                  >
                  <span
                    v-if="profile.signature_icon && profile.signature_icon.logo"
                  >
                    {{ getImageName(profile.signature_icon.logo) }}
                    <v-btn
                      flat
                      icon
                      color="blue"
                      @click="downloadImage(profile.signature_icon)"
                    >
                      <v-icon>download</v-icon>
                    </v-btn>
                  </span>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-layout>
      <div class="text-xs-center mt-4">
        <v-btn
          dark
          class="re-orangtbtn"
          :disabled="isLoading"
          @click="storeData"
          >{{ $t("Save") }}</v-btn
        >
        <v-btn dark :disabled="isLoading" @click="globalGoBack">{{
          $t("Cancel")
        }}</v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { isEmpty, cloneDeep } from "lodash";
import { mapGetters } from "vuex";

import profileService from "../../services/plugins/profile";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";

export default {
  name: "add_profile",
  $_veeValidate: {
    validator: "new"
  },
  mixins: [breadItemsSetLang],
  async mounted() {
    let isEdit = false;
    this.$validator.localize("en", this.dictionary);

    if (this.$route.params.id) {
      await this.fetchProfile(this.$route.params.id);
      isEdit = true;
    }

    this.isEdit = isEdit;
  },
  data() {
    return {
      isLoading: false,
      profile: {},
      isEdit: false,
      breadCrumbs: [],
      prevRoute: "",
      showError: false,
      isTouched: false,
      uploadingTypeEnum: {
        logo: "logo",
        signature: "signature_icon"
      }
    };
  },
  watch: {
    "$i18n.locale": function () {
      this.$validator.localize(this.$i18n.locale, this.dictionary);
    }
  },
  computed: {
    dictionary() {
      return {
        custom: {
          profile_name_en: {
            required: () => this.$t("Profile Name(EN) can not be empty")
          },
          profile_name_ar: {
            required: () => this.$t("Profile Name(AR) can not be empty")
          }
        }
      };
    },
    translations() {
      return {
        countrySelectorLabel: this.$t("Country code"),
        countrySelectorError: "Country Code Not Found",
        phoneNumberLabel: this.$t("Phone number"),
        example: "Example :"
      };
    },
    ...mapGetters("config", {
      defaultCountryCode: "getDefaultPhoneCountryCode",
      phoneInputPreferredCountries: "getPhoneInputPreferredCountries"
    })
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  methods: {
    async fetchProfile(id) {
      try {
        this.isLoading = true;
        const resp = await this.$processReq(
          profileService.getProfileById(id),
          false
        );
        this.profile = resp.data.body;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log("Error: Unable to get profile by ID - ", error);
      }
    },
    async storeData() {
      if (!isEmpty(this.profile.logo && this.profile.signature_icon)) {
        this.showError = false;
      } else {
        this.showError = true;
      }
      if ((await this.$validator.validateAll()) && !this.showError) {
        const response = {
          ...this.profile,
          profile_name: this.profile.profile_name_en,
          logo: this.profile.logo.id,
          signature_icon: this.profile.signature_icon.id
        };

        this.isLoading = true;
        try {
          if (this.isEdit) {
            await this.$processReq(
              profileService.updateProfile(response, response.id),
              true
            );
          } else {
            await this.$processReq(
              profileService.addNewProfile(response),
              true
            );
          }
          this.isLoading = false;
          this.globalGoBack();
        } catch (err) {
          this.isLoading = false;
          console.log("Error: Unable to update or add new profile - ", err);
        }
      }
    },
    getImageName(imageUrl) {
      if (imageUrl) {
        let url = new URL(imageUrl);

        // eslint-disable-next-line no-useless-escape
        url = url.pathname.replace(/^.*(\\|\/|\:)/, "");
        return url;
      }

      return "";
    },
    async uploadImage(e, name) {
      try {
        this.isUploadingFile = true;
        const file = e.target.files[0];

        const formData = new FormData();
        formData.append("logo", file);
        formData.append("field_name", name);
        const response = await this.$processReq(
          profileService.uploadImages(formData),
          false
        );
        this.$root.$emit("snackbar", {
          text: this.$t("File Uploaded Successfully"),
          color: "success"
        });
        this.isUploadingFile = false;

        let profile = cloneDeep(this.profile);

        profile = {
          ...profile,
          [name]: response.data.body
        };
        this.profile = profile;
        if (
          !isEmpty(this.profile.logo && !isEmpty(this.profile.signature_icon))
        ) {
          this.showError = false;
        }
      } catch (err) {
        this.isUploadingFile = false;
        this.$root.$emit("snackbar", {
          text: this.$t("Something went wrong while uploading file"),
          color: "error"
        });
        console.log("Error: Unable to Upload image - ", err);
      }
    },
    async downloadImage(image) {
      try {
        this.isLoading = true;
        var link = document.createElement("a");

        const resp = await profileService.downloadImage(image.id);

        var blob = new Blob([resp.data], { type: "image/*" });
        link.href = window.URL.createObjectURL(blob);
        link.target = "_blank";

        // eslint-disable-next-line no-useless-escape
        const attachementUrl = image.logo.replace(/^.*(\\|\/|\:)/, "");

        link.download = attachementUrl;

        if (attachementUrl.split("?")?.length > 0) {
          link.download = attachementUrl.split("?")[0];
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log("Error: Unable to download image - ", error);
      }
    },
    updateCountryCode(event) {
      this.profile.country_code = event.countryCode;
      this.profile.phone_number = event.formattedNumber;
      this.isValid = event.isValid;
    }
  }
};
</script>
