import { PluginService } from "./index";

const pluginConfigService = PluginService("/plugin-config");

export default {
  getPluginConfig() {
    return pluginConfigService({
      method: "GET",
      url: "/"
    });
  },
  getPluginFields() {
    return pluginConfigService({
      method: "OPTIONS",
      url: "/"
    });
  },
  getPluginConfigById(id) {
    return pluginConfigService({
      method: "GET",
      url: `/${id}/`
    });
  },
  createPluginConfig(params) {
    return pluginConfigService({
      method: "POST",
      url: "/",
      data: params
    });
  },
  updatePluginConfig(id, params) {
    return pluginConfigService({
      method: "PUT",
      url: `/${id}/`,
      data: params
    });
  }
};
