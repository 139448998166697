import startCase from "lodash/startCase";
import moment from "moment-timezone";

import { timeZones } from "../constants/timeZones";
import store from "../store";

function transformforPropertyUnits(propertyUnits) {
  const units = [];
  const unitTypes = [];
  for (const propertyUnit of propertyUnits) {
    if (!unitTypes.find(unitType => unitType.id === propertyUnit.unittype.id)) {
      unitTypes.push(propertyUnit.unittype);
    }
    const reformattedUnit = reformatUnitDetailsWithDecimals(propertyUnit);
    units.push(reformattedUnit);
  }

  return {
    units,
    unitTypes
  };
}

const transformPropertyUnits = units => {
  let unitTypes = [];
  if (units.length) {
    units.forEach(unit => {
      if (!unitTypes.some(u => u.id === unit.unittype.id)) {
        unitTypes.push({ ...unit.unittype, units: [] });
      }
    });
    unitTypes.forEach(unitType => {
      units.forEach(unit => {
        if (unit.unittype.id === unitType.id) {
          const reshapedUnit = reformatUnitDetailsWithDecimals(unit);
          unitType.units.push({ ...reshapedUnit, unittype: unit.unittype.id });
        }
      });
    });
  }
  return unitTypes;
};

function transformContractUnit(contractUnits) {
  return contractUnits.map(unit => {
    return {
      ...reformatUnitDetailsWithDecimals(unit.unit),
      meter_price: reformatAmountWithDecimals(unit.meter_price),
      occupied_meters: unit.occupied_meters,
      unit_price: reformatAmountWithDecimals(unit.unit_price),
      invoice_amount: reformatAmountWithDecimals(unit.unit_price)
    };
  });
}

/**
 * A specific function to recalcaulate and transform
 * Contract units especially for Renew contract screen
 */
function recalculateAndtransformContractUnit(contractUnits) {
  const newContractUnits = contractUnits.map(unit => {
    const isRentableByMeter = unit.unit.unittype.is_rentable_by_meter;
    const newInvoiceAmount = isRentableByMeter
      ? +reformatAmountWithDecimals(unit.unit.price_per_meter) *
        unit.occupied_meters
      : reformatAmountWithDecimals(unit.unit.invoice_amount);
    return {
      ...reformatUnitDetailsWithDecimals(unit.unit),
      meter_price: reformatAmountWithDecimals(unit.meter_price),
      occupied_meters: unit.occupied_meters,
      unit_price: reformatAmountWithDecimals(unit.unit_price),
      invoice_amount: reformatAmountWithDecimals(newInvoiceAmount)
    };
  });
  return newContractUnits;
}

function reshapSelectedMonths(months) {
  if (!months.length) return [];
  const formatedMonths = months.map(month => {
    // const date = moment(month.duration);
    return {
      payment_due_date: month.payment_due_date,
      amount: month.amount,
      discount: month.discount,
      paid_amount_before_discount: month.paid_amount_before_discount,
      paid_amount: month.paid_amount
    };
  });
  return formatedMonths;
}

function downloadCSV(content, name) {
  let csvContent = "data:text/csv;charset=utf-8,";
  csvContent += content || [];
  const data = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", data);
  link.setAttribute("download", `Export ${name || ""}.csv`);
  link.click();
}

function getFileExtension(type) {
  return "." + type.split("/")[1];
}

function downloadImage(data, name) {
  try {
    const link = document.createElement("a");

    const blob = new Blob([data], { type: "image/*" });
    link.href = window.URL.createObjectURL(blob);
    link.target = "_blank";

    // eslint-disable-next-line no-useless-escape
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log("Error: Unable to download image - ", error);
  }
}

function downloadFile(data, type, name) {
  try {
    const link = document.createElement("a");

    const blob = new Blob([data], { type });
    link.href = window.URL.createObjectURL(blob);
    link.target = "_blank";

    // eslint-disable-next-line no-useless-escape
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log("Error: Unable to download file - ", error);
  }
}

function transformToDropDown(arr) {
  if (Array.isArray(arr) && arr.length > 0) {
    return arr.map(item => {
      return {
        label: item.property_name,
        code: item.id
      };
    });
  }

  return arr;
}

const transformPropertiesRequest = property => {
  const propertyData = property;

  if (propertyData.unit_types) {
    const unit_types = propertyData.unit_types;
    delete propertyData.unit_types;
    delete propertyData.units;

    propertyData.units = [];

    for (let i = 0; i < unit_types.length; i++) {
      const updatedUnits = unit_types[i].units.map(value => {
        return {
          ...value,
          unittype: unit_types[i].unit_type_id
        };
      });

      propertyData.units.push(...updatedUnits);
      delete propertyData.unit_details;
    }
  }

  return propertyData;
};

function formatDateForList(date) {
  const timeZone =
    timeZones[store.getters["config/getCountry"]] || moment.tz.guess();
  return moment(date).tz(timeZone).format("DD-MM-YYYY hh:mm:ss A");
}

function defaultFormat(date, containsTime) {
  const timeZone =
    timeZones[store.getters["config/getCountry"]] || moment.tz.guess();
  if (!date) return "-";
  if (containsTime) {
    return moment(new Date(date)).tz(timeZone).format("DD-MM-YYYY hh:mm:ss A");
  }
  return moment(new Date(date)).tz(timeZone).format("DD-MM-YYYY");
}

function formatDateForPicker(date, containsTime) {
  if (containsTime) {
    let time = moment(Date()).format("Thh:mm:ssZ");
    return date + time;
  }
  return moment(new Date(date)).format("YYYY-MM-DD");
}

function calcualteNetInvoiceAmount(item) {
  const fractionDigits = store.getters["config/getDecimalCount"];
  let invoice_amount = reformatAmountWithDecimals(0);
  if (!item.unittype?.is_rentable_by_meter) {
    const price = reformatAmountWithDecimals(item.price || 0);

    if (item.discount_type === "percentage") {
      const percentageData = (item.discount / 100) * price;

      invoice_amount = (price - percentageData).toFixed(fractionDigits);
    } else {
      invoice_amount = (price - item.discount).toFixed(fractionDigits);
    }
  } else {
    const price = reformatAmountWithDecimals(item.price_per_meter || 0);
    const totalSpace = item.total_space || 0;
    invoice_amount = (price * totalSpace).toFixed(fractionDigits);
  }
  return invoice_amount;
}

function capitalizeFirstLetter(str) {
  return startCase(str);
}

function getYear(date) {
  if (date) {
    return moment(date, "YYYY/MM/DD").format("YYYY");
  }
  return "-";
}

function numToMonth(date) {
  if (date) {
    return moment(date, "YYYY/MM/DD").format("MM");
  }
  return "-";
}

function reformatAmountWithDecimals(amount) {
  const fractionDigits = store.getters["config/getDecimalCount"];
  // Just for some keys that could have [null, undefined] values
  if (amount === null || amount === undefined) {
    return amount;
  }
  return parseFloat(amount).toFixed(fractionDigits);
}

/* A specific function to reformat unit properties to match the current fraction digits 
// keys need to be reformatted with decimals : 
// [invoice_amount, price, price_per_meter, discount]
// using the function 'reformatAmountWithDecimals'
*/
function reformatUnitDetailsWithDecimals(unit) {
  /* Checking discount_type of Unit to determine 
  // what is the max decimal count whether it is (2) for [percentage] type 
  // or matched to 'currentDecimalCount' for [fixed] type. 
  */
  const unitDiscount =
    unit.discount_type === "fixed"
      ? reformatAmountWithDecimals(unit.discount)
      : unit.discount === null || unit.discount === undefined
      ? unit.discount
      : parseFloat(unit.discount).toFixed(2);
  const updatedUnit = {};

  if (Object.prototype.hasOwnProperty.call(unit, "invoice_amount")) {
    updatedUnit.invoice_amount =
      reformatAmountWithDecimals(unit.invoice_amount) ?? unit.invoice_amount;
  }
  if (Object.prototype.hasOwnProperty.call(unit, "price")) {
    updatedUnit.price = reformatAmountWithDecimals(unit.price) ?? unit.price;
  }
  if (Object.prototype.hasOwnProperty.call(unit, "price_per_meter")) {
    updatedUnit.price_per_meter =
      reformatAmountWithDecimals(unit.price_per_meter) ?? unit.price_per_meter;
  }
  if (Object.prototype.hasOwnProperty.call(unit, "discount")) {
    updatedUnit.discount = unitDiscount;
  }

  return {
    ...unit,
    ...updatedUnit
  };
}

/* A specific function to reformat [Invoice, Payment] properties to match the current fraction digits 
// keys need to be reformatted with decimals : 
// 1. [amount, discount, original_amount, paid_amount, paid_amount_before_discount] for Invoice
// 2. [actual_paid_amount, contract_original_amount, payment_amount, payment_discount, payment_due_amount] for Payment
// using the function 'reformatAmountWithDecimals'
*/
function reformatInvoiceDetailsWithDecimals(invoice) {
  const updatedInvoice = {};

  /// Invoice properties
  if (Object.prototype.hasOwnProperty.call(invoice, "amount")) {
    updatedInvoice.amount =
      reformatAmountWithDecimals(invoice.amount) ?? invoice.amount;
  }
  if (Object.prototype.hasOwnProperty.call(invoice, "discount")) {
    updatedInvoice.discount =
      reformatAmountWithDecimals(invoice.discount) ?? invoice.discount;
  }
  if (Object.prototype.hasOwnProperty.call(invoice, "original_amount")) {
    updatedInvoice.original_amount =
      reformatAmountWithDecimals(invoice.original_amount) ??
      invoice.original_amount;
  }
  if (Object.prototype.hasOwnProperty.call(invoice, "paid_amount")) {
    updatedInvoice.paid_amount =
      reformatAmountWithDecimals(invoice.paid_amount) ?? invoice.paid_amount;
  }
  if (
    Object.prototype.hasOwnProperty.call(invoice, "paid_amount_before_discount")
  ) {
    updatedInvoice.paid_amount_before_discount =
      reformatAmountWithDecimals(invoice.paid_amount_before_discount) ??
      invoice.paid_amount_before_discount;
  }
  if (Object.prototype.hasOwnProperty.call(invoice, "total_amount")) {
    updatedInvoice.total_amount =
      reformatAmountWithDecimals(invoice.total_amount) ?? invoice.total_amount;
  }
  if (Object.prototype.hasOwnProperty.call(invoice, "vat_amount")) {
    updatedInvoice.vat_amount =
      reformatAmountWithDecimals(invoice.vat_amount) ?? invoice.vat_amount;
  }
  if (Object.prototype.hasOwnProperty.call(invoice, "net_amount")) {
    updatedInvoice.net_amount =
      reformatAmountWithDecimals(invoice.net_amount) ?? invoice.net_amount;
  }
  /// Payment properties (ex: Generate Invoices manually screen)
  if (Object.prototype.hasOwnProperty.call(invoice, "actual_paid_amount")) {
    updatedInvoice.actual_paid_amount =
      reformatAmountWithDecimals(invoice.actual_paid_amount) ??
      invoice.actual_paid_amount;
  }
  if (
    Object.prototype.hasOwnProperty.call(invoice, "contract_original_amount")
  ) {
    updatedInvoice.contract_original_amount =
      reformatAmountWithDecimals(invoice.contract_original_amount) ??
      invoice.contract_original_amount;
  }
  if (Object.prototype.hasOwnProperty.call(invoice, "payment_amount")) {
    updatedInvoice.payment_amount =
      reformatAmountWithDecimals(invoice.payment_amount) ??
      invoice.payment_amount;
  }
  if (Object.prototype.hasOwnProperty.call(invoice, "payment_discount")) {
    updatedInvoice.payment_discount =
      reformatAmountWithDecimals(invoice.payment_discount) ??
      invoice.payment_discount;
  }
  if (Object.prototype.hasOwnProperty.call(invoice, "payment_due_amount")) {
    updatedInvoice.payment_due_amount =
      reformatAmountWithDecimals(invoice.payment_due_amount) ??
      invoice.payment_due_amount;
  }
  if (Object.prototype.hasOwnProperty.call(invoice, "payment_vat_amount")) {
    updatedInvoice.payment_vat_amount =
      reformatAmountWithDecimals(invoice.payment_vat_amount) ??
      invoice.payment_vat_amount;
  }
  if (Object.prototype.hasOwnProperty.call(invoice, "payment_net_amount")) {
    updatedInvoice.payment_net_amount =
      reformatAmountWithDecimals(invoice.payment_net_amount) ??
      invoice.payment_net_amount;
  }

  return {
    ...invoice,
    ...updatedInvoice
  };
}

function reformatInvoicesListWithDecimals(invoiceList) {
  return invoiceList.map(invoice =>
    reformatInvoiceDetailsWithDecimals(invoice)
  );
}

function checkMaxAllowedFractionDigits(num) {
  if (isNaN(num)) {
    return false;
  }
  const maxDigits = store.getters["config/getDecimalCount"];
  let str = num.toString();
  let parts = str.split(".");

  // Check if the length of parts is only 1, which means it's integer number
  if (parts.length === 1) {
    return true;
  }
  // Check if the length of the fractional part is less than or equal to the maximum limit
  if (parts[1] && parts[1].length <= maxDigits) {
    return true;
  } else {
    return false;
  }
}

function transformContractPrecalculatedPayments(paymentsList) {
  return paymentsList.map(({ payment_due_amount, ...rest }) =>
    reformatInvoiceDetailsWithDecimals({
      ...rest,
      payment_due_amount,
      actual_paid_amount: payment_due_amount
    })
  );
}

function calculateVatAmount(amount) {
  /**
   * The formulas of calculating Vat amount
   * on both [Back-end, Fron-end] sides :
   * [1] vat_amount = round_up(round_up(actual_paid_amount) * (vat_percentage / 100));
   */
  const fractionDigits = store.getters["config/getDecimalCount"];
  const isCountrySupportsVat = store.getters["config/getIsCountrySupportsVat"];
  const isVatEnabled = store.getters["config/getIsVatEnabled"];
  const vatPercentage = store.getters["config/getVatPercentage"];
  /// Checking whether Country doesn't support Vat or Vat isn't enabled
  if (!isCountrySupportsVat || !isVatEnabled) {
    return reformatAmountWithDecimals(0);
  } else {
    // Apply custom rounding on amount before calculating the vat amount
    const roundedAmount = customRoundUpNumber(amount, fractionDigits);
    const calculatedVatAmount =
      (parseFloat(vatPercentage) / 100) * roundedAmount;
    // Apply custom rounding on Vat amount after being calculated
    const roundedVatAmount = customRoundUpNumber(
      calculatedVatAmount,
      fractionDigits
    );
    return reformatAmountWithDecimals(roundedVatAmount);
  }
}

function calculateNetAmount(amount, vatAmount) {
  /**
   * The formulas of calculating Vat amount and Net amount
   * on both [Back-end, Fron-end] sides :
   * [1] vat_amount = round_up(round_up(actual_paid_amount) * (vat_percentage / 100));
   * [2] net_amount = vat_amount + round_up(actual_paid_amount);
   *
   * [vatAmount] value is being passed to this function as rounded value,
   * So no need to round it up again.
   */

  const fractionDigits = store.getters["config/getDecimalCount"];
  const isCountrySupportsVat = store.getters["config/getIsCountrySupportsVat"];
  const isVatEnabled = store.getters["config/getIsVatEnabled"];

  // Apply custom rounding on amount before performing the Sum operation.
  const roundedAmount = customRoundUpNumber(amount, fractionDigits);

  /// Checking whether Country doesn't support Vat or Vat isn't enabled
  if (!isCountrySupportsVat || !isVatEnabled) {
    return reformatAmountWithDecimals(roundedAmount);
  } else {
    // No Need to apply custom rounding on Net amount, just apply [+] operation
    const calculatedNetAmount =
      parseFloat(vatAmount) + parseFloat(roundedAmount);
    return reformatAmountWithDecimals(calculatedNetAmount);
  }
}

/**
 * A specific function that rounds a number (n)
 * to a specified number of decimal places (decimals).
 * It takes two parameters: n, which is the number to be rounded, and decimals,
 * which is the number of decimal places to round to (default is 0).
 * It uses the same basic arithmetic operations used on Back-end side
 * to achieve the rounding and refines the result based on the last digit if necessary.
 * If the last digit is less than or equal to 5, it changes the last digit to 5.
 * Otherwise, it increments the tens digit and sets the last digit to 0
 * @param {number} n - The number to round up.
 * @param {number} [decimals=0] - The number of decimals to round up to.
 * @returns {string} The rounded up number with the specified decimals as String.
 *
 * Examples:
 * @example
 * customRoundUpNumber(23.678,2) --> output  = "23.70"
 *
 * @example
 * customRoundUpNumber(23.678,3) --> output  = "23.680"
 *
 * @example
 * customRoundUpNumber(23.673,3) --> output  = "23.675"
 *
 * @example
 * customRoundUpNumber(350,2) --> output  = "350.00"
 *
 * @example
 * customRoundUpNumber(0.66,2) --> output  = "0.70"
 */
function customRoundUpNumber(n, decimals = 0) {
  let multiplier = Math.pow(10, decimals);
  let number = Math.ceil(n * multiplier) / multiplier;
  let decimals_count = number.toString().split("").reverse().indexOf(".");

  if (decimals_count === decimals) {
    if (parseInt(number.toString().slice(-1)) <= 5) {
      number = number.toString().split("");
      number[number.length - 1] = "5";
    } else {
      number = number.toString().split("");
      number[number.length - 1] = "0";
      number[number.length - 2] = (
        parseInt(number[number.length - 2]) + 1
      ).toString();
    }
    number = parseFloat(number.join(""));
  }

  return parseFloat(number).toFixed(decimals);
}

export {
  downloadCSV,
  downloadImage,
  downloadFile,
  defaultFormat,
  getFileExtension,
  formatDateForList,
  reshapSelectedMonths,
  transformToDropDown,
  formatDateForPicker,
  capitalizeFirstLetter,
  transformPropertyUnits,
  calcualteNetInvoiceAmount,
  transformPropertiesRequest,
  transformforPropertyUnits,
  transformContractUnit,
  recalculateAndtransformContractUnit,
  getYear,
  numToMonth,
  reformatAmountWithDecimals,
  reformatUnitDetailsWithDecimals,
  reformatInvoiceDetailsWithDecimals,
  reformatInvoicesListWithDecimals,
  checkMaxAllowedFractionDigits,
  transformContractPrecalculatedPayments,
  calculateVatAmount,
  calculateNetAmount,
  customRoundUpNumber
};
