var render = function render(){var _vm=this,_c=_vm._self._c;return (Object.keys(_vm.item).length)?_c('v-layout',{staticClass:"d-flex show-colors",attrs:{"id":`colorBox-${_vm.item.contract_id}-${_vm.item.payment_due_date}`}},[(_vm.item.is_open_balance)?_c('div',{staticClass:"tooltip-column"},[_c('ToolTip',{attrs:{"text":_vm.$t('Open Balance'),"dynamicClass":"bg-open-balance","contentClass":"color-open-balance"}})],1):_vm._e(),(_vm.item.is_grace_period)?_c('div',{staticClass:"tooltip-column"},[_c('ToolTip',{attrs:{"text":_vm.$t('Grace Period'),"dynamicClass":"bg-is-grace","contentClass":"color-is-grace"}})],1):_vm._e(),(_vm.item.is_missed)?_c('div',{staticClass:"tooltip-column"},[_c('ToolTip',{attrs:{"text":_vm.$t('Missed'),"dynamicClass":"bg-is-missed","contentClass":"color-is-missed"}})],1):_vm._e(),(
      (_vm.item.is_electronic_payment_allowed &&
        _vm.item.is_payment_made_in_sequence &&
        !_vm.item.is_previous_payment_paid) ||
        (_vm.item.is_electronic_payment_allowed &&
          (_vm.item.contract_is_suspended || _vm.item.is_suspended))
    )?_c('div',{staticClass:"tooltip-column"},[_c('ToolTip',{attrs:{"text":_vm.$t('Link is not sent'),"dynamicClass":"bg-link-is-not-sent","contentClass":"color-link-is-not-sent"}})],1):_vm._e(),(_vm.item.contract_is_suspended || _vm.item.is_suspended)?_c('div',{staticClass:"tooltip-column"},[_c('ToolTip',{attrs:{"text":_vm.$t('Is Suspended'),"dynamicClass":"bg-is-suspended","contentClass":"color-is-suspended"}})],1):_vm._e(),(
      _vm.item.is_electronic_payment_allowed &&
        _vm.item.is_payment_made_in_sequence &&
        _vm.item.is_previous_payment_paid === false
    )?_c('div',{staticClass:"tooltip-column"},[_c('ToolTip',{attrs:{"text":_vm.$t('Unpaid Previous Invoice'),"dynamicClass":"bg-unpaid-previous-invoice","contentClass":"color-unpaid-previous-invoice"}})],1):_vm._e(),(_vm.item.is_advanced_payment_while_contracting)?_c('div',{staticClass:"tooltip-column"},[_c('ToolTip',{attrs:{"text":_vm.$t('Advanced Payment While Contracting'),"dynamicClass":"bg-advance-payment-while-contracting","contentClass":"color-advance-payment-while-contracting"}})],1):_vm._e(),(
      _vm.item.is_advanced_payment_after_contracting || _vm.item.is_advanced_payment
    )?_c('div',{staticClass:"tooltip-column"},[_c('ToolTip',{attrs:{"text":_vm.$t('Advanced Payment After Contracting'),"dynamicClass":"bg-advance-payment-after-contracting","contentClass":"color-advance-payment-after-contracting"}})],1):_vm._e(),(_vm.isNormalColor)?_c('div',{staticClass:"default-tooltip-column"},[_c('ToolTip',{attrs:{"text":_vm.$t('Normal'),"dynamicClass":"default-color","contentClass":"color-default-color"}})],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }