<template>
  <div class="overflow-data">
    <v-layout
      v-for="(unit, index) in selectedUnits"
      :key="index"
      justify-space-around
      align-center
    >
      <v-select
        v-model="selectedUnits[index].id"
        v-validate="'required||duplicate'"
        :label="$t('Unit Number')"
        item-text="unit_number"
        :no-data-text="$t('Please select a property to load units')"
        :items="units"
        item-value="id"
        :disabled="disableUnitsSelect"
        :name="`unit_number_${index}`"
        :data-vv-name="`unit_number_${index}`"
        :error-messages="
          errors.collect(`${parentValidatorScope}unit_number_${index}`)
        "
        @change="updateSelectedUnits(selectedUnits[index].id, index)"
      />
      <v-select
        v-model="selectedUnits[index].unittype"
        :label="$t('Unit Type')"
        required
        disabled
        item-text="unittype.unit_type_name"
        item-value="unittype"
        :items="units"
        :class="{ 'input-margin': !checkIsSpace(index) }"
      />
      <v-text-field
        v-model="selectedUnits[index].occupied_meters"
        v-if="checkIsSpace(index)"
        v-validate="`required||max_value:${selectedUnits[index].total_space}`"
        class="custom"
        item-value="occupied_meters"
        :disabled="disableUnitsSelect"
        :label="$t('Meters')"
        :items="units"
        :data-vv-name="`occupied_meters_${index}`"
        :error-messages="
          errors.collect(`${parentValidatorScope}occupied_meters_${index}`)
        "
        @input="updateContractAmount(index)"
      />
      <v-text-field
        v-model="selectedUnits[index].price_per_meter"
        v-if="checkIsSpace(index)"
        class="custom"
        disabled
        item-value="price"
        required
        :label="$t('Price')"
        :items="units"
      />
      <v-text-field
        v-model="selectedUnits[index].invoice_amount"
        class="custom"
        disabled
        item-value="invoice_amount"
        item-text="invoice_amount"
        required
        :label="$t('Amount')"
        :items="units"
      />
      <div v-if="!disableUnitsSelect" class="actions">
        <i @click="removeUnit(index)" class="fas fa-times"></i>
      </div>
    </v-layout>
  </div>
</template>

<script>
import { reformatAmountWithDecimals } from "../../helper";
export default {
  props: {
    selectedUnits: Array,
    units: Array,
    outerValidatorScope: {
      type: String,
      default: ""
    },
    disableUnitsSelect: Boolean
  },
  inject: ["parentValidator"],
  created() {
    if (!this.disableUnitsSelect) this.$validator = this.parentValidator;
    this.selectedUnits = this.selectedUnits.filter(unit => unit);
  },
  mounted() {
    this.$validator.extend(
      "duplicate",
      value =>
        !(this.selectedUnits.filter(unit => unit.id === value).length > 1)
    );
    this.$validator.localize("en", this.dictionary);
  },
  methods: {
    checkIsSpace(index) {
      return this.selectedUnits[index].unittype?.is_rentable_by_meter;
    },
    updateSelectedUnits(data, index) {
      this.$emit("updateSelectedUnits", { data, index });
    },
    updateContractAmount(index) {
      const calculatedInvoiceAmount =
        this.selectedUnits[index].price_per_meter *
        this.selectedUnits[index].occupied_meters;
      this.selectedUnits[index].invoice_amount = reformatAmountWithDecimals(
        calculatedInvoiceAmount
      );
      this.$emit("updateContractAmount");
    },
    removeUnit(index) {
      this.selectedUnits.splice(index, 1);
    }
  },
  watch: {
    "$i18n.locale": function () {
      this.$validator.localize(this.$i18n.locale, this.dictionary);
    },
    "selectedUnits.length"() {
      this.$validator.localize(this.$i18n.locale, this.dictionary);
    }
  },
  computed: {
    dictionary() {
      const messages = {};
      for (let i = 0; i < this.selectedUnits.length; i++) {
        messages[`occupied_meters_${i}`] = {
          required: this.$t("This field is required"),
          max_value: this.$t("Meters must be less than total space")
        };
        messages[`unit_number_${i}`] = {
          duplicate: this.$t("Unit added already")
        };
      }
      return {
        custom: messages
      };
    },
    parentValidatorScope() {
      if (this.outerValidatorScope) {
        return `${this.outerValidatorScope}.`;
      } else return "";
    }
  }
};
</script>
<style scoped>
i {
  color: lightgray;
  font-size: large;
}

.overflow-data {
  max-height: 30rem;
  height: auto;
}
.input-margin {
  margin-right: 36%;
}
</style>
