function createQRCodePrintingTemplate(
  lang,
  direction,
  propertyName,
  qrInnerHTML,
  headerTitle,
  descriptionText
) {
  const newTemplate = `
        <!DOCTYPE html>
        <html lang='${lang}' dir='${direction}'>
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${propertyName}</title>
            <style>
            body{
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            </style>
          </head>
          <body>
            <header>
              <h1>${headerTitle}</h1>
            </header>
            <section>
              ${qrInnerHTML}
              <p>${descriptionText}</p>
            </section>
            <script>
              setTimeout(function() {
                window.print();
              }, 1000); // Adjust the timeout value as needed
            <\/script>
          </body>
        </html>`;

  return newTemplate;
}

export { createQRCodePrintingTemplate };
