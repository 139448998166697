import { PluginService } from "./index";

const propertyMaintenanceService = PluginService("/maintenance-tickets");
const maintenanceTicketStatusService = PluginService(
  "/maintenance-ticket-statuses"
);
const maintenanceTicketMessageService = PluginService("/messages");

export default {
  getPropertyMaintenanceTicketsList(params) {
    return propertyMaintenanceService({
      method: "GET",
      url: "/",
      params
    });
  },
  getPropertyMaintenanceTicketById(id) {
    return propertyMaintenanceService({
      method: "GET",
      url: `/${id}/`
    });
  },
  addNewPropertyMaintenanceTicket(params) {
    return propertyMaintenanceService({
      method: "POST",
      url: "/",
      data: params
    });
  },
  updatePropertyMaintenanceTicket(params, id) {
    return propertyMaintenanceService({
      method: "PUT",
      url: `/${id}/`,
      data: params
    });
  },
  getMaintenanceTicketStatusList(params) {
    return maintenanceTicketStatusService({
      method: "GET",
      url: "/",
      params
    });
  },
  getMaintenanceTicketMessagesList(params) {
    return maintenanceTicketMessageService({
      method: "GET",
      url: "/",
      params
    });
  },
  getMaintenanceTicketMessageById(id) {
    return maintenanceTicketMessageService({
      method: "GET",
      url: `/${id}/`
    });
  },
  addNewMaintenanceTicketMessage(params) {
    return maintenanceTicketMessageService({
      method: "POST",
      url: "/",
      data: params
    });
  },
  updateMaintenanceTicketMessage(params, id) {
    return maintenanceTicketMessageService({
      method: "PUT",
      url: `/${id}/`,
      data: params
    });
  }
};
