<template>
  <v-layout
    v-if="Object.keys(item).length"
    :id="`colorBox-${item.contract_id}-${item.payment_due_date}`"
    class="d-flex show-colors"
  >
    <div v-if="item.is_open_balance" class="tooltip-column">
      <ToolTip
        :text="$t('Open Balance')"
        dynamicClass="bg-open-balance"
        contentClass="color-open-balance"
      ></ToolTip>
    </div>
    <div v-if="item.is_grace_period" class="tooltip-column">
      <ToolTip
        :text="$t('Grace Period')"
        dynamicClass="bg-is-grace"
        contentClass="color-is-grace"
      ></ToolTip>
    </div>
    <div v-if="item.is_missed" class="tooltip-column">
      <ToolTip
        :text="$t('Missed')"
        dynamicClass="bg-is-missed"
        contentClass="color-is-missed"
      ></ToolTip>
    </div>
    <div
      v-if="
        (item.is_electronic_payment_allowed &&
          item.is_payment_made_in_sequence &&
          !item.is_previous_payment_paid) ||
          (item.is_electronic_payment_allowed &&
            (item.contract_is_suspended || item.is_suspended))
      "
      class="tooltip-column"
    >
      <ToolTip
        :text="$t('Link is not sent')"
        dynamicClass="bg-link-is-not-sent"
        contentClass="color-link-is-not-sent"
      ></ToolTip>
    </div>
    <div
      v-if="item.contract_is_suspended || item.is_suspended"
      class="tooltip-column"
    >
      <ToolTip
        :text="$t('Is Suspended')"
        dynamicClass="bg-is-suspended"
        contentClass="color-is-suspended"
      ></ToolTip>
    </div>
    <div
      v-if="
        item.is_electronic_payment_allowed &&
          item.is_payment_made_in_sequence &&
          item.is_previous_payment_paid === false
      "
      class="tooltip-column"
    >
      <ToolTip
        :text="$t('Unpaid Previous Invoice')"
        dynamicClass="bg-unpaid-previous-invoice"
        contentClass="color-unpaid-previous-invoice"
      ></ToolTip>
    </div>
    <div
      v-if="item.is_advanced_payment_while_contracting"
      class="tooltip-column"
    >
      <ToolTip
        :text="$t('Advanced Payment While Contracting')"
        dynamicClass="bg-advance-payment-while-contracting"
        contentClass="color-advance-payment-while-contracting"
      ></ToolTip>
    </div>
    <div
      v-if="
        item.is_advanced_payment_after_contracting || item.is_advanced_payment
      "
      class="tooltip-column"
    >
      <ToolTip
        :text="$t('Advanced Payment After Contracting')"
        dynamicClass="bg-advance-payment-after-contracting"
        contentClass="color-advance-payment-after-contracting"
      ></ToolTip>
    </div>
    <div v-if="isNormalColor" class="default-tooltip-column">
      <ToolTip
        :text="$t('Normal')"
        dynamicClass="default-color"
        contentClass="color-default-color"
      ></ToolTip>
    </div>
  </v-layout>
</template>

<script>
import ToolTip from "./ToolTip.vue";
export default {
  name: "ColorShow",
  components: {
    ToolTip
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tooltipMessage: "",
      isNormalColor: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.isNormalColor = !this.isOtherColors();
    });
  },
  watch: {
    item: {
      deep: true,
      handler() {
        this.isNormalColor = !this.isOtherColors();
      }
    }
  },
  methods: {
    isOtherColors() {
      const parentElement = document.getElementById(
        `colorBox-${this.item.contract_id}-${this.item.payment_due_date}`
      );
      const childrenWithClassName = parentElement?.querySelectorAll(
        ".tooltip-column"
      );
      return childrenWithClassName?.length ? true : false;
    }
  }
};
</script>

<style lang="scss" scoped>
.show-colors {
  height: 100%;
}
.tooltip-column {
  width: 100%;
}
.default-tooltip-column {
  width: 100%;
}
</style>
