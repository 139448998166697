<template>
  <div class="edit-property-issue">
    <v-layout class="pt-5">
      <v-flex grow pa-1 xs6>
        <h2 class="display-2 pl-4">{{ $t("Types") }}</h2>
      </v-flex>
      <v-flex shrink pa-1 xs6 text-xs-right>
        <v-btn
          dark
          class="mt-4 r-m-end"
          :disabled="isLoading"
          @click="globalGoBack"
          >← {{ $t("Back") }}</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex class="flex-wrap" xs12 pa-1>
        <v-breadcrumbs :items="breadItemsSetLang()" class="pl-4">
          <template v-slot:divider>
            <v-icon>fiber_manual_record</v-icon>
          </template>
        </v-breadcrumbs>
      </v-flex>
    </v-layout>
    <v-form>
      <v-layout row wrap justify-center>
        <v-layout row wrap justify-center>
          <v-flex xs12 sm12 lg9>
            <vue-skeleton-loader
              rounded
              class="w-full"
              type="rect"
              v-if="isLoading"
              :height="'358px'"
              :width="'100%'"
              animation="fade"
            />
            <v-card v-if="!isLoading">
              <v-card-title>
                <h3>
                  {{ isEdit ? $t("Edit") : $t("Add") }}
                  {{ $t("Maintenance Type") }}
                </h3>
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="issue.name_en"
                  v-validate="'required'"
                  :label="$t('Maintenance Type Name (EN)')"
                  required
                  class="mb-2 pb-2"
                  data-vv-name="name_en"
                  :error-messages="errors.collect('name_en')"
                />

                <v-text-field
                  v-model="issue.name_ar"
                  v-validate="'required'"
                  :label="$t('Maintenance Type Name (AR)')"
                  required
                  class="mb-2 pb-2"
                  data-vv-name="name_ar"
                  :error-messages="errors.collect('name_ar')"
                />
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-layout>
      <div class="text-xs-center mt-4">
        <v-btn
          dark
          class="re-orangtbtn"
          :disabled="isLoading"
          @click="storeData"
          >{{ $t("Save") }}</v-btn
        >
        <v-btn dark :disabled="isLoading" @click="globalGoBack">{{
          $t("Cancel")
        }}</v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import propertyIssueService from "../../services/plugins/property_issue";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";

export default {
  name: "add_property_issue",
  $_veeValidate: {
    validator: "new"
  },
  mixins: [breadItemsSetLang],
  async mounted() {
    let isEdit = false;
    this.$validator.localize("en", this.dictionary);

    if (this.$route.params.id) {
      await this.fetchPropertyIssue(this.$route.params.id);
      isEdit = true;
    }

    this.isEdit = isEdit;
  },
  data() {
    return {
      isLoading: false,
      issue: {},
      isEdit: false,
      breadCrumbs: []
    };
  },
  watch: {
    "$i18n.locale": function () {
      this.$validator.localize(this.$i18n.locale, this.dictionary);
    }
  },
  computed: {
    dictionary() {
      return {
        custom: {
          name_en: {
            required: () =>
              this.$t("Maintenance Type Name(EN) can not be empty")
          },
          name_ar: {
            required: () =>
              this.$t("Maintenance Type Name(AR) can not be empty")
          }
        }
      };
    }
  },
  methods: {
    async fetchPropertyIssue(id) {
      try {
        this.isLoading = true;
        const resp = await this.$processReq(
          propertyIssueService.getPropertyIssueById(id),
          false
        );
        this.issue = resp.data.body;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log("Error: Unable to get maintenance type by ID - ", error);
      }
    },
    async storeData() {
      if (await this.$validator.validateAll()) {
        const data = {
          ...this.issue,
          name: this.issue.name_en,
          description: this.issue.name
        };

        this.isLoading = true;
        try {
          if (this.isEdit) {
            await this.$processReq(
              propertyIssueService.updatePropertyIssue(data, data.id),
              true
            );
          } else {
            await this.$processReq(
              propertyIssueService.addNewPropertyIssue(data),
              true
            );
          }
          this.isLoading = false;
          this.globalGoBack();
        } catch (err) {
          this.isLoading = false;
          console.log(
            "Error: Unable to update or add new maintenance type - ",
            err
          );
        }
      }
    }
  }
};
</script>
