import { PluginService } from ".";

const advPayment = PluginService("/advpay");
const paymentService = PluginService("/payment");

export default {
  fetchAdvPayment(params) {
    return advPayment({
      method: "GET",
      url: "/",
      params
    });
  },
  getPaymentList(params) {
    return paymentService({
      method: "GET",
      url: "/",
      params
    });
  },
  getPaymentOptions() {
    return paymentService({
      method: "OPTIONS",
      url: "/"
    });
  },
  getPaymentDetails(id) {
    return paymentService({
      method: "GET",
      url: `/${id}`
    });
  },
  getContractPayments(id, params) {
    return paymentService({
      method: "GET",
      url: `/contract/${id}`,
      params
    });
  },
  addNewPayment(data) {
    return paymentService({
      method: "POST",
      url: "/",
      data
    });
  },
  updatePayment(data, id) {
    return paymentService({
      method: "PUT",
      url: `/${id}/`,
      data
    });
  },
  checkPayment(params) {
    return paymentService({
      method: "GET",
      url: "/check/",
      params
    });
  },
  notifyPayments(params) {
    return paymentService({
      method: "GET",
      url: "/notify/",
      params
    });
  },
  getPaymentReminder(payment_id) {
    return paymentService({
      method: "GET",
      url: `/notify-single-payment/${payment_id}/`
    });
  },
  deleteGeneratedPayment(payment_id) {
    return paymentService({
      method: "DELETE",
      url: `/${payment_id}/rollback-generated-payment/`
    });
  }
};
