<template>
  <v-layout wrap justify-center>
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 lg10>
        <v-layout class="pt-5" wrap>
          <v-flex grow pa-1 xs10 sm7 md8>
            <v-layout align-start wrap>
              <h2 class="display-2 pl-4">{{ $t("Bank List") }}</h2>
            </v-layout>
          </v-flex>
          <v-flex shrink pa-1 xs2 sm5 md4 class="re-rel-pos" text-xs-right>
            <v-btn
              dark
              class="re-orangtbtn"
              :disabled="isLoading"
              :to="{ name: 'add_bank' }"
              >+ {{ $t("Add New Bank") }}</v-btn
            >
            <v-btn
              fab
              small
              outline
              v-on="on"
              :disabled="isLoading"
              class="re-abs-pos"
            >
              <v-icon>history</v-icon>
            </v-btn>
          </v-flex>
          <v-flex shrink pa-1 xs6 text-xs-right> </v-flex>
        </v-layout>

        <v-layout>
          <v-flex class="flex-wrap" xs12 pa-1>
            <v-breadcrumbs :items="breadItemsSetLang()" class="pl-4">
              <template #divider>
                <v-icon>fiber_manual_record</v-icon>
              </template>
            </v-breadcrumbs>
          </v-flex>
        </v-layout>

        <table-loader v-if="isLoading" />
        <v-card v-if="!isLoading">
          <v-toolbar class="re-toolbar re-wrap">
            <div class="re-flex">
              {{ $t("Rows Per Page") }}
              <v-radio-group v-model="row" class="re-radios">
                <v-radio :label="$t('All')" value="show-all" />
              </v-radio-group>
            </div>
            <v-spacer />
            <v-text-field
              hide-details
              v-model="searchFilter.search"
              :placeholder="$t('Search')"
              single-line
            />
            <v-spacer />
          </v-toolbar>

          <v-layout style="overflow: auto">
            <v-data-table
              hide-actions
              class="width100"
              :headers="headers"
              :items="banks"
              :pagination.sync="sortingParams"
              :options.sync="pagination"
              :server-items-length="pagination.totalItems"
              :no-data-text="$t('No data available')"
            >
              <template v-slot:items="props">
                <tr @click="dialog = true">
                  <td
                    class="re-orange-color"
                    :class="{
                      'text-xs-right': $vuetify.rtl,
                      'text-xs-left': !$vuetify.rtl
                    }"
                  >
                    <div>{{ props.item.id }}</div>
                  </td>
                  <td
                    :class="{
                      'text-xs-right': $vuetify.rtl,
                      'text-xs-left': !$vuetify.rtl
                    }"
                  >
                    <div>{{ props.item.bank_name }}</div>
                  </td>
                  <td class="re-td text-xs-center ">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">more_vert</v-icon>
                      </template>
                      <v-list>
                        <v-list-tile
                          class="list_tile"
                          style="cursor:pointer"
                          @click="
                            $router.push({
                              name: 'edit_bank',
                              params: { id: props.item.id }
                            })
                          "
                        >
                          <v-list-tile-title>{{
                            $t("Edit")
                          }}</v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-layout>
        </v-card>
        <div class="overflow-hidden text-xs-center pt-4" v-if="!isLoading">
          <v-pagination
            v-model="pagination.page"
            :length="pages"
            :total-visible="10"
            class="re-pagination"
            @input="fetchData(false)"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import bankService from "../../services/plugins/bank";
import TableLoader from "../loaders/TableLoader.vue";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";
import paginationMixin from "@/mixins/paginationMixin";

export default {
  name: "property_classification_list",
  props: ["on"],
  components: {
    TableLoader
  },
  mixins: [breadItemsSetLang, paginationMixin],
  async created() {
    await this.fetchData();
  },
  data() {
    return {
      isLoading: false,
      breadCrumbs: [],
      banks: [],
      row: ""
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("ID"),
          align: !this.$vuetify.rtl ? "left" : "right",
          value: "id"
        },
        {
          text: this.$t("Bank Name"),
          align: !this.$vuetify.rtl ? "left" : "right",
          value: "bank_name"
        },
        {
          text: this.$t("Actions"),
          align: "center",
          sortable: false
        }
      ];
    }
  },
  watch: {
    "$i18n.locale": function() {
      this.fetchData();
    },
    order() {
      if (this.row !== "show-all") {
        this.fetchData();
      }
    },
    "searchFilter.search": function() {
      this.fetchData(true);
    },
    row() {
      this.fetchData();
    }
  },
  methods: {
    async fetchData(blockLoading) {
      if (!blockLoading) {
        this.isLoading = true;
      }
      try {
        let params = {
          ...this.setSortingParams(),
          ...this.setPaginationParams()
        };
        const list = await this.$processReq(
          bankService.getBankList(params),
          false
        );
        this.banks = list.data.body.results;
        this.setPagination(list.data.body.count);
        this.showAllPagination();
      } catch (error) {
        this.$root.$emit("snackbar", {
          text: this.$t("Something went wrong while fetching results"),
          color: "error"
        });
        console.log(error, "Error: Fetching Result of profiles");
      }
      this.isLoading = false;
    }
  }
};
</script>

<style lang="css" scoped>
.list_tile {
  width: 8rem;
}
.list_tile:hover {
  background: #dcdcdc;
  color: orange;
}
</style>
