<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    :top="openOnTop"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <div
        :class="{ 'disabled-div': isDisabled }"
        class="re-custom-input input-max-width dummy-input-field"
        v-on="on"
      >
        {{
          isParent
            ? invoiceItem.currentInvoice.actual_paid_amount
            : invoiceItem.actual_paid_amount
        }}
      </div>
      <span class="red-text">
        {{
          errors.collect(dataVvName).length ? errors.first(dataVvName) : ""
        }}</span
      >
    </template>
    <v-card>
      <v-card-text class="price-editor-modal">
        <v-text-field
          v-model="changePaidAmount"
          v-validate="
            `required|max_value:${maxAmount}|min_value:1|decimal:${getDecimalCount}`
          "
          :name="dataVvName"
          :data-vv-name="dataVvName"
          class="re-custom-input input-max-width"
          :disabled="isDisabled"
          @input="$emit('input', changePaidAmount)"
        />
        <span class="red-text">
          {{
            errors.collect(dataVvName).length ? errors.first(dataVvName) : ""
          }}</span
        >
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PriceEditor",
  props: {
    invoiceItem: {
      type: Object,
      default: null
    },
    isParent: {
      type: Boolean,
      default: false
    },
    maxAmount: String,
    initialValue: String,
    isDisabled: {
      type: Boolean,
      default: false
    },
    dataVvName: {
      type: String,
      default: ""
    }
  },
  inject: ["parentValidator"],
  data() {
    return {
      changePaidAmount: ""
    };
  },
  created() {
    this.$validator = this.parentValidator;
    this.changePaidAmount = this.initialValue;
  },
  watch: {
    initialValue(val) {
      if (val && val != this.changePaidAmount) {
        this.changePaidAmount = val;
      }
    }
  },
  computed: {
    ...mapGetters("config", ["getDecimalCount"])
  }
};
</script>

<style lang="scss" scoped>
.input-max-width {
  max-width: 123px !important;
  margin: auto;
}
.dummy-input-field {
  border: 1px solid black;
  padding: 0 8px;
  background: #f2f2f2;
  font-size: 16px;
}
.re-custom-input {
  border-radius: 20px;
}

.disabled-div {
  opacity: 0.5;
  pointer-events: none;
}

.price-editor-modal {
  width: 20rem;
  height: auto;
  ::v-deep .v-input__slot {
    input {
      padding: 1px 8px;
    }
    border-radius: 20px;
    background: #f2f2f2 !important;
    &:before {
      border: none;
    }
  }
}
</style>
