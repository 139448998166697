import contractService from "../services/plugins/contract";
import paymentService from "../services/plugins/payment";
export default {
  data() {
    return {
      paymentMethods: [],
      paymentPeriods: []
    };
  },
  created() {
    this.fetchContractOptions();
    this.fetchPaymentOptions();
  },
  computed: {
    contractPaymentMethods() {
      return this.paymentMethods.filter(e => e.value !== "not determined");
    }
  },
  methods: {
    async fetchPaymentOptions() {
      try {
        const resp = await this.$processReq(
          paymentService.getPaymentOptions(),
          false
        );
        this.paymentMethods =
          resp.data?.body?.actions?.POST?.payment_method?.choices;
      } catch (err) {
        console.log("Error: Unable to fetch payment options - ", err);
      }
    },
    async fetchContractOptions() {
      try {
        const resp = await this.$processReq(
          contractService.fetchContractOptions(),
          false
        );
        this.paymentPeriods =
          resp.data?.body?.actions?.POST?.payment_frequency?.choices;
        if (this.isNewContract) {
          this.contract.payment_frequency =
            this.paymentPeriods.find(option => option.value == "monthly")
              ?.value || "";
        }
      } catch (err) {
        console.log("Error: Unable to fetch contract options - ", err);
      }
      this.isLoading = false;
    }
  },
  watch: {
    "$i18n.locale": function() {
      this.fetchContractOptions();
      this.fetchPaymentOptions();
    }
  }
};
