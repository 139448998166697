import { PluginService } from "./index";

const propertyClassificationService = PluginService("/properties/classes");

export default {
  getPropertyClassificationList(params) {
    return propertyClassificationService({
      method: "GET",
      url: "/",
      params
    });
  },
  getPropertyClassificationById(id) {
    return propertyClassificationService({
      method: "GET",
      url: `/${id}/`
    });
  },
  addNewPropertyClassification(params) {
    return propertyClassificationService({
      method: "POST",
      url: "/",
      data: params
    });
  },
  updatePropertyClassification(params, id) {
    return propertyClassificationService({
      method: "PUT",
      url: `/${id}/`,
      data: params
    });
  }
};
