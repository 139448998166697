const state = {
  country: "",
  decimalCount: 0,
  currencyCode: "",
  vatPercentage: "0.00",
  isActivated: false,
  isVatEnabled: false,
  defaultPhoneCountryCode: "",
  phoneInputPreferredCountries: []
};

const getters = {
  getCountry: state => {
    return state.country;
  },
  getIsActivated: state => {
    return state.isActivated;
  },
  getDecimalCount: state => {
    return state.decimalCount;
  },
  getCurrencyCode: state => {
    return state.currencyCode;
  },
  getIsCountrySupportsVat: state => {
    return parseFloat(state.vatPercentage) > 0; // It's calculated property, if vat percentage is greater than 0, then country suports vat
  },
  getVatPercentage: state => {
    return state.vatPercentage;
  },
  getIsVatEnabled: state => {
    return state.isVatEnabled;
  },
  getDefaultPhoneCountryCode: state => {
    return state.defaultPhoneCountryCode; // The default phone country code should be matched to selected country in configuration
  },
  getPhoneInputPreferredCountries: state => {
    return state.phoneInputPreferredCountries;
  }
};

const actions = {
  setCountry({ commit }, value) {
    commit("setCountry", value);
  },
  setIsActivated({ commit }, value) {
    commit("setIsActivated", value);
  },
  setDecimalCount({ commit }, value) {
    commit("setDecimalCount", value);
  },
  setCurrencyCode({ commit }, value) {
    commit("setCurrencyCode", value);
  },
  setVatPercentage({ commit }, value) {
    commit("setVatPercentage", value);
  },
  setIsVatEnabled({ commit }, value) {
    commit("setIsVatEnabled", value);
  },
  setDefaultPhoneCountryCode({ commit }, value) {
    commit("setDefaultPhoneCountryCode", value);
  },
  setPhoneInputPreferredCountries({ commit }, value) {
    commit("setPhoneInputPreferredCountries", value);
  }
};

const mutations = {
  setCountry(state, value) {
    state.country = value;
  },
  setIsActivated(state, value) {
    state.isActivated = value;
  },
  setDecimalCount(state, value) {
    state.decimalCount = value;
  },
  setCurrencyCode(state, value) {
    state.currencyCode = value;
  },
  setVatPercentage(state, value) {
    state.vatPercentage = value;
  },
  setIsVatEnabled(state, value) {
    state.isVatEnabled = value;
  },
  setDefaultPhoneCountryCode(state, value) {
    state.defaultPhoneCountryCode = value;
  },
  setPhoneInputPreferredCountries(state, value) {
    state.phoneInputPreferredCountries = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
