<template>
  <div class="advance-payment">
    <v-layout wrap justify-center>
      <v-layout wrap justify-center>
        <v-flex xs12 sm12 lg10>
          <v-layout class="pt-5" wrap>
            <v-flex grow pa-1 xs10 sm7 md8>
              <v-layout align-start justify-space-between wrap>
                <h2 class="display-2">{{ $t("Advance Payment List") }}</h2>
                <v-select
                  :items="items3"
                  :label="$t('All')"
                  solo
                  class="re-rent w-100"
                ></v-select>
              </v-layout>
            </v-flex>
            <v-flex shrink pa-1 xs2 sm5 md4 class="re-rel-pos text-xs-right">
              <v-btn fab small outline v-on="on" class="re-abs-pos">
                <v-icon>history</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout class="re-mm-24 n-margin-left">
            <v-flex>
              <v-breadcrumbs :items="breadItemsSetLang()" class="pl-4">
                <template v-slot:divider>
                  <v-icon>fiber_manual_record</v-icon>
                </template>
              </v-breadcrumbs>
            </v-flex>
          </v-layout>

          <table-loader v-if="isLoading" />
          <v-card v-show="!isLoading">
            <v-toolbar class="re-toolbar re-wrap">
              <div class="re-flex">
                {{ $t("Rows Per Page") }}
                <v-radio-group
                  v-model="row"
                  class="re-radios"
                  @change="fetchPayments(false)"
                >
                  <v-radio label="all" value="show-all"></v-radio>
                </v-radio-group>
              </div>
              <v-spacer />
              <v-text-field
                v-model="search"
                hide-details
                :placeholder="$t('Search')"
                single-line
                @input.native="fetchPayments"
              />

              <v-spacer />

              <contract-list-filter
                v-model="filter"
                filterType="advance_payment"
                @fetchResult="fetchPayments"
              />
            </v-toolbar>

            <v-layout style="overflow: auto">
              <v-data-table
                :headers="headers"
                :items="payments"
                :pagination.sync="sortBy"
                :options.sync="pagination"
                :server-items-length="pagination.totalItems"
                :no-data-text="$t('No data available')"
                hide-actions
                class="width100"
              >
                <template v-slot:items="props">
                  <tr @click="showDialog(props.item)">
                    <td class="text-xs-left re-td re-orange-color div">
                      <div>{{ props.item.contract_id }}</div>
                    </td>
                    <td class="text-xs-center div">
                      <div>{{ formatDate(props.item.modified, true) }}</div>
                    </td>
                    <td class="text-xs-center">
                      <div>{{ props.item.initiator }}</div>
                    </td>
                    <td class="text-xs-center">
                      <div>{{ props.item.tenant_name }}</div>
                    </td>
                    <td class="text-xs-center">
                      <div>{{ props.item.property_name }}</div>
                    </td>
                    <td class="text-xs-center">
                      <div>{{ props.item.unit_number }}</div>
                    </td>
                    <td class="text-xs-center">
                      <div>{{ props.item.contract_reference }}</div>
                    </td>
                    <td class="text-xs-center">
                      <div>
                        {{ formatDate(props.item.contract_start_date) }}
                      </div>
                    </td>
                    <td class="text-xs-center">
                      <div>{{ formatDate(props.item.contract_end_date) }}</div>
                    </td>
                    <td class="text-xs-center">
                      <div>
                        {{ props.item.net_amount }}
                      </div>
                    </td>
                    <!-- <td class="text-xs-center" :class="{ ongoing: true }">
                      <div>{{ props.item.status }}</div>
                    </td> -->
                    <td v-on:click.stop>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <img
                            v-on="on"
                            class="actions"
                            src="@/assets/dotedVertical.svg"
                          />
                        </template>
                        <v-list>
                          <v-list-tile
                            v-for="(action, index) in actions"
                            :key="index"
                            @click="showAlert(props.item)"
                          >
                            <v-list-tile-title>{{
                              action.title
                            }}</v-list-tile-title>
                          </v-list-tile>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-layout>
          </v-card>
          <div class="overflow-hidden text-xs-center pt-4">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
              :total-visible="10"
              class="re-pagination"
              @input="fetchPayments(false)"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
    </v-layout>
    <template>
      <div class="text-xs-center">
        <advance-payment-dialog
          v-model="dialog"
          :selectedPayment="selectedPayment"
        />
      </div>
    </template>

    <template>
      <div class="text-xs-center">
        <confirmation-dialog
          v-model="alert"
          :label="$t('Confirmation')"
          :message="
            $t('Are you sure that you want to roll back the advance payment?')
          "
          @acceptDialog="acceptDialog"
          @closeDialog="closeDialog"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { isEmpty } from "lodash";

import AdvancePaymentDialog from "../components/dialog/AdvancePaymentDialog.vue";
import ConfirmationDialog from "../components/dialog/ConfirmationDialog.vue";
import { ContractEnums } from "../enums";
import { defaultFormat, reformatInvoicesListWithDecimals } from "../helper";
import { transformContractFilter } from "../helper/filters";
import logs from "../services/plugins/logs";

import ContractListFilter from "./contracts/List/ContractListFilter.vue";
import TableLoader from "./loaders/TableLoader.vue";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";

export default {
  name: "AdvancePaymentList",
  components: {
    TableLoader,
    ContractListFilter,
    AdvancePaymentDialog,
    ConfirmationDialog
  },
  mixins: [breadItemsSetLang],
  data() {
    return {
      dialog: false,
      alert: false,
      row: "",
      pagination: {
        rowsPerPage: 10
      },
      sortBy: {
        rowsPerPage: 10
      },
      selectedPayment: {},
      selectedPaymentId: null,
      selectedInvoiceGroupId: null,
      filter: {},
      isLoading: false,
      totalCount: 0,
      search: "",
      payments: []
    };
  },
  computed: {
    headers() {
      return [
        { text: this.$t("Contract ID"), align: "left", sortable: false },
        { text: this.$t("Created"), align: "center", sortable: false },
        { text: this.$t("Initiator"), align: "center", sortable: false },
        { text: this.$t("Tenant"), align: "center", sortable: false },
        { text: this.$t("Property"), align: "center", sortable: false },
        { text: this.$t("Units"), align: "center", sortable: false },
        {
          text: this.$t("Contract Reference"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Contract Start Date"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Contract End Date"),
          align: "center",
          sortable: false
        },
        { text: this.$t("Net Amount"), align: "center", sortable: false },
        // { text: this.$t("Status"), align: "center", sortable: false },
        { text: this.$t("Actions"), align: "center", sortable: false }
      ];
    },
    actions() {
      return [
        {
          title: this.$t("Advance Payment Roll back"),
          value: "advance_payment_rollback",
          route: ""
        }
      ];
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    items3() {
      return [
        this.$t("All"),
        this.$t("Paid"),
        this.$t("Pending"),
        this.$t("Late")
      ];
    }
  },
  created() {
    this.fetchPayments();
  },
  methods: {
    showDialog(item) {
      this.selectedPayment = item;
      this.dialog = true;
    },
    closeDialog() {
      this.selectedInvoiceGroupId = null;
      this.alert = false;
    },
    acceptDialog() {
      this.alert = false;
      this.advancePaymentRollback();
    },
    async showAlert(payment) {
      this.selectedPaymentId = payment.id;
      await this.getInvoiceGroupId();
      if (this.selectedInvoiceGroupId) {
        this.alert = true;
      }
    },
    async fetchPayments(data) {
      try {
        if (!data) {
          this.isLoading = true;
        }
        let params = {
          limit: 10,
          offset: this.pagination.page ? (this.pagination.page - 1) * 10 : 0,
          search: this.search || ""
        };

        if (this.row === "show-all") {
          this.payments = [];
          params = {
            ...params,
            limit: this.totalCount,
            offset: 0
          };
        }

        if (!isEmpty(this.filter)) {
          params = {
            ...params,
            ...transformContractFilter(this.filter, true)
          };
        }
        const resp = await this.$processReq(
          logs.fetchPaymentList(ContractEnums.AdvancePayment, params),
          false
        );
        this.payments = reformatInvoicesListWithDecimals(
          resp.data.body.results
        );
        this.isLoading = false;
        this.pagination = {
          ...this.pagination,
          totalItems: resp.data.body.count,
          page: this.pagination.page > 0 ? this.pagination.page : 1,
          rowsPerPage: this.row !== "" ? resp.data.body.count : 10
        };

        if (this.totalCount <= resp.data.body.count) {
          this.totalCount = resp.data.body.count;
        }

        if (this.row === "show-all") {
          this.pagination = {
            ...this.pagination,
            rowsPerPage: this.totalCount
          };
          this.sortBy = {
            ...this.sortBy,
            rowsPerPage: this.totalCount
          };
        }
      } catch (err) {
        console.log("Error: Unable to Fetch payment list - ", err);
      }
    },
    formatDate(value, containsTime) {
      return defaultFormat(value, containsTime);
    },
    async getInvoiceGroupId() {
      try {
        this.isLoading = true;
        const resp = await this.$processReq(
          logs.getPaymentDetailsById(this.selectedPaymentId),
          false
        );
        this.selectedInvoiceGroupId = resp.data.body.invoice_group_id;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log("Error: Unable to fetch payment details- ", error);
      }
    },
    async advancePaymentRollback() {
      try {
        this.isLoading = true;
        await this.$processReq(
          logs.advancePaymentRollback(this.selectedInvoiceGroupId)
        );
        this.fetchPayments(false);
      } catch (error) {
        this.isLoading = false;
        console.log("Error: Unable to delete advance payment - ", error);
      }
    }
  }
};
</script>

<style scoped>
.v-dialog .headline {
  max-width: 420px !important;
  padding: 0px 23px !important;
  padding-top: 10px !important;
}

.display-2 {
  width: 20rem;
}
</style>
