import { PluginService } from "./index";

const contractServices = PluginService("/contract");

export default {
  fetchContractValidation(params) {
    return contractServices({
      method: "GET",
      url: "/unit/validate/",
      params
    });
  },
  fetchContractList(params) {
    return contractServices({
      method: "GET",
      url: "/",
      params
    });
  },
  fetchContractListMiniDetails(params) {
    return contractServices({
      method: "GET",
      url: "/mini/",
      params
    });
  },
  fetchContractOptions() {
    return contractServices({
      method: "OPTIONS",
      url: "/"
    });
  },
  fetchContractById(id) {
    return contractServices({
      method: "GET",
      url: `/${id}/`
    });
  },
  saveContract(data) {
    return contractServices({
      method: "POST",
      url: "/",
      data
    });
  },
  updateContract(data, id) {
    return contractServices({
      method: "PUT",
      url: `/${id}/`,
      data
    });
  },
  renewContract(data, id) {
    return contractServices({
      method: "PUT",
      url: `/${id}/renew-contract/`,
      data
    });
  },
  terminateContract(data, id) {
    return contractServices({
      method: "PATCH",
      url: `/${id}/terminate/`,
      data
    });
  },
  resumeContract(id) {
    return contractServices({
      method: "PATCH",
      url: `/${id}/resume/`
    });
  },
  suspendContract(id) {
    return contractServices({
      method: "PATCH",
      url: `/${id}/suspend/`
    });
  },
  updateContractAmount(data, id) {
    return contractServices({
      method: "PUT",
      url: `/${id}/amount/`,
      data
    });
  },
  validateUnit(params) {
    return contractServices({
      method: "GET",
      url: `/unit/validate/`,
      params
    });
  },
  uploadImages(data) {
    return contractServices({
      method: "POST",
      url: "/image/upload",
      data
    });
  },
  downloadImage(id) {
    return contractServices({
      method: "GET",
      url: `/image/download/${id}/`,
      responseType: "blob"
    });
  }
};
