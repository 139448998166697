import { PluginService } from "./index";

const propertyIssueService = PluginService("/fix-types");

export default {
  getPropertyIssuesList(params) {
    return propertyIssueService({
      method: "GET",
      url: "/",
      params
    });
  },
  getPropertyIssueById(id) {
    return propertyIssueService({
      method: "GET",
      url: `/${id}/`
    });
  },
  addNewPropertyIssue(params) {
    return propertyIssueService({
      method: "POST",
      url: "/",
      data: params
    });
  },
  updatePropertyIssue(params, id) {
    return propertyIssueService({
      method: "PUT",
      url: `/${id}/`,
      data: params
    });
  }
};
