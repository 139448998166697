<template>
  <div class="edit-unit-type">
    <v-layout class="pt-5">
      <v-flex grow pa-1 xs6>
        <h2 class="display-2 pl-4">{{ $t("Properties") }}</h2>
      </v-flex>
      <v-flex shrink pa-1 xs6 text-xs-right>
        <v-btn
          dark
          class="mt-4 r-m-end"
          :disabled="isLoading"
          @click="globalGoBack"
          >← {{ $t("Back") }}</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex class="flex-wrap" xs12 pa-1>
        <v-breadcrumbs :items="breadItemsSetLang()" class="pl-4">
          <template v-slot:divider>
            <v-icon>fiber_manual_record</v-icon>
          </template>
        </v-breadcrumbs>
      </v-flex>
    </v-layout>
    <v-form>
      <v-layout row wrap justify-center>
        <v-layout row wrap justify-center>
          <v-flex xs12 sm12 lg9>
            <vue-skeleton-loader
              rounded
              class="w-full"
              type="rect"
              v-if="isLoading"
              :height="'358px'"
              :width="'100%'"
              animation="fade"
            />
            <v-card v-if="!isLoading">
              <v-card-title>
                <h3>
                  {{ isEdit ? $t("Edit") : $t("Add") }}
                  {{ $t("Property Classification") }}
                </h3>
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="classification.class_name_en"
                  v-validate="'required'"
                  :label="$t('Property Classification Name (EN)')"
                  required
                  class="mb-2 pb-2"
                  data-vv-name="property_classification_name_en"
                  :error-messages="
                    errors.collect('property_classification_name_en')
                  "
                />

                <v-text-field
                  v-model="classification.class_name_ar"
                  v-validate="'required'"
                  :label="$t('Property Classification Name (AR)')"
                  required
                  class="mb-2 pb-2"
                  data-vv-name="property_classification_name_ar"
                  :error-messages="
                    errors.collect('property_classification_name_ar')
                  "
                />
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-layout>
      <div class="text-xs-center mt-4">
        <v-btn
          dark
          class="re-orangtbtn"
          :disabled="isLoading"
          @click="storeData"
          >{{ $t("Save") }}</v-btn
        >
        <v-btn dark :disabled="isLoading" @click="globalGoBack">{{
          $t("Cancel")
        }}</v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import propertyClassificationService from "../../services/plugins/property_classification";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";

export default {
  name: "add_property_classification",
  $_veeValidate: {
    validator: "new"
  },
  mixins: [breadItemsSetLang],
  async mounted() {
    let isEdit = false;
    this.$validator.localize("en", this.dictionary);

    if (this.$route.params.id) {
      await this.fetchPropertyClassification(this.$route.params.id);
      isEdit = true;
    }

    this.isEdit = isEdit;
  },
  data() {
    return {
      isLoading: false,
      classification: {},
      isEdit: false,
      breadCrumbs: []
    };
  },
  watch: {
    "$i18n.locale": function() {
      this.$validator.localize(this.$i18n.locale, this.dictionary);
    }
  },
  computed: {
    dictionary() {
      return {
        custom: {
          property_classification_name_en: {
            required: () =>
              this.$t("Property Classification Name(EN) can not be empty")
          },
          property_classification_name_ar: {
            required: () =>
              this.$t("Property Classification Name(AR) can not be empty")
          }
        }
      };
    }
  },
  methods: {
    async fetchPropertyClassification(id) {
      try {
        this.isLoading = true;
        const resp = await this.$processReq(
          propertyClassificationService.getPropertyClassificationById(id),
          false
        );
        this.classification = resp.data.body;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(
          "Error: Unable to get property classification by ID - ",
          error
        );
      }
    },
    async storeData() {
      if (await this.$validator.validateAll()) {
        const response = {
          ...this.classification,
          class_name: this.classification.class_name_en
        };

        this.isLoading = true;
        try {
          if (this.isEdit) {
            await this.$processReq(
              propertyClassificationService.updatePropertyClassification(
                response,
                response.id
              ),
              true
            );
          } else {
            await this.$processReq(
              propertyClassificationService.addNewPropertyClassification(
                response
              ),
              true
            );
          }
          this.isLoading = false;
          this.globalGoBack();
        } catch (err) {
          this.isLoading = false;
          console.log(
            "Error: Unable to update or add new property classification - ",
            err
          );
        }
      }
    }
  }
};
</script>
