<template>
  <v-tooltip top :content-class="contentClass">
    <template v-slot:activator="{ on }">
      <div v-on="on" :class="`color-column ${dynamicClass}`"></div>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "tooltip",
  props: {
    text: {
      type: String,
      default: ""
    },
    dynamicClass: {
      type: String,
      default: ""
    },
    contentClass: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.color-column {
  height: 100%;
}
.v-tooltip__content {
  background-color: white;
  border-radius: 10px;
  border: 1px solid;
  font-size: 1rem;
  font-weight: bold;
  opacity: 1 !important;
  padding: 1rem;
}
.v-tooltip__content.color-is-missed {
  background-color: #8e8e8e;
}
.v-tooltip__content.color-is-missed::before {
  background-color: #8e8e8e;
}
.v-tooltip__content::before {
  content: "";
  position: absolute;
  bottom: -7.5px;
  left: 45%;
  width: 15px;
  height: 15px;
  opacity: 1 !important;
  background-color: white;
  transform: rotate(45deg);
  border-width: 0px 2px 2px 0px;
  border-style: solid;
  border-color: inherit;
  z-index: 9;
}
</style>
