<template>
  <div class="advance-payment">
    <v-layout class="pt-5">
      <v-flex grow pa-1 xs6>
        <h2 class="display-2 pl-4">{{ $t("Property") }}</h2>
      </v-flex>
      <v-flex shrink pa-1 xs6 text-xs-right>
        <v-btn dark class="mt-4 r-m-end" @click="globalGoBack"
          >← {{ $t("Back") }}</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex class="flex-wrap" xs12 pa-1>
        <v-breadcrumbs :items="breadItemsSetLang()" class="pl-4">
          <template v-slot:divider>
            <v-icon>fiber_manual_record</v-icon>
          </template>
        </v-breadcrumbs>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-layout row wrap justify-center>
        <v-flex xs12 sm12 lg9>
          <v-card v-show="!isLoading">
            <v-card-title>
              <h3>{{ $t("Advance Payment Request") }}</h3>
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-autocomplete
                  v-model="payment.property"
                  v-validate="'required'"
                  :label="$t('Property Name')"
                  data-vv-name="property"
                  item-text="property_name"
                  item-value="id"
                  clearable
                  :disabled="disableContractSelectors"
                  :error-messages="errors.collect('property')"
                  :items="properties"
                  @input.native="fetchProperties"
                  @change="fetchContracts"
                />
                <v-autocomplete
                  v-model="payment.tenant"
                  v-validate="'required'"
                  pb-3
                  :label="$t('Tenant Name')"
                  item-text="tenant_name"
                  item-value="id"
                  data-vv-name="tenant"
                  clearable
                  :disabled="disableContractSelectors"
                  :items="tenants"
                  :error-messages="errors.collect('tenant')"
                  @input.native="fetchTenants"
                  @change="fetchContracts"
                />
                <v-autocomplete
                  v-model="payment.contract"
                  v-validate="'required'"
                  :label="$t('Contracts')"
                  data-vv-name="contracts"
                  clearable
                  item-text="id"
                  item-value="id"
                  :no-data-text="generateContractsEmptyMessage()"
                  :items="contracts"
                  :disabled="disableContractSelectors"
                  :error-messages="errors.collect('contracts')"
                  @input.native="fetchContracts"
                  @change="setContract()"
                >
                  <template v-slot:selection="data">
                    <span>
                      {{ $t("Contract ID") }}: {{ data.item.id }},
                      {{ $t("from date") }}:
                      {{ formatDate(data.item.contract_start_date) }}
                      {{ $t("to date") }}:
                      {{ formatDate(data.item.contract_end_date) }}
                    </span>
                  </template>

                  <template v-slot:item="data">
                    <span>
                      {{ $t("Contract ID") }}: {{ data.item.id }},
                      {{ $t("from date") }}:
                      {{ formatDate(data.item.contract_start_date) }}
                      {{ $t("to date") }}:
                      {{ formatDate(data.item.contract_end_date) }}
                    </span>
                  </template>
                </v-autocomplete>
                <SelectedUnits
                  :selectedUnits="selecetedUnits"
                  :units="selecetedUnits"
                  disableUnitsSelect
                />
                <v-checkbox
                  v-model="payment.is_manual_payment"
                  :label="$t('Manual Payment')"
                />
                <div v-if="payment.is_manual_payment">
                  <v-select
                    v-model="payment.payment_method"
                    :label="$t('Payment Method')"
                    data-vv-name="payment_method"
                    v-validate="payment.is_manual_payment ? 'required' : ''"
                    :error-messages="errors.collect('payment_method')"
                    item-text="display_name"
                    item-value="value"
                    :items="allowedContractPaymentMethods"
                  />
                  <v-autocomplete
                    v-model="payment.bank"
                    :label="$t('Bank')"
                    data-vv-name="bank"
                    v-validate="
                      `${
                        checkValidationRuleBasedOnSelectedPaymentMethod('bank')
                          ? 'required'
                          : ''
                      }`
                    "
                    :error-messages="errors.collect('bank')"
                    item-text="bank_name"
                    item-value="id"
                    clearable
                    :items="banks"
                    :disabled="
                      !checkValidationRuleBasedOnSelectedPaymentMethod('bank')
                    "
                    @input.native="fetchBanks"
                  />
                  <v-text-field
                    v-model="payment.cheque_number"
                    data-vv-name="cheque_number"
                    v-validate="
                      `${
                        checkValidationRuleBasedOnSelectedPaymentMethod(
                          'cheque_number'
                        )
                          ? 'required|'
                          : ''
                      }numeric`
                    "
                    :error-messages="errors.collect('cheque_number')"
                    :label="$t('Cheque Number')"
                    :disabled="
                      !checkValidationRuleBasedOnSelectedPaymentMethod(
                        'cheque_number'
                      )
                    "
                  />
                  <date-picker
                    v-model="payment.cheque_date"
                    :default_date="payment.cheque_date"
                    :required="
                      checkValidationRuleBasedOnSelectedPaymentMethod(
                        'cheque_date'
                      )
                    "
                    data-vv-name="cheque_date"
                    v-validate="
                      `${
                        checkValidationRuleBasedOnSelectedPaymentMethod(
                          'cheque_date'
                        )
                          ? 'required'
                          : ''
                      }`
                    "
                    :disabled="
                      !checkValidationRuleBasedOnSelectedPaymentMethod(
                        'cheque_date'
                      )
                    "
                    :label="$t('Cheque Date')"
                    :error-messages="errors.collect('cheque_date')"
                  />
                  <v-autocomplete
                    v-model="payment.cash_treasury"
                    :label="$t('Cash Treasury')"
                    v-validate="
                      `${
                        checkValidationRuleBasedOnSelectedPaymentMethod(
                          'cash_treasury'
                        )
                          ? 'required'
                          : ''
                      }`
                    "
                    data-vv-name="cash_treasury"
                    item-text="cash_treasury_name"
                    item-value="id"
                    clearable
                    :items="cashTreasuries"
                    :disabled="
                      !checkValidationRuleBasedOnSelectedPaymentMethod(
                        'cash_treasury'
                      )
                    "
                    :error-messages="errors.collect('cash_treasury')"
                    @input.native="fetchCashTreasuries"
                  />
                  <v-text-field
                    v-model="payment.reference_number"
                    data-vv-name="reference_number"
                    v-validate="
                      `${
                        checkValidationRuleBasedOnSelectedPaymentMethod(
                          'reference_number'
                        )
                          ? 'required|'
                          : ''
                      }numeric`
                    "
                    :error-messages="errors.collect('reference_number')"
                    :label="$t('Reference Number')"
                    :disabled="
                      !checkValidationRuleBasedOnSelectedPaymentMethod(
                        'reference_number'
                      )
                    "
                  />
                  <v-text-field
                    v-model="payment.receipt_voucher_reference"
                    data-vv-name="receipt_voucher_reference"
                    v-validate="'required|numeric'"
                    :error-messages="
                      errors.collect('receipt_voucher_reference')
                    "
                    :label="$t('Receipt Voucher Number')"
                  />
                </div>
                <v-layout wrap class="re-radio">
                  <v-flex v-for="(month, index) of months" :key="index" xs6 sm4>
                    <v-checkbox
                      v-model="month.isSelected"
                      required
                      type="checkbox"
                      color="grey darken-1"
                      :label="formatInvoiceLabel(month)"
                      :checked="month.isSelected"
                      @change="checkPrevious(index, month.isSelected)"
                    />
                    <span
                      v-if="!isSingleSelected && showError && months.length"
                      class="red-text"
                      >{{ $t("Please select at lease one month") }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-form>
              <div class="text-xs-center re-total">
                <strong>{{ $t("Total Net Amount") }}:</strong>
                {{ totalAmount }}
              </div>
            </v-card-text>
          </v-card>
          <vue-skeleton-loader
            rounded
            class="w-full"
            type="rect"
            v-if="isLoading"
            :height="'600px'"
            :width="'100%'"
            animation="fade"
          />
        </v-flex>
      </v-layout>
    </v-layout>
    <div class="text-xs-center mt-4">
      <v-btn
        v-if="!isLoading"
        class="re-orangtbtn"
        :disabled="showError"
        @click="storeData"
        >{{ $t("Save") }}</v-btn
      >
      <v-btn v-if="!isLoading" dark @click="globalGoBack">{{
        $t("Cancel")
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

import SelectedUnits from "../components/Contract/SelectedUnits.vue";
import DatePicker from "../components/picker/DatePicker.vue";
import { ContractEnums } from "../enums";
import {
  reshapSelectedMonths,
  transformforPropertyUnits,
  transformContractUnit,
  defaultFormat,
  reformatAmountWithDecimals,
  reformatInvoicesListWithDecimals
} from "../helper";
import { getMonthLabels } from "../lib/date_time";
import bankService from "../services/plugins/bank";
import cashTreasuryService from "../services/plugins/cash_treasury";
import contractService from "../services/plugins/contract";
import paymentService from "../services/plugins/payment";
import propertiesService from "../services/plugins/properties";
import tenantServices from "../services/plugins/tenant";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";
import paginationMixin from "@/mixins/paginationMixin";

export default {
  name: "AdvancePayment",
  $_veeValidate: {
    validator: "new"
  },
  mixins: [breadItemsSetLang, paginationMixin],
  components: { DatePicker, SelectedUnits },
  data() {
    return {
      payment: {},
      showError: false,
      isLoading: false,
      disableContractSelectors: false,
      properties: [],
      units: [],
      tenants: [],
      contracts: [],
      months: [],
      monthNames: getMonthLabels(),
      selecetedUnits: [],
      fetchProperties: null,
      fetchTenants: null,
      fetchCashTreasuries: null,
      cashTreasuries: [],
      banks: [],
      paymentMethods: [],
      contractPaymentMethods: []
    };
  },
  async created() {
    this.isLoading = true;
    this.$validator.localize("en", this.dictionary);
    const params = this.$route.params;
    this.fetchProperties = debounce(this.loadProperties, 500);
    this.fetchTenants = debounce(this.loadTenants, 500);
    this.fetchBanks = debounce(this.loadBanks, 500);
    this.fetchCashTreasuries = debounce(this.loadCashTreasuries, 500);
    this.fetchContracts = debounce(this.loadContracts, 500);

    if (params.contract_id) {
      this.disableContractSelectors = true;
      await this.fetchContractById(params.contract_id);
      await this.fetchAdvPayment();
    } else {
      this.loadTenants();
      await this.fetchProperties();
    }
    this.fetchBanks();
    this.fetchCashTreasuries();
    await this.fetchPaymentOptions();
    this.isLoading = false;
  },
  watch: {
    "$i18n.locale": function () {
      this.$validator.localize(this.$i18n.locale, this.dictionary);
    },
    "payment.is_manual_payment": function (is_manual) {
      if (!is_manual) {
        this.payment.payment_method = undefined;
      }
    },
    "payment.payment_method": async function () {
      this.payment.bank = undefined;
      this.payment.cheque_number = undefined;
      this.payment.cheque_date = undefined;
      this.payment.cash_treasury = undefined;
      this.payment.reference_number = undefined;
      this.payment.receipt_voucher_reference = undefined;
      await this.$nextTick();
    }
  },

  computed: {
    dictionary() {
      return {
        custom: {
          tenant: {
            required: () => this.$t("Tenant Name is required")
          },
          property: {
            required: () => this.$t("Property Name is required")
          },
          unit: {
            required: () => this.$t("Unit is required")
          },
          contracts: {
            required: () => this.$t("This field is required")
          },
          payment_method: {
            required: () => this.$t("Payment method is required")
          },
          cash_treasury: {
            required: () => this.$t("Cash treasury is required")
          },
          reference_number: {
            required: () => this.$t("This field is required")
          },
          bank: {
            required: () => this.$t("Bank is required")
          },
          cheque_number: {
            required: () => this.$t("Cheque number is required")
          },
          cheque_date: {
            required: () => this.$t("Cheque date is required")
          },
          receipt_voucher_reference: {
            required: () => this.$t("This field is required")
          }
        }
      };
    },
    isSingleSelected() {
      return this.selectedMonths.length > 0;
    },
    selectedMonths() {
      return this.months.filter(month => month.isSelected);
    },
    totalAmount() {
      const selectedInvoicesTotalAmount = this.selectedMonths.reduce(
        (prevValue, month) =>
          parseFloat(prevValue) + parseFloat(month.net_amount),
        0
      );

      return reformatAmountWithDecimals(selectedInvoicesTotalAmount);
    },
    allowedContractPaymentMethods() {
      let fullList = [];
      if (this.paymentMethods.length && this.contractPaymentMethods.length) {
        fullList = this.paymentMethods.filter(el =>
          this.contractPaymentMethods.includes(el.value)
        );
      }
      return fullList;
    }
  },
  methods: {
    formatDate(value, addTime) {
      return defaultFormat(value, addTime);
    },
    async updateTenant(data) {
      if (data) {
        const unit = this.units.find(
          unit => (unit.unit_id || unit.id) === data
        );

        if (unit && unit.contract_id && unit.tenant_id) {
          this.tenants = [
            { tenant_name: unit.tenant_name, id: unit.tenant_id }
          ];

          this.payment = {
            ...this.payment,
            contract: unit.contract_id
          };
        } else if (unit && !unit.tenant_id) {
          this.tenants = [];
          this.$root.$emit("snackbar", {
            text: this.$t("No tenant found against this unit"),
            color: "error"
          });
        } else {
          this.tenants = [];
          this.months = [];
          this.$root.$emit("snackbar", {
            text: this.$t("No contract found"),
            color: "error"
          });
        }
      } else {
        await this.$nextTick();
        this.payment = {
          ...this.payment,
          tenant: ""
        };
      }
    },
    async checkPrevious(selectedIndex, isChecked) {
      let flag = false;
      if (selectedIndex !== 0 && isChecked) {
        const previous = this.months[selectedIndex - 1];
        if (!previous.isSelected) {
          flag = true;
          this.$root.$emit("snackbar", {
            text: this.$t(
              "Kindly, select month checklist in sequence and block"
            ),
            color: "red"
          });
          await this.$nextTick();
          this.months = this.months.map((month, index) => {
            if (selectedIndex === index) {
              return {
                ...month,
                isSelected: false
              };
            }
            return month;
          });
        }
      } else if (!isChecked) {
        /* In case the selected payment date was not checked, 
      // then all next payment dates should be not checked 
      */
        this.months = this.months.map((month, index) => {
          if (selectedIndex <= index) {
            return {
              ...month,
              isSelected: false
            };
          }
          return month;
        });
      }
      await this.$nextTick();
      if (!flag) {
        this.showError = false;
      } else {
        this.showError = true;
      }
    },

    checkValidationRuleBasedOnSelectedPaymentMethod(fieldName) {
      let payMethod = this.payment.payment_method;
      switch (fieldName) {
        case "bank":
          if (["cheque", "bank transfer"].includes(payMethod)) return true;
          else return false;
        case "cheque_number":
        case "cheque_date":
          if (["cheque"].includes(payMethod)) return true;
          else return false;
        case "cash_treasury":
          if (["cash"].includes(payMethod)) return true;
          else return false;
        case "reference_number":
          if (
            [
              "bank transfer",
              "payment link",
              "pos (debit/ credit)",
              "cheque",
              "cash"
            ].includes(payMethod)
          )
            return true;
          else return false;

        default:
          return false;
      }
    },

    async fetchAdvPayment() {
      try {
        this.isLoading = true;
        this.months = [];
        let resp = await this.$processReq(
          paymentService.fetchAdvPayment({
            contract_id: this.payment.contract
          }),
          false,
          false
        );
        resp = reformatInvoicesListWithDecimals(resp.data.body);
        if (!resp.length) {
          this.isLoading = false;
          this.showError = true;
          this.$root.$emit("snackbar", {
            text: this.$t("No advance payment found"),
            color: "error"
          });
        }
        this.months = resp;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        if (
          error.response.data?.errors &&
          Array.isArray(error.response.data.errors)
        ) {
          this.showError = true;
          this.$root.$emit("snackbar", {
            text: this.$t(error.response.data.errors[0].field_error),
            color: "error"
          });
        }
        this.months = [];
        console.log("Error: ", error);
      }
    },
    async fetchPaymentOptions() {
      try {
        const resp = await this.$processReq(
          paymentService.getPaymentOptions(),
          false
        );
        this.paymentMethods =
          resp.data?.body?.actions?.POST?.payment_method?.choices.filter(
            option => option.value != "not determined"
          );
      } catch (err) {
        console.log("Error: Unable to fetch payment options - ", err);
      }
    },
    async storeData() {
      if (!this.isSingleSelected) {
        this.showError = true;
      } else {
        this.showError = false;
      }

      await this.$nextTick();

      if ((await this.$validator.validateAll()) && !this.showError) {
        try {
          this.isLoading = true;
          const {
            contract,
            is_manual_payment,
            receipt_voucher_reference,
            reference_number,
            cash_treasury,
            bank,
            cheque_number,
            cheque_date,
            payment_method
          } = this.payment;
          let data = {
            contract,
            is_manual_payment: Number(is_manual_payment) || 0,
            is_advanced_payment: Number(true),
            receipt_voucher_reference,
            reference_number,
            cash_treasury,
            bank,
            cheque_number,
            cheque_date,
            payment_method,
            reason: ContractEnums.AdvancePayment,
            adv_payment_duration: reshapSelectedMonths(this.selectedMonths)
          };

          await this.$processReq(paymentService.addNewPayment(data), true);
          this.globalGoBack();
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          console.log("Error: Unable to add new payment - ", error);
        }
      }
    },
    async fetchContractById(contract_id) {
      try {
        const res = await this.$processReq(
          contractService.fetchContractById(contract_id),
          false
        );

        const result = res.data.body;

        this.properties = [result.property];
        this.tenants = [result.tenant];
        this.contracts = [result];

        this.payment.contract = result.id;
        this.payment.property = result.property.id;
        this.payment.tenant = result.tenant.id;
        this.contractPaymentMethods = result.allowed_pyament_methodes;
        this.selecetedUnits = transformContractUnit(result.contractunit_set);
      } catch (err) {
        console.log("Error: Unable to fetch contract by ID - ", err);
      }
    },
    async updateUnits(data) {
      if (data) {
        this.units = [];
        const property = this.properties.find(property => property.id === data);

        const { units } = transformforPropertyUnits(property.unit_details);
        this.units = units;
        this.payment = {
          ...this.payment,
          tenant: ""
        };
      } else {
        this.units = [];
        this.payment = {};
        this.months = [];
      }
    },
    async loadContracts(event) {
      this.isLoadingContracts = true;
      if (this.payment.property && this.payment.tenant) {
        try {
          this.selectedInvoice = null;
          this.selectedUnits = [
            {
              unit_id: "--",
              unit_number: "--",
              unit_type_id: "--"
            }
          ];
          this.payment.contract = null;
          this.contracts = [];
          const params = {
            property: this.payment.property,
            tenant: this.payment.tenant,
            id: event?.target?.value
          };
          const res = await this.$processReq(
            contractService.fetchContractListMiniDetails(params),
            false
          );

          this.contracts = res.data?.body?.results;
        } catch (err) {
          console.log("Error: Unable to fetch contracts - ", err);
        }
      }
      this.isLoadingContracts = false;
    },
    async loadProperties(event) {
      try {
        if (event) {
          this.searchFilter.search = event.target.value;
        }
        const params = this.setPaginationParams();

        const response = await this.$processReq(
          propertiesService.getPropertiesList(params),
          false
        );

        this.properties = response.data.body.results;
      } catch (err) {
        console.log("Error: Unable to get properties list - ", err);
      }
    },
    async loadTenants(event) {
      try {
        if (event) {
          this.searchFilter.search = event.target.value;
        }
        const params = this.setPaginationParams();

        const resp = await this.$processReq(
          tenantServices.getTenantsList(params),
          false
        );

        this.tenants = resp.data.body.results;
      } catch (error) {
        console.log("Error: Unable to get tenant list - ", error);
      }
    },
    async loadBanks(event) {
      try {
        if (event) {
          this.searchFilter.search = event.target.value;
        }
        const params = this.setPaginationParams();

        const resp = await this.$processReq(
          bankService.getBankList(params),
          false
        );

        this.banks = resp.data.body.results;
      } catch (error) {
        console.log("Error: Unable to get bank list - ", error);
      }
    },
    async loadCashTreasuries(event) {
      try {
        if (event) {
          this.searchFilter.search = event.target.value;
        }
        const params = this.setPaginationParams();

        const resp = await this.$processReq(
          cashTreasuryService.getCashTreasuryList(params),
          false
        );

        this.cashTreasuries = resp.data.body.results;
      } catch (error) {
        console.log("Error: Unable to get tresury list - ", error);
      }
    },
    generateContractsEmptyMessage() {
      if (this.payment.property && this.payment.tenant) {
        if (this.isLoadingContracts) {
          return this.$t("Loading...");
        }
        return this.$t("No data available");
      } else {
        return this.$t("Please select a property and tenant to load contracts");
      }
    },
    setContract() {
      const contract = this.contracts.find(
        contract => contract.id === this.payment.contract
      );
      this.contractPaymentMethods = [...contract.allowed_pyament_methodes];
      this.units = transformContractUnit(
        contract?.contractunit_set?.length
          ? contract.contractunit_set
          : [contract.contractunit_set]
      );
      this.fetchAdvPayment();
    },
    formatInvoiceLabel(invoice) {
      return `(${this.formatDate(invoice.payment_due_date)}, Net Amount: ${
        invoice.net_amount
      })`;
    }
  }
};
</script>
