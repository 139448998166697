<template>
  <div class="edit-ticket">
    <v-layout class="pt-5">
      <v-flex grow pa-1 xs6>
        <h2 class="display-2 pl-4">{{ $t("Ticket Details") }}</h2>
      </v-flex>
      <v-flex shrink pa-1 xs6 text-xs-right>
        <v-btn
          dark
          class="mt-4 r-m-end"
          :disabled="isLoading"
          @click="globalGoBack"
          >← {{ $t("Back") }}</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex class="flex-wrap" xs12 pa-1>
        <v-breadcrumbs :items="breadItemsSetLang()" class="pl-4">
          <template v-slot:divider>
            <v-icon>fiber_manual_record</v-icon>
          </template>
        </v-breadcrumbs>
      </v-flex>
    </v-layout>
    <v-form>
      <v-layout row wrap justify-center>
        <v-layout row wrap justify-center>
          <v-flex xs12 sm12 lg9>
            <vue-skeleton-loader
              rounded
              class="w-full"
              type="rect"
              v-if="isLoading"
              :height="'358px'"
              :width="'100%'"
              animation="fade"
            />
            <v-card v-if="!isLoading">
              <v-card-title>
                <h3>
                  {{ $t("Tenant Side") }}
                </h3>
              </v-card-title>
              <v-card-text>
                <v-autocomplete
                  v-model="ticket.property"
                  v-validate="'required'"
                  :label="$t('Property Name')"
                  data-vv-name="property"
                  item-text="property_name"
                  item-value="id"
                  clearable
                  :disabled="isEdit"
                  :error-messages="errors.collect('property')"
                  :items="properties"
                  :no-data-text="`${
                    isLoadingPropertyOptions
                      ? $t('Loading...')
                      : $t('No data available')
                  }`"
                  @input.native="fetchProperties"
                />
                <v-select
                  v-model="ticket.units"
                  :items="propertyUnitsList"
                  multiple
                  v-validate="'required'"
                  item-text="unit_number"
                  item-value="id"
                  :label="$t('Unit')"
                  data-vv-name="units"
                  :error-messages="errors.collect('units')"
                  class="mb-1"
                />
                <v-item-group
                  multiple
                  v-model="ticket.fix_types"
                  v-validate="'required'"
                  data-vv-name="fix_types"
                >
                  <v-subheader>{{ $t("Maintenance Types") }} :</v-subheader>
                  <v-item
                    active-class="re-orangtbtn"
                    v-for="option in issues"
                    :key="option.id"
                    :value="option.id"
                  >
                    <v-chip
                      label
                      slot-scope="{ active, toggle }"
                      @click="toggle"
                    >
                      {{ option.name }}
                    </v-chip>
                  </v-item>
                </v-item-group>
                <div v-if="!ticket.fix_types.length">
                  <span class="red-text">{{ errors.first("fix_types") }}</span>
                </div>
                <v-textarea
                  v-model="ticket.note"
                  v-validate="'required'"
                  data-vv-name="note"
                  :label="$t('Notes')"
                  :disabled="isEdit"
                  :error-messages="errors.collect('note')"
                  class="mb-2 pb-2"
                />
                <vue-phone-number-input
                  v-model="ticket.creator_phone_number"
                  :translations="translations"
                  :only-countries="phoneInputPreferredCountries"
                  :default-country-code="
                    ticket.phone_details.creator_country_code
                      ? ticket.phone_details.creator_country_code.replace(
                          /[0-9]/g,
                          ''
                        )
                      : defaultCountryCode
                  "
                  required
                  :disabled="isEdit"
                  v-validate="'required'"
                  data-vv-name="creator_phone_number"
                  :error="!isValidPhone"
                  @update="updateCountryCode"
                  @phone-number-focused="isTouched = true"
                />
                <span
                  v-if="
                    errors.collect('creator_phone_number').length ||
                    !isValidPhone
                  "
                  class="red-text"
                  style="display: flex"
                  >{{ $t("Phone number is required") }}</span
                >
              </v-card-text>
            </v-card>
            <v-card v-if="!isLoading" class="mt-3">
              <v-card-title>
                <h3>
                  {{ $t("Landlord Side") }}
                </h3>
              </v-card-title>
              <v-card-text>
                <v-select
                  v-model="ticket.maintenance_ticket_status"
                  :items="statusList"
                  v-validate="'required'"
                  item-text="display_name"
                  item-value="id"
                  :label="$t('Status')"
                  data-vv-name="maintenance_ticket_status"
                  :error-messages="errors.collect('maintenance_ticket_status')"
                  class="mb-1"
                />
                <v-textarea
                  v-model="ticket.message"
                  :label="$t('Message')"
                  class="mb-2 pb-2"
                />
              </v-card-text>
            </v-card>
            <v-card v-if="!isLoading" class="mt-3">
              <v-card-title>
                <h3>
                  {{ $t("Messages") }}
                </h3>
              </v-card-title>
              <v-card-text>
                <v-layout style="overflow: auto">
                  <v-data-table
                    hide-actions
                    class="width100"
                    :headers="headers"
                    :items="messages"
                    :pagination.sync="sortingParams"
                    :options.sync="pagination"
                    :server-items-length="pagination.totalItems"
                    :no-data-text="$t('No data available')"
                  >
                    <template v-slot:items="props">
                      <tr>
                        <td class="text-xs-center">
                          <div>{{ props.item.text }}</div>
                        </td>
                        <td class="text-xs-center">
                          <div>
                            {{ defaultFormatted(props.item.created, true) }}
                          </div>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-layout>
              </v-card-text>
            </v-card>
            <div
              class="overflow-hidden text-xs-center pt-4"
              v-if="!isLoading || !isLoadingMessages"
            >
              <v-pagination
                v-model="pagination.page"
                :length="pages"
                :total-visible="10"
                class="re-pagination"
                @input="fetchTicketMessages($route.params.id)"
              ></v-pagination>
            </div>
          </v-flex>
        </v-layout>
      </v-layout>
      <div class="text-xs-center mt-4">
        <v-btn
          dark
          class="re-orangtbtn"
          :disabled="isLoading"
          @click="saveTicket"
          >{{ $t("Save") }}</v-btn
        >
        <v-btn dark @click="globalGoBack">{{ $t("Cancel") }}</v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";
import { defaultFormat } from "@/helper";
import paginationMixin from "@/mixins/paginationMixin";
import breadItemsSetLang from "@/mixins/breadItemsSetLang";
import propertyService from "@/services/plugins/properties";
import propertyIssuesService from "@/services/plugins/property_issue";
import propertyMaintenanceTicketsService from "@/services/plugins/property_maintenance";

export default {
  name: "add_property_maintenance_ticket",
  $_veeValidate: {
    validator: "new"
  },
  mixins: [breadItemsSetLang, paginationMixin],
  async created() {
    this.$validator.localize("en", this.dictionary);
    this.fetchProperties = debounce(this.loadProperties, 500);
    await this.initializTicketDetails();
  },
  data() {
    return {
      isLoading: false,
      isLoadingPropertyOptions: false,
      isLoadingUnitsOptions: false,
      isLoadingIssuesOptions: false,
      isLoadingStatusOptions: false,
      isLoadingMessages: false,
      ticket: {
        phone_details: {},
        fix_types: []
      },
      fetchProperties: null,
      isEdit: false,
      isValidPhone: true,
      breadCrumbs: [],
      properties: [],
      propertyUnitsList: [],
      issues: [],
      statusList: [],
      messages: [],
      isTouched: false,
      row: ""
    };
  },
  watch: {
    "$i18n.locale": async function () {
      this.$validator.localize(this.$i18n.locale, this.dictionary);
      await this.initializTicketDetails();
    },
    "ticket.property": function (value) {
      if (!this.$route.params.id) {
        this.ticket.units = [];
        this.propertyUnitsList = [];
        if (value) {
          this.fetchPropertyUnits(value);
        }
      }
    }
  },
  computed: {
    dictionary() {
      return {
        custom: {
          property: {
            required: () => this.$t("Property is required")
          },
          units: {
            required: () => this.$t("Unit is required")
          },
          fix_types: {
            required: () =>
              this.$t("Please select at least one maintenance type")
          },
          creator_phone_number: {
            required: () => this.$t("Phone is required")
          },
          maintenance_ticket_status: {
            required: () => this.$t("Status is required")
          },
          note: {
            required: () => this.$t("This field is required")
          }
        }
      };
    },
    translations() {
      return {
        countrySelectorLabel: this.$t("Country code"),
        countrySelectorError: this.$t("Country Code Not Found"),
        phoneNumberLabel: this.$t("Phone number"),
        example: "Example :"
      };
    },
    headers() {
      return [
        {
          text: this.$t("Message"),
          value: "text",
          sortable: false,
          align: "center"
        },

        {
          text: this.$t("Date"),
          value: "created",
          sortable: false,
          align: "center"
        }
      ];
    },
    ...mapGetters("config", {
      defaultCountryCode: "getDefaultPhoneCountryCode",
      phoneInputPreferredCountries: "getPhoneInputPreferredCountries"
    })
  },
  methods: {
    async loadProperties(event) {
      try {
        this.isLoadingPropertyOptions = true;

        let params = {
          limit: 10,
          offset: 0
        };

        if (event) {
          params = {
            ...params,
            search: event.target.value || ""
          };
        }
        const resp = await this.$processReq(
          propertyService.getPropertiesList(params),
          false
        );

        this.properties = resp.data.body.results;
        this.isLoadingPropertyOptions = false;
      } catch (err) {
        this.isLoadingPropertyOptions = false;
        console.log("Error: Unable to get properties list - ", err);
      }
    },
    async fetchPropertyUnits(propertyId) {
      try {
        this.propertyUnitsList = [];
        this.isLoadingUnitsOptions = true;
        const resp = await this.$processReq(
          propertyService.getPopertyUnitsList(propertyId),
          false
        );
        this.isLoadingUnitsOptions = false;
        this.propertyUnitsList = resp.data.body;
      } catch (err) {
        this.isLoadingUnitsOptions = false;
        console.log("Error: Unable to get property Units list - ", err);
      }
    },
    async loadStatusList() {
      try {
        this.isLoadingStatusOptions = true;

        const resp = await this.$processReq(
          propertyMaintenanceTicketsService.getMaintenanceTicketStatusList(),
          false
        );

        this.statusList = resp.data.body.results;
        this.isLoadingStatusOptions = false;
      } catch (err) {
        this.isLoadingStatusOptions = false;
        console.log("Error: Unable to get Status list - ", err);
      }
    },
    async loadIssues() {
      try {
        this.isLoadingIssuesOptions = true;

        let params = {
          limit: 100, // 100 value of limit to get all issues list
          offset: 0
        };

        const resp = await this.$processReq(
          propertyIssuesService.getPropertyIssuesList(params),
          false
        );

        this.issues = resp.data.body.results;
        this.isLoadingIssuesOptions = false;
      } catch (err) {
        this.isLoadingIssuesOptions = false;
        console.log("Error: Unable to get issues list - ", err);
      }
    },
    async fetchTicketMessages(id) {
      try {
        this.isLoadingMessages = true;

        let params = {
          ...this.setPaginationParams(),
          maintenance_ticket: id
        };

        const resp = await this.$processReq(
          propertyMaintenanceTicketsService.getMaintenanceTicketMessagesList(
            params
          ),
          false
        );

        this.messages = resp.data.body.results;
        this.isLoadingMessages = false;
        this.setPagination(resp.data.body.count);

        this.showAllPagination();
      } catch (err) {
        this.isLoadingMessages = false;
        console.log("Error: Unable to get messages list - ", err);
      }
    },
    updateCountryCode(event) {
      const phone_details = this.ticket.phone_details;

      phone_details.creator_country_code = event.countryCode;
      phone_details.creator_phone_number = event.formattedNumber;

      this.ticket = {
        ...this.ticket,
        phone_details
      };

      this.isValidPhone = event.isValid;
    },
    async fetchPropertyById(id) {
      try {
        this.isLoadingPropertyOptions = true;
        const res = await this.$processReq(
          propertyService.getPropertyDetails(id),
          false
        );

        this.properties = [res.data.body];
        this.isLoadingPropertyOptions = false;
      } catch (err) {
        this.isLoadingPropertyOptions = false;
        console.log("Error: Unable to fetch property by Id - ", err);
      }
    },
    async fetchTicketDetails(id) {
      try {
        const resp = await this.$processReq(
          propertyMaintenanceTicketsService.getPropertyMaintenanceTicketById(
            id
          ),
          false
        );
        return resp.data.body;
      } catch (err) {
        console.log("Error: Unable to get ticket details - ", err);
        return null;
      }
    },
    async initializTicketDetails() {
      let isEdit = false;
      const params = this.$route.params;
      try {
        this.isLoading = true;
        await this.loadStatusList();
        await this.loadIssues();
        if (params.id) {
          await this.setTicketDetails();
          isEdit = true;
        } else {
          await this.fetchProperties();
        }
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log(
          "Error: Unable to fetch data from initializTicketDetails func- ",
          err
        );
      }
      this.isEdit = isEdit;
    },
    async setTicketDetails() {
      const params = this.$route.params;
      try {
        await this.fetchTicketMessages(params.id);
        const ticketDetails = await this.fetchTicketDetails(params.id);
        await this.fetchPropertyById(ticketDetails.property.id);
        const property = this.properties.find(
          el => el.id === ticketDetails.property.id
        );
        this.propertyUnitsList = [...property.unit_details];
        this.ticket = {
          ...ticketDetails,
          fix_types: ticketDetails.fix_types.map(el => el.id),
          maintenance_ticket_status: ticketDetails.maintenance_ticket_status.id,
          property: ticketDetails.property.id,
          units: ticketDetails.units.map(el => el.id),
          phone_details: {
            creator_country_code: ticketDetails.creator_country_code,
            creator_phone_number: ticketDetails.creator_phone_number
          }
        };
      } catch (err) {
        console.log(
          "Error: Unable to fetch property details or set ticket details - ",
          err
        );
      }
    },
    async saveMessage(ticketId) {
      let data = {
        text: this.ticket.message,
        creator_phone_number: this.ticket.phone_details.creator_phone_number,
        maintenance_ticket: ticketId
      };
      try {
        await this.$processReq(
          propertyMaintenanceTicketsService.addNewMaintenanceTicketMessage(
            data
          ),
          false
        );
      } catch (err) {
        console.log("Error: Unable to add new ticket message - ", err);
      }
    },
    async saveTicket() {
      if ((await this.$validator.validateAll()) && this.isValidPhone) {
        let data = {
          ...this.ticket,
          ...this.ticket.phone_details
        };
        delete data.phone_details;

        this.isLoading = true;
        let ticketId;
        try {
          if (this.isEdit) {
            await this.$processReq(
              propertyMaintenanceTicketsService.updatePropertyMaintenanceTicket(
                data,
                data.id
              ),
              true
            );
            ticketId = data.id;
          } else {
            const response = await this.$processReq(
              propertyMaintenanceTicketsService.addNewPropertyMaintenanceTicket(
                data
              ),
              true
            );
            ticketId = response.data.body.id;
          }
          if (this.ticket.message) {
            await this.saveMessage(ticketId);
          }
          this.isLoading = false;
          this.$router.push({ name: "property_maintenance_tickets" });
        } catch (err) {
          this.isLoading = false;
          console.log(
            "Error: Unable to update or add new maintenance ticket - ",
            err
          );
        }
      }
    },
    defaultFormatted(date, containsTime) {
      return defaultFormat(date, containsTime);
    }
  }
};
</script>
<style scoped>
.v-chip.re-orangtbtn {
  color: #fff !important;
}
.v-subheader {
  padding: 0px !important;
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
}
.v-item-group > * {
  cursor: unset !important;
}
</style>
