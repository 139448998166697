<template>
  <div>
    <form data-vv-scope="invoices_scope">
      <v-data-table
        :headers="headers"
        :items="selectedInvoices"
        :expand="expand"
        item-key="payment_due_date"
        hide-actions
        :no-data-text="$t('No data available')"
      >
        <template v-slot:headers="props">
          <tr>
            <th class="color-column-70"></th>
            <th class="d-flex select-all">
              <v-checkbox
                v-model="selectAll"
                primary
                hide-details
                @click.stop="toggleAll"
              />
              <div class="is-paid-text">
                {{ $t("Is Paid") }}
              </div>
            </th>
            <th v-for="header in props.headers" :key="header.text">
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:items="props">
          <tr>
            <td class="color-column-70" dense>
              <ColorShow v-if="isShowColors" :item="props.item" />
            </td>
            <td>
              <v-checkbox
                v-model="props.item.is_paid"
                class="justify-start"
                :disabled="
                  props.item.is_grace_period ||
                  (props.item.is_payment_made_in_sequence &&
                    !checkPreviousIndex(props.index - 1) &&
                    !props.item.is_paid) ||
                  props.item.is_generated ||
                  props.item.is_blocked ||
                  !(props.item.is_open_balance || props.item.is_missed)
                "
                @change="toggleSelectbutton(props.index)"
              />
            </td>
            <td class="re-width-70 text-xs-center div">
              {{ formatDate(props.item.payment_due_date) }}
            </td>
            <td class="text-xs-center">
              {{
                defaultReformattedAmount(
                  props.item[isAction ? "amount" : "original_amount"]
                )
              }}
            </td>
            <td class="text-xs-center">
              {{
                defaultReformattedAmount(props.item.paid_amount_before_discount)
              }}
            </td>
            <td class="text-xs-center">
              {{ defaultReformattedAmount(props.item.discount) }}
            </td>
            <td class="text-xs-center">
              {{ defaultReformattedAmount(props.item.paid_amount) }}
            </td>
            <td class="re-width-115 justify-center div">
              <v-text-field
                v-model="
                  props.item[`${isAction ? 'actual_paid_amount' : 'amount'}`]
                "
                required
                v-validate="
                  `required|max_value: ${
                    isAction ? props.item.amount : props.item.original_amount
                  }|decimal:${getDecimalCount}`
                "
                :name="`actual_paid_amount_${props.index}`"
                :data-vv-name="`actual_paid_amount_${props.index}`"
                :disabled="
                  props.item.is_grace_period ||
                  props.item.is_generated ||
                  !(props.item.is_open_balance || props.item.is_missed)
                "
                @input="updateInvoiceVatAndNetAmounts(props.item)"
              />
              <span class="red-text">{{
                errors.collect(
                  `invoices_scope.actual_paid_amount_${props.index}`
                ).length
                  ? errors.first(
                      `invoices_scope.actual_paid_amount_${props.index}`
                    )
                  : ""
              }}</span>
            </td>
            <td v-if="getIsCountrySupportsVat" class="text-xs-center">
              {{ defaultReformattedAmount(props.item.vat_amount) }}
            </td>
            <td class="text-xs-center">
              {{ defaultReformattedAmount(props.item.net_amount) }}
            </td>
            <td
              class="re-width-145 div datePickerWidth"
              :class="{ datePickerWidthEn: $i18n.locale === 'en' }"
            >
              <date-picker
                v-model="props.item.payment_date"
                :disabled="
                  props.item.is_grace_period ||
                  props.item.is_generated ||
                  !(props.item.is_open_balance || props.item.is_missed)
                "
                :defaultValue="props.item.payment_date"
              />
            </td>
            <td>
              <v-btn flat icon @click="props.expanded = !props.expanded">
                <v-icon class="rotate-90-back" v-if="props.expanded"
                  >chevron_right</v-icon
                >
                <v-icon class="rotate-90" v-else>chevron_right</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        <template v-slot:expand="props">
          <v-card flat>
            <v-card-text>
              <v-data-table
                :headers="headers2"
                :items="[props.item]"
                :no-data-text="$t('No data available')"
                :item-key="`payment_${props.item.payment_due_date}`"
                hide-actions
              >
                <template v-slot:items="props">
                  <tr>
                    <td>
                      <v-checkbox
                        v-model="props.item.is_suspended"
                        class="justify-center"
                        :disabled="
                          props.item.is_grace_period ||
                          props.item.is_generated ||
                          !(props.item.is_open_balance || props.item.is_missed)
                        "
                      />
                    </td>
                    <td>
                      <v-checkbox
                        v-model="props.item.is_manual_payment"
                        class="justify-center"
                        :disabled="
                          props.item.is_grace_period ||
                          props.item.is_generated ||
                          !(props.item.is_open_balance || props.item.is_missed)
                        "
                      />
                    </td>
                    <td>
                      <v-select
                        v-model="props.item.payment_method"
                        item-text="display_name"
                        item-value="value"
                        :items="paymentMethods"
                        :disabled="
                          props.item.is_grace_period ||
                          props.item.is_generated ||
                          !(props.item.is_open_balance || props.item.is_missed)
                        "
                      />
                    </td>
                    <td class="re-width-115 div">
                      <v-text-field
                        v-model="props.item.receipt_voucher_reference"
                        :disabled="
                          props.item.is_grace_period ||
                          props.item.is_generated ||
                          !(props.item.is_open_balance || props.item.is_missed)
                        "
                      />
                    </td>
                    <td>
                      <v-autocomplete
                        v-model="props.item.cash_treasury"
                        item-text="cash_treasury_name"
                        item-value="id"
                        clearable
                        :disabled="
                          props.item.is_grace_period ||
                          props.item.is_generated ||
                          !(props.item.is_open_balance || props.item.is_missed)
                        "
                        :items="cashTreasuries"
                        @input.native="fetchCashTreasuries"
                      />
                    </td>
                    <td>
                      <v-autocomplete
                        v-model="props.item.bank"
                        item-text="bank_name"
                        item-value="id"
                        clearable
                        :disabled="
                          props.item.is_grace_period ||
                          props.item.is_generated ||
                          !(props.item.is_open_balance || props.item.is_missed)
                        "
                        :items="banks"
                        @input.native="fetchBanks"
                      />
                    </td>
                    <td class="re-width-115 div">
                      <v-text-field
                        v-model="props.item.cheque_number"
                        :disabled="
                          props.item.is_grace_period ||
                          props.item.is_generated ||
                          !(props.item.is_open_balance || props.item.is_missed)
                        "
                      />
                    </td>
                    <td
                      class="re-width-145 div datePickerWidth"
                      :class="{ datePickerWidthEn: $i18n.locale === 'en' }"
                    >
                      <date-picker
                        v-model="props.item.cheque_date"
                        :disabled="
                          props.item.is_grace_period ||
                          props.item.is_generated ||
                          !(props.item.is_open_balance || props.item.is_missed)
                        "
                        :defaultValue="props.item.cheque_date"
                      />
                    </td>
                    <td class="re-width-115 div">
                      <v-text-field
                        v-model="props.item.reference_number"
                        :disabled="
                          props.item.is_grace_period ||
                          props.item.is_generated ||
                          !(props.item.is_open_balance || props.item.is_missed)
                        "
                      />
                    </td>
                    <td>
                      <v-checkbox
                        v-model="
                          props.item[
                            `${
                              isAction
                                ? 'is_advanced_payment_after_contracting'
                                : 'is_advanced_payment'
                            }`
                          ]
                        "
                        class="justify-center"
                        :disabled="
                          props.item.is_grace_period ||
                          props.item.is_generated ||
                          !(props.item.is_open_balance || props.item.is_missed)
                        "
                      />
                    </td>
                    <td>
                      <v-checkbox
                        v-model="props.item.is_late_payment"
                        class="justify-center"
                        :disabled="
                          props.item.is_grace_period ||
                          props.item.is_generated ||
                          !(props.item.is_open_balance || props.item.is_missed)
                        "
                      />
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </template>
      </v-data-table>
    </form>
    <color-details />
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import Vue from "vue";
import { mapGetters } from "vuex";

import ColorDetails from "../../components/ColorDetails";
import ColorShow from "../../components/ColorDetails/ColorShow.vue";
import DatePicker from "../../components/picker/DatePicker.vue";
import {
  defaultFormat,
  reformatAmountWithDecimals,
  calculateVatAmount,
  calculateNetAmount
} from "../../helper";
import bankService from "../../services/plugins/bank";
import cashTreasuryService from "../../services/plugins/cash_treasury";
import paymentService from "../../services/plugins/payment";

import colorMixin from "@/mixins/colorMixin";
import date_mixins from "@/mixins/date_mixins";
import paginationMixin from "@/mixins/paginationMixin";

export default {
  name: "invoice-table",
  mixins: [date_mixins, colorMixin, paginationMixin],
  props: {
    selectedInvoices: {
      type: Array,
      default: () => []
    },
    isRenew: Boolean,
    isAction: Boolean,
    isShowColors: {
      type: Boolean,
      default: false
    },
    contractPaymentMethods: {
      type: Array,
      default: () => []
    },
    isSelectedPropertySupportsVat: {
      type: Boolean,
      default: false
    }
  },
  inject: ["parentValidator"],
  components: { DatePicker, ColorDetails, ColorShow },
  data() {
    return {
      selectAll: this.selectedInvoices.every(invoice => invoice.is_paid),
      expand: true,
      fetchBanks: null,
      fetchCashTreasuries: null,
      cashTreasuries: [],
      banks: [],
      paymentMethods: []
    };
  },
  computed: {
    ...mapGetters("config", ["getDecimalCount", "getIsCountrySupportsVat"]),
    headers() {
      return [
        {
          text: this.$t("Due Date"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Original Amount"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Payment Amount"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Discount"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Paid Amount"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Actual Paid Amount"),
          align: "center",
          sortable: false
        },
        this.getIsCountrySupportsVat
          ? {
              text: this.$t("Vat Amount"),
              align: "center",
              sortable: false
            }
          : null,
        {
          text: this.$t("Net Amount"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Payment Date"),
          align: "center",
          sortable: false
        },
        {
          text: "",
          align: "center",
          sortable: false
        }
      ].filter(item => item !== null);
    },
    headers2() {
      return [
        { text: this.$t("Is Suspended"), align: "center", sortable: false },
        { text: this.$t("Is Manual"), align: "center", sortable: false },
        { text: this.$t("Payment Method"), align: "center", sortable: false },
        {
          text: this.$t("Receipt Voucher Reference"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Cash Treasury"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Bank"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Cheque Number"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Cheque Date"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Reference Number"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Is Advance Payment After Contracting"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Is Late Payment"),
          align: "center",
          sortable: false
        }
      ];
    }
  },
  async created() {
    this.$validator = this.parentValidator;
    this.fetchBanks = debounce(this.loadBanks, 500);
    this.fetchCashTreasuries = debounce(this.loadCashTreasuries, 500);
    this.fetchBanks();
    this.fetchCashTreasuries();
    if (!this.isAction) await this.fetchPaymentOptions();
    else this.paymentMethods = this.contractPaymentMethods;
  },
  methods: {
    formatDate(value, addTime) {
      return defaultFormat(value, addTime);
    },
    checkPreviousIndex(index) {
      if (index >= 0) return this.selectedInvoices[index].is_paid;
      return true;
    },
    toggleAll() {
      this.selectAll = !this.selectAll;
      this.selectedInvoices.forEach(invoice => {
        if (
          !invoice.is_grace_period &&
          !invoice.is_blocked &&
          (invoice.is_missed || invoice.is_open_balance || invoice.is_suspended)
        ) {
          invoice.is_paid = this.selectAll;
        }
      });
    },
    toggleSelectbutton(index) {
      if (this.selectedInvoices.some(invoice => !invoice.is_paid)) {
        this.selectAll = false;
      }
      /**
       * Check if the contract made in sequence,
       * If true, then disable all the next payments when the current payment is Not Paid
       */
      if (this.selectedInvoices[index].is_payment_made_in_sequence) {
        this.selectedInvoices.forEach((invoice, idx) => {
          if (
            idx > index &&
            (invoice.is_missed ||
              invoice.is_open_balance ||
              invoice.is_suspended)
          )
            invoice.is_paid = false;
        });
      }
    },
    async loadBanks(event) {
      try {
        if (event) {
          this.searchFilter.search = event.target.value;
        }
        const params = this.setPaginationParams();

        const resp = await this.$processReq(
          bankService.getBankList(params),
          false
        );

        this.banks = resp.data.body.results;
      } catch (error) {
        console.log("Error: Unable to get bank list - ", error);
      }
    },
    async loadCashTreasuries(event) {
      try {
        if (event) {
          this.searchFilter.search = event.target.value;
        }
        const params = this.setPaginationParams();

        const resp = await this.$processReq(
          cashTreasuryService.getCashTreasuryList(params),
          false
        );

        this.cashTreasuries = resp.data.body.results;
      } catch (error) {
        console.log("Error: Unable to get tresury list - ", error);
      }
    },
    async fetchPaymentOptions() {
      try {
        const resp = await this.$processReq(
          paymentService.getPaymentOptions(),
          false
        );
        this.paymentMethods =
          resp.data?.body?.actions?.POST?.payment_method?.choices;
      } catch (err) {
        console.log("Error: Unable to fetch payment options - ", err);
      }
    },
    defaultReformattedAmount(amount) {
      return reformatAmountWithDecimals(amount || 0);
    },
    updateInvoiceVatAndNetAmounts(invoice) {
      const index = this.selectedInvoices.findIndex(
        item => item.payment_due_date === invoice.payment_due_date
      );
      if (index !== -1) {
        const actAmount =
          invoice[`${this.isAction ? "actual_paid_amount" : "amount"}`];
        const newVatAmount = this.isSelectedPropertySupportsVat
          ? calculateVatAmount(actAmount)
          : this.defaultReformattedAmount(0);
        const newNetAmount = calculateNetAmount(actAmount, newVatAmount);
        Vue.set(this.selectedInvoices, index, {
          ...invoice,
          vat_amount: newVatAmount,
          net_amount: newNetAmount
        });
      }
    }
  },
  watch: {
    contractPaymentMethods() {
      this.paymentMethods = this.contractPaymentMethods;
    },
    "$i18n.locale": function () {
      this.fetchPaymentOptions();
    }
  }
};
</script>

<style scoped lang="scss">
.color-column-70 {
  margin: 0 !important;
  padding: 0 !important;
  min-width: 70px !important;
  max-width: 70px !important;
}
.select-all {
  align-items: center;
  justify-content: space-around;
  margin-top: 15px;
  .is-paid-text {
    width: fit-content;
  }
  .v-input {
    display: contents !important;
  }
}
::v-deep .v-datatable .v-input--selection-controls {
  width: auto;
  display: flex;
  justify-content: center;
}
::v-deep .datePickerWidth .v-input__icon {
  justify-content: end;
}
::v-deep .datePickerWidthEn input {
  text-align: left;
}
::v-deep .highlight-text-open-balance input {
  color: white !important;
}
::v-deep .highlight-text-is-missed input {
  color: white !important;
}
::v-deep .highlight-text-is-missed:hover {
  background-color: #302f37 !important;
}

::v-deep .higlight-text-is-grace:hover {
  background-color: #f77d1a !important;
}
::v-deep .higlight-text-is-grace input {
  color: white !important;
}
.rotate-90 {
  transform: rotate(90deg);
}
.rotate-90-back {
  transform: rotate(-90deg);
}
::v-deep .v-autocomplete .v-select__slot input {
  width: auto !important;
}
</style>
