<template>
  <div class="version-text">real_estate v{{ frontEndVersion }}</div>
</template>

<script>
import packageInfo from "../../package";

export default {
  name: "Version",
  data() {
    return {
      frontEndVersion: packageInfo.version
    };
  }
};
</script>

<style scoped>
.version-text {
  position: fixed;
  bottom: 1px;
  right: 4px;
  z-index: 900;
  color: transparent;
  height: 50px;
  width: 50px;
}
</style>
