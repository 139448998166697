import moment from "moment";

export default {
  methods: {
    allowFirstDay(val) {
      return parseInt(val.split("-")[2], 10) === 1;
    },
    allowLastDay(val) {
      return (
        parseInt(
          moment(val)
            .clone()
            .add(1, "day")
            .format("DD")
        ) === 1
      );
    },
    dateExistBetween(val, date) {
      if (!date) {
        return true;
      }

      const startDate = moment(date).subtract(1, "day");
      const endDate = moment(date)
        .clone()
        .add(moment(date).daysInMonth(), "day");

      return moment(val).isBetween(startDate, endDate);
    },
    allowPaymentPeriodDates(val, paymentPeriod, date) {
      if (moment(val).isSameOrBefore(date)) return;
      if (parseInt(moment(date).format("DD")) === 1) {
        if (
          parseInt(
            moment(val)
              .clone()
              .add(1, "day")
              .format("DD")
          ) !== 1
        ) {
          return;
        }
      } else if (moment(date).format("DD") > 27) {
        if (parseInt(moment(val).format("DD")) < 27) {
          return;
        }
      }
      let allowedDate = date;
      let interval = 1;
      if (paymentPeriod?.toLowerCase() === "monthly") {
        while (moment(allowedDate).isBefore(val)) {
          allowedDate = moment(date)
            .clone()
            .add(interval, "month");
          interval++;
        }
      } else if (paymentPeriod?.toLowerCase() === "quarterly") {
        while (moment(allowedDate).isBefore(val)) {
          allowedDate = moment(date)
            .clone()
            .add(interval, "quarter");
          interval++;
        }
      } else if (paymentPeriod?.toLowerCase() === "semi-annually") {
        while (moment(allowedDate).isBefore(val)) {
          allowedDate = moment(date)
            .clone()
            .add(interval * 2, "quarter");
          interval++;
        }
      } else if (paymentPeriod?.toLowerCase() === "annually") {
        while (moment(allowedDate).isBefore(val)) {
          allowedDate = moment(date)
            .clone()
            .add(interval, "year");
          interval++;
        }
      }
      return moment(val)
        .clone()
        .add(1, "day")
        .isSame(allowedDate);
    }
  }
};
