<template>
  <v-layout
    :class="[
      $vuetify.breakpoint.mdAndUp
        ? 'justify-space-around'
        : 'justify-space-between'
    ]"
    wrap
  >
    <div>
      <div class="color-group">
        <div class="default-color color-box"></div>
        <span>{{ $t("Normal") }}</span>
      </div>
      <div class="color-group">
        <div class="bg-open-balance color-box"></div>
        <span>{{ $t("Open Balance") }}</span>
      </div>
      <div class="color-group">
        <div class="bg-is-grace color-box"></div>
        <span>{{ $t("Grace Period") }}</span>
      </div>
    </div>
    <div>
      <div class="color-group">
        <div class="bg-is-missed color-box"></div>
        <span>{{ $t("Missed") }}</span>
      </div>
      <div class="color-group">
        <div class="bg-link-is-not-sent color-box"></div>
        <span>{{ $t("Link is not sent") }}</span>
      </div>
      <div class="color-group">
        <div class="bg-unpaid-previous-invoice color-box"></div>
        <span>{{ $t("Unpaid Previous Invoice") }}</span>
      </div>
    </div>
    <div>
      <div class="color-group">
        <div class="bg-advance-payment-while-contracting color-box"></div>
        <span>{{ $t("Advanced Payment While Contracting") }}</span>
      </div>
      <div class="color-group">
        <div class="bg-advance-payment-after-contracting color-box"></div>
        <span>{{ $t("Advanced Payment After Contracting") }}</span>
      </div>
      <div class="color-group">
        <div class="bg-is-suspended color-box"></div>
        <span>{{ $t("Is Suspended") }}</span>
      </div>
    </div>
  </v-layout>
</template>

<script>
export default {
  name: "ColorDetails"
};
</script>
