<template>
  <v-card>
    <v-card-title class="headline re-card-title" id="propertyQrTitle">
      <h3>{{ propertyDetails?.property_name }} {{ $t("QR Code") }}</h3>
    </v-card-title>
    <v-card-text id="qrImage">
      <v-layout row justify-center>
        <qrcode-vue :value="qrContent" :size="size" level="H" render-as="svg" />
      </v-layout>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-layout row justify-center>
        <v-btn dark class="re-orangtbtn mx-1" @click="printPropertyQR">
          {{ $t("Print") }}
        </v-btn>
        <v-btn dark @click="closeDialog">
          {{ $t("Cancel") }}
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import { createQRCodePrintingTemplate } from "../../helper/printedQRCodeTemplate";
export default {
  name: "property-qr-code-dialog",
  props: {
    propertyDetails: {
      type: Object,
      default: null
    },
    showModal: {
      default: false
    }
  },
  components: {
    QrcodeVue
  },
  data() {
    return {
      size: 350
    };
  },
  computed: {
    qrContent() {
      const fullPath = `${window.location.origin}/${this.$i18n.locale}/property-maintenance/${this.propertyDetails.id}`;
      return fullPath;
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    printPropertyQR() {
      const newWindow = window.open("", "_blank");
      const currentLocale = this.$i18n.locale;
      const matchedDir = currentLocale === "en" ? "ltr" : "rtl";
      const propertyName = this.propertyDetails?.property_name;
      const qrInnerHTML = document.getElementById("qrImage").innerHTML;
      const headerTitle = `${propertyName} ${this.$t("QR Code")}`;
      const descriptionText = this.$t(
        "Kindly, Scan this QR code to report a new property issue"
      );
      const newTemplate = createQRCodePrintingTemplate(
        currentLocale,
        matchedDir,
        propertyName,
        qrInnerHTML,
        headerTitle,
        descriptionText
      );
      newWindow.document.write(newTemplate);
      this.closeDialog();
    }
  }
};
</script>
<style lang="css" scoped>
.re-card-title {
  max-width: 100% !important;
  padding: 10px !important;
}
</style>
