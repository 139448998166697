import { PluginService } from "./index";

const tenantServices = PluginService("/tenant");

export default {
  getTenantsList(params) {
    return tenantServices({
      method: "GET",
      url: "/",
      params
    });
  },
  fetchTenantById(id) {
    return tenantServices({
      method: "GET",
      url: `/${id}`
    });
  },
  exportTenants(params) {
    return tenantServices({
      method: "GET",
      url: "/",
      params
    });
  },
  printTenantContracts(params) {
    return tenantServices({
      method: "GET",
      url: "",
      params
    });
  },
  uploadImages(data) {
    return tenantServices({
      method: "POST",
      url: "/image/upload/",
      data
    });
  },
  saveResidentialTenant(data) {
    return tenantServices({
      method: "POST",
      url: "/residential/",
      data
    });
  },
  saveLimitedLiabiltyCompanyTenant(data) {
    return tenantServices({
      method: "POST",
      url: "/limited-liability-company/",
      data
    });
  },
  saveListedCompanyTenant(data) {
    return tenantServices({
      method: "POST",
      url: "/listed-company/",
      data
    });
  },
  updateTenant(data, id) {
    return tenantServices({
      method: "PUT",
      url: `/${id}/`,
      data
    });
  },
  downloadImage(id) {
    return tenantServices({
      method: "GET",
      url: `/image/download/${id}/`,
      responseType: "blob"
    });
  },
  getCountriesList() {
    return tenantServices({
      method: "GET",
      url: "/countries/"
    });
  },
  uploadTenantsXLSFile(data) {
    return tenantServices({
      method: "POST",
      url: "/excel-files/",
      data
    });
  },
  downloadTenantsXLSSample() {
    return tenantServices({
      method: "GET",
      url: "/excel-files/",
      responseType: "blob"
    });
  },
  getDefaultCountryCode() {
    return tenantServices({
      method: "GET",
      url: "/country-codes/"
    });
  }
};
