import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import singleSpaVue from "single-spa-vue";
import VueSkeletonLoader from "skeleton-loader-vue";
import Vue from "vue";
import VuePhoneNumberInput from "vue-phone-number-input";

import pjson from "../package.json";

import App from "./App.vue";
import { i18n } from "./plugins/i18n";
import "./plugins/vee-validate";
import "./plugins/fetching";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import router from "./router";
import { translationsService } from "./services";
import PluginConfigService from "./services/plugins/plugin_config";
import tenantService from "./services/plugins/tenant";
import store from "./store";

Vue.component("vue-skeleton-loader", VueSkeletonLoader);
Vue.component("vue-phone-number-input", VuePhoneNumberInput);

/**
 * It's crucial to use the below global mixin, as it contains
 * an override of the 'router.go(-1)' method, which is 'globalGoBack'
 * to solve the issue of going back in navigation when
 * the pages are being opened in new tabs. In such case, the user
 * will be directed to Dashboard page.
 */
Vue.mixin({
  methods: {
    globalGoBack() {
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.replace("/dashboard");
    }
  }
});

Vue.config.productionTip = false;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {});
    },
    created: function () {
      Vue.prototype.$token = this.idToken;
      Vue.prototype.$merchant = this.merchant;
      Vue.prototype.$logout = this.logout;
      Vue.prototype.$ignoredSentryErrors = this.ignoredSentryErrors;
      Vue.prototype.$checkSentryErrors = this.checkSentryErrors;
      const lang = this.$route.path.slice(1, 3);
      if (lang === "en" || lang === "ar") {
        this.$i18n.locale = lang;
      }
      this.setLang();
      this.$vuetify.rtl = this.$i18n.locale === "ar";

      Sentry.setTag("microfrontend", "real_estate");
      Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_URL || this.sentryUrl,
        integrations: [
          new Integrations.Vue({
            Vue,
            attachProps: true,
            logErrors: true
          })
        ],
        release: `real_estate@${pjson.version}`,
        // tracesSampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE || 0.2,
        sampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE || 0.2,
        ignoreErrors: Vue.prototype.$ignoredSentryErrors,
        beforeSend: Vue.prototype.$checkSentryErrors,
        autoSessionTracking: false,
        enabled: !this.isLocal
      });
      this.setPluginConfig();
    },
    watch: {
      "$i18n.locale": function () {
        this.$vuetify.rtl = this.$i18n.locale === "ar";
      }
    },
    methods: {
      async setLang() {
        const real_estate_translations = await translationsService();
        const root_translations = this.translations();
        const full_translations = {
          en: {
            ...root_translations.en
          },
          ar: {
            ...root_translations.ar,
            ...real_estate_translations.data.body
          }
        };
        this.$i18n.setLocaleMessage("en", full_translations.en);
        this.$i18n.setLocaleMessage("ar", full_translations.ar);
      },
      async setPluginConfig() {
        try {
          const res = await this.$processReq(
            PluginConfigService.getPluginConfig(),
            false
          );
          const phoneInputResponse = await this.$processReq(
            tenantService.getDefaultCountryCode(),
            false
          );
          const config = res.data.body?.results[0];
          const phoneInputConfig = phoneInputResponse.data.body;
          const isActivated = config?.is_activated;
          const country = config?.country;
          const decimalCount = config?.currency_decimals_count;
          const currencyCode = config?.currency_code;
          const vatPercentage = parseFloat(config?.vat_percentage || 0).toFixed(
            2
          );
          const isVatEnabled = config?.is_vat_enabled;
          const defaultPhoneCountryCode = phoneInputConfig?.default_code;
          const phoneInputPreferredCountries = phoneInputConfig?.codes;
          store.dispatch("config/setCountry", country);
          store.dispatch("config/setIsActivated", isActivated);
          store.dispatch("config/setDecimalCount", decimalCount);
          store.dispatch("config/setCurrencyCode", currencyCode);
          store.dispatch("config/setVatPercentage", vatPercentage);
          store.dispatch("config/setIsVatEnabled", isVatEnabled);
          store.dispatch(
            "config/setDefaultPhoneCountryCode",
            defaultPhoneCountryCode
          );
          store.dispatch(
            "config/setPhoneInputPreferredCountries",
            phoneInputPreferredCountries
          );
        } catch (err) {
          console.log("Unable to get Configurations", err);
        }
      }
    },
    router,
    i18n,
    store
  }
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
