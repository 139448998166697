<template>
  <div class="new-contract">
    <v-layout class="pt-5">
      <v-flex grow pa-1 xs6>
        <h2 class="display-2 pl-4">{{ $t("Property") }}</h2>
      </v-flex>
      <v-flex shrink pa-1 xs6 text-xs-right>
        <v-btn dark class="mt-4 r-m-end" @click="globalGoBack"
          >← {{ $t("Back") }}</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout class="re-mm-24px">
      <v-flex class="flex-wrap" xs12 pa-1>
        <v-breadcrumbs :items="breadItemsSetLang()" class="pl-4">
          <template v-slot:divider>
            <v-icon>fiber_manual_record</v-icon>
          </template>
        </v-breadcrumbs>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-layout row wrap justify-center>
        <v-flex xs12 sm12 lg9>
          <v-card v-show="!isLoading">
            <v-tabs v-model="tab" @change="onTabChange">
              <v-tab>
                <v-card-title>
                  <h3>{{ $t("New Contract") }}</h3>
                </v-card-title>
              </v-tab>
              <v-tab :disabled="isLoadingInvoices">
                <v-card-title>
                  <h3>{{ $t("Invoices") }}</h3>
                </v-card-title>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card-text>
                  <v-form data-vv-scope="new_contract_scope">
                    <v-autocomplete
                      v-model="contract.tenant"
                      v-validate="'required'"
                      pb-3
                      clearable
                      :label="$t('Tenant Name')"
                      data-vv-name="tenant"
                      item-text="tenant_name"
                      item-value="id"
                      :disabled="disableTenant"
                      :items="tenants"
                      :error-messages="
                        errors.collect('new_contract_scope.tenant')
                      "
                      :no-data-text="`${
                        isLoadingTenants
                          ? $t('Loading...')
                          : $t('No data available')
                      }`"
                      @input.native="fetchTenants"
                    />

                    <v-autocomplete
                      v-model="contract.property"
                      v-validate="'required'"
                      :label="$t('Property Name')"
                      data-vv-name="property"
                      item-text="property_name"
                      item-value="id"
                      clearable
                      :disabled="disableProperties"
                      :error-messages="
                        errors.collect('new_contract_scope.property')
                      "
                      :items="properties"
                      :no-data-text="`${
                        isLoadingProperties
                          ? $t('Loading...')
                          : $t('No data available')
                      }`"
                      @change="updateUnits"
                      @input.native="fetchProperties"
                    />
                    <v-btn
                      @click="addMore"
                      dark
                      class="re-orangtbtn"
                      :disabled="disableUnitType"
                    >
                      {{ $t("Add Unit") }}
                    </v-btn>
                    <SelectedUnits
                      :selectedUnits="contract.selectedUnits"
                      :units="units"
                      outerValidatorScope="new_contract_scope"
                      @updateSelectedUnits="updateSelectedUnits($event)"
                      @updateContractAmount="updateContractAmount()"
                    />
                    <v-select
                      v-model="contract.payment_frequency"
                      :label="$t('Payment Period')"
                      item-text="display_name"
                      item-value="value"
                      :items="paymentPeriods"
                      v-validate="
                        `required${
                          isExpiredContract() ? '|included:monthly' : ''
                        }`
                      "
                      name="payment_frequency"
                      data-vv-name="payment_frequency"
                      :error-messages="
                        errors.collect('new_contract_scope.payment_frequency')
                      "
                      @change="clearContractDates"
                    />

                    <date-picker-without-buttons
                      v-model="contract.contract_start_date"
                      v-validate="'required'"
                      name="contract_start_date"
                      :label="$t('Contract Start Date')"
                      data-vv-name="contract_start_date"
                      :changed="contract.contract_start_date"
                      :allowed-dates="
                        $event =>
                          isExpiredContract() ? allowFirstDay($event) : true
                      "
                      :error-messages="
                        errors.collect('new_contract_scope.contract_start_date')
                      "
                    />

                    <date-picker-without-buttons
                      v-model="contract.payment_start_date"
                      v-validate="
                        `required|date_format:yyyy-MM-dd|date_between:${contract.contract_start_date},2100-01-01,${contract.contract_start_date}`
                      "
                      :default_date="contract.contract_start_date"
                      name="payment_start_date"
                      :label="$t('Payment Start Date')"
                      data-vv-name="payment_start_date"
                      :changed="contract.payment_start_date"
                      :allowed-dates="
                        $event =>
                          isExpiredContract() ? allowFirstDay($event) : true
                      "
                      :error-messages="
                        errors.collect('new_contract_scope.payment_start_date')
                      "
                    />

                    <date-picker-without-buttons
                      v-model="contract.contract_end_date"
                      v-validate="
                        `required|date_format:yyyy-MM-dd|date_between:${contract.payment_start_date},2100-01-01`
                      "
                      name="contract_end_date"
                      :label="$t('Contract End Date')"
                      data-vv-name="contract_end_date"
                      :changed="contract.contract_end_date"
                      :allowedDates="
                        $event =>
                          allowPaymentPeriodDates(
                            $event,
                            contract.payment_frequency,
                            contract.contract_start_date
                          )
                      "
                      :error-messages="
                        errors.collect('new_contract_scope.contract_end_date')
                      "
                    />

                    <v-text-field
                      v-model="contract.amount"
                      class="custom"
                      :label="$t('Amount')"
                      v-validate="
                        `required|decimal:${getDecimalCount}|min_value:0`
                      "
                      name="amount"
                      data-vv-name="amount"
                      :error-messages="
                        errors.collect('new_contract_scope.amount')
                      "
                    />

                    <v-text-field
                      v-model="contract.original_amount"
                      v-validate="
                        `required|decimal:${getDecimalCount}|min_value:${
                          contract.amount || 0
                        }`
                      "
                      data-vv-name="original_amount"
                      class="custom"
                      :label="$t('Original Contract Amount')"
                      :error-messages="
                        errors.collect('new_contract_scope.original_amount')
                      "
                    />

                    <v-text-field
                      v-model="contract.advanced_payments_count"
                      v-validate="`required||numeric`"
                      data-vv-name="advanced_payments_count"
                      class="custom"
                      :label="$t('Number of invoices for advanced payment')"
                      name="advanced_payments_count"
                      :error-messages="
                        errors.collect(
                          'new_contract_scope.advanced_payments_count'
                        )
                      "
                    />

                    <v-text-field
                      v-model="contract.insurance_amount"
                      v-validate="`decimal:${getDecimalCount}`"
                      class="custom"
                      data-vv-name="insurance_amount"
                      :label="$t('Insurance Amount')"
                      :error-messages="
                        errors.collect('new_contract_scope.insurance_amount')
                      "
                    />

                    <v-text-field
                      v-model="contract.reference"
                      class="custom"
                      :label="$t('Contract Reference')"
                    />

                    <v-checkbox
                      :label="$t('Suspended')"
                      v-model="contract.is_suspended"
                    />

                    <v-layout class="align-center mb-2 ml-3">
                      <span class="re-upload__span">{{
                        $t("Upload Contract")
                      }}</span>
                      <input
                        type="file"
                        id="myUpload"
                        style="display: none"
                        ref="uploadContract"
                        accept="image/*"
                        @change="event => uploadImage(event)"
                      />
                      <v-btn
                        @click="$refs.uploadContract.click()"
                        class="re-custom-file-input"
                        >{{ $t("Upload") }}</v-btn
                      >
                      <span v-if="contract.attachment">
                        {{ getImageName(contract.attachment.contract_file) }}
                      </span>
                    </v-layout>
                    <span> {{ $t("Payment Methods") }}: </span>
                    <v-layout class="align-center mb-2 ml-3">
                      <v-checkbox
                        v-for="option in contractPaymentMethods"
                        v-model="contract.allowed_pyament_methodes"
                        multiple
                        v-validate="'required'"
                        data-vv-name="allowed_pyament_methodes"
                        :label="option.display_name"
                        :value="option.value"
                        :key="option"
                      />
                      {{ contract.payment_methods }}
                    </v-layout>
                    <div v-if="!contract.allowed_pyament_methodes.length">
                      <span class="red-text">{{
                        errors.first(
                          "new_contract_scope.allowed_pyament_methodes"
                        )
                      }}</span>
                    </div>
                    <v-checkbox
                      :label="$t('Payment is not in sequence')"
                      v-model="contract.is_payment_made_in_sequence"
                      :disabled="disableSequenceOption"
                      @change="updateExpirationCheckbox()"
                      :true-value="false"
                      :false-value="true"
                    />
                    <v-checkbox
                      :label="$t('No expiration on payment links')"
                      v-model="contract.is_payment_links_expiration_enabled"
                      :disabled="disableExpirationOption"
                      :true-value="false"
                      :false-value="true"
                      @change="resetCotractDates()"
                    />
                  </v-form>
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-card-text>
                  <invoice
                    v-show="tab === 1"
                    :title="$t('New Contract Invoices')"
                    :selectedInvoices="selectedInvoices"
                    :isDialogLoading="isLoadingInvoices"
                    :isAction="true"
                    :isShowColors="tab === 1"
                    :contractPaymentMethods="paymentMethods"
                    :isSelectedPropertySupportsVat="
                      isSelectedPropertySupportsVat
                    "
                  />
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
          <vue-skeleton-loader
            rounded
            class="w-full"
            type="rect"
            v-if="isLoading"
            :width="'100%'"
            :height="'750px'"
            animation="fade"
          />
        </v-flex>
      </v-layout>
      <ConfirmationDialog
        v-model="isConfirmed"
        :label="$t('Confirmation')"
        :message="$t('Are you sure that you want to make a new contract?')"
        @acceptDialog="saveData"
      />
    </v-layout>

    <div class="text-xs-center mt-4">
      <v-btn
        class="re-orangtbtn"
        :disabled="isLoading || isUploadingFile || isLoadingInvoices"
        @click="confirmData"
        >{{ $t("Save") }}</v-btn
      >

      <v-btn dark :disabled="isLoading" @click="globalGoBack">{{
        $t("Cancel")
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import moment from "moment";
import { mapGetters } from "vuex";

import SelectedUnits from "../../components/Contract/SelectedUnits.vue";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog";
import Invoice from "../../components/Invoices/Invoice.vue";
import DatePickerWithoutButtons from "../../components/picker/DatePickerWithoutButtons.vue";
import { ContractEnums } from "../../enums";
import {
  formatDateForPicker,
  transformforPropertyUnits,
  reformatAmountWithDecimals
} from "../../helper";
import contractService from "../../services/plugins/contract";
import invoiceService from "../../services/plugins/invoices";
import pluginConfigService from "../../services/plugins/plugin_config";
import propertiesService from "../../services/plugins/properties";
import tenantServices from "../../services/plugins/tenant";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";
import contractPymtMethodMixin from "@/mixins/contractPymtMethodMixin";
import dateMixins from "@/mixins/date_mixins";

export default {
  name: "NewContract",
  mixins: [breadItemsSetLang, contractPymtMethodMixin, dateMixins],
  components: {
    Invoice,
    ConfirmationDialog,
    DatePickerWithoutButtons,
    SelectedUnits
  },
  $_veeValidate: {
    validator: "new"
  },
  data() {
    return {
      isConfirmed: false,
      isLoading: false,
      contract: {
        is_suspended: false,
        selectedUnits: [],
        is_payment_links_expiration_enabled: true,
        is_payment_made_in_sequence: true,
        allowed_pyament_methodes: [
          "cash",
          "cheque",
          "bank transfer",
          "payment link",
          "pos (debit/ credit)"
        ],
        advanced_payments_count: "0"
      },
      tab: null,
      properties: [],
      tenants: [],
      units: [],
      selectedInvoices: [],
      isUploadingFile: false,
      disableTenant: false,
      disableProperties: false,
      disableExpirationOption: false,
      disableSequenceOption: false,
      isLoadingProperties: false,
      isLoadingTenants: false,
      fetchProperties: null,
      fetchTenants: null,
      fetchInvoices: null,
      isLoadingInvoices: false,
      showReset: false,
      pluginConfig: {},
      isNewContract: true,
      isLoadingUnits: false,
      isSelectedPropertySupportsVat: false
    };
  },
  async created() {
    this.fetchProperties = debounce(this.loadProperties, 500);
    this.fetchTenants = debounce(this.loadTenants, 500);
    this.fetchInvoices = debounce(this.loadInvoices, 500);
    this.isLoading = true;
    this.fetchPluginConfiguration();
    await this.initialCall();

    this.$validator.localize("en", this.dictionary);
    this.initializeData();
  },
  provide() {
    return {
      parentValidator: this.$validator
    };
  },
  computed: {
    dictionary() {
      const messages = {};
      for (let i = 0; i < this.selectedInvoices.length; i++) {
        messages[`actual_paid_amount_${i}`] = {
          required: () => this.$t("This field is required"),
          max_value: (fName, params) =>
            `${this.$t(
              "Actual paid amount should be less than or equal to the original amount"
            )} ${params[0]}`,
          decimal: () =>
            this.$t("Decimal value can contain upto") +
            ` (${this.getDecimalCount}) ` +
            this.$t("digits")
        };
      }
      return {
        custom: {
          tenant: {
            required: () => this.$t("Tenant Name is required")
          },
          property: {
            required: () => this.$t("Property Name is required")
          },
          contract_end_date: {
            required: () => this.$t("Contract end date is required"),
            date_between: () =>
              this.$t(
                "Kindly, enter a contract end date which is greater than the contract start date and payment start date"
              )
          },
          payment_start_date: {
            required: () => this.$t("Payment start date is required"),
            date_between: () =>
              this.$t(
                "Kindly, enter a contract payment start date which is greater than the contract start date"
              )
          },
          contract_start_date: {
            required: () => this.$t("Contract start date is required")
          },
          original_amount: {
            required: () => this.$t("Original Amount is required"),
            min_value: () =>
              this.$t(
                "Monthly paid amount cannot be greater than the original contract amount"
              )
          },
          advanced_payments_count: {
            required: () => this.$t("Advanced Payment Count is required"),
            numeric: () => this.$t("Please enter a number")
          },
          payment_frequency: {
            required: () => this.$t("Payment Period is required"),
            included: () =>
              this.$t("Payment Period should be 'Monthly' for expired contract")
          },
          allowed_pyament_methodes: {
            required: () => this.$t("Please select at lease one payment method")
          },
          ...messages
        }
      };
    },
    duplicateContract() {
      return Object.assign({}, this.contract);
    },
    ...mapGetters("config", ["getDecimalCount"])
  },
  watch: {
    "$i18n.locale": function () {
      this.$validator.localize(this.$i18n.locale, this.dictionary);
      this.initialCall();
      this.initializeData();
    },
    async duplicateContract(newVal, oldVal) {
      if (isEqual(newVal, oldVal)) return;

      if (
        !(
          newVal.contract_start_date &&
          newVal.contract_end_date &&
          newVal.payment_start_date &&
          newVal.original_amount &&
          newVal.amount &&
          newVal.property &&
          newVal.advanced_payments_count &&
          this.isUnitsInputValid()
        )
      ) {
        return;
      }

      if (
        newVal.contract_start_date !== oldVal.contract_start_date ||
        newVal.contract_end_date !== oldVal.contract_end_date ||
        newVal.payment_start_date !== oldVal.payment_start_date ||
        newVal.property !== oldVal.property ||
        newVal.amount !== oldVal.amount ||
        newVal.original_amount !== oldVal.original_amount ||
        newVal.advanced_payments_count !== oldVal.advanced_payments_count ||
        newVal.is_payment_made_in_sequence !==
          oldVal.is_payment_made_in_sequence ||
        newVal.is_payment_links_expiration_enabled !==
          oldVal.is_payment_links_expiration_enabled
      ) {
        await this.$nextTick();
        if (await this.$validator.validateAll("new_contract_scope")) {
          await this.callInvoices();
        }
      }
    },
    "contract.contract_start_date": function (value) {
      if (value) {
        this.contract.contract_end_date = "";
      }
    },
    "contract.amount": function (value) {
      this.contract.original_amount = value;
    },
    "contract.selectedUnits": function () {
      this.updateContractAmount();
    },
    "contract.payment_frequency": function () {
      this.updateContractAmount();
    },
    "selectedInvoices.length"() {
      this.$validator.localize(this.$i18n.locale, this.dictionary);
    }
  },
  methods: {
    async onTabChange(tab) {
      if (
        tab === 1 &&
        (!(await this.$validator.validateAll("new_contract_scope")) ||
          !this.contract.selectedUnits.length)
      ) {
        this.tab = 0;
        return;
      }

      this.tab = tab;
    },
    async initialCall() {
      const params = this.$route.params;

      if (params.isTenant) {
        await this.fetchTenantById(params.tenant_id);
      } else {
        this.fetchTenants();
      }

      if (!params.isTenant && params.property) {
        await this.fetchPropertyById(params.property);
      } else {
        await this.fetchProperties();
      }
    },
    async callInvoices() {
      await this.fetchInvoices();

      if (this.showReset) {
        this.$root.$emit("snackbar", {
          text: this.$t("Your invoice changes has been reset."),
          color: "success"
        });
      }

      if (!this.showReset) {
        this.showReset = true;
      }
    },
    async uploadImage(e) {
      try {
        this.isUploadingFile = true;
        const file = e.target.files[0];

        const formData = new FormData();
        formData.append("contract_file", file);

        const response = await this.$processReq(
          contractService.uploadImages(formData),
          false
        );
        this.$root.$emit("snackbar", {
          text: this.$t("File Uploaded Successfully"),
          color: "success"
        });
        this.isUploadingFile = false;

        this.contract = {
          ...this.contract,
          attachment: response.data.body
        };
      } catch (err) {
        this.isUploadingFile = false;
        this.$root.$emit("snackbar", {
          text: this.$t("Something went wrong while uploading file"),
          color: "error"
        });
        console.log("Error: Unable to Upload image - ", err);
      }
    },
    async initializeData() {
      const params = this.$route.params;
      if (params.isTenant) {
        await this.$nextTick();

        this.contract = {
          ...this.contract,
          tenant: params.tenant_id
        };
        this.disableTenant = true;
      } else if (params.property) {
        this.contract = {
          ...this.contract,
          property: params.property
        };

        await this.$nextTick();
        await this.updateUnits(params.property);

        await this.$nextTick();
        this.initializeSelectedUnits(params.units);
        this.disableProperties = true;
      }
    },
    async updateUnits(propertyId) {
      this.resetValues();
      this.resetSelectedUnits();
      if (propertyId) {
        this.units = [];
        const property = this.properties.find(
          property => property.id === propertyId
        );
        if (Object.prototype.hasOwnProperty.call(property, "is_vat_enabled")) {
          this.isSelectedPropertySupportsVat = property.is_vat_enabled;
        }
        let unitsList = [];
        if (property.unit_details) {
          unitsList = [...property.unit_details];
        } else {
          unitsList = await this.fetchPropertyUnits(propertyId);
        }
        const { units } = transformforPropertyUnits(unitsList);
        this.units = units;
      } else {
        this.units = [];
      }
    },
    resetValues() {
      this.showReset = false;
      if (this.contract.contract_start_date) {
        this.contract = {
          ...this.contract,
          contract_start_date: ""
        };
      }

      if (this.contract.contract_end_date) {
        this.contract = {
          ...this.contract,
          contract_end_date: ""
        };
      }

      if (this.contract.payment_start_date) {
        this.contract = {
          ...this.contract,
          payment_start_date: ""
        };
      }

      this.contract = {
        ...this.contract,
        amount: "",
        original_amount: "",
        insurance_amount: "",
        reference: "",
        attachment: null,
        is_suspended: false
      };
    },
    resetSelectedUnits() {
      this.contract = { ...this.contract, selectedUnits: [] };
    },
    async fetchPropertyById(id) {
      try {
        const response = await this.$processReq(
          propertiesService.getPropertyDetails(id),
          false
        );

        const { units } = transformforPropertyUnits(
          response.data.body.unit_details
        );
        const propertyFullDetails = {
          ...response.data.body,
          unit_details: units
        };
        this.properties = [propertyFullDetails];
        this.isSelectedPropertySupportsVat = propertyFullDetails.is_vat_enabled;
      } catch (err) {
        console.log("Error: Unable to fetch property by ID - ", err);
      }
    },
    async fetchTenantById(id) {
      try {
        const response = await this.$processReq(
          tenantServices.fetchTenantById(id),
          false
        );

        this.tenants = [response.data.body];
      } catch (err) {
        console.log("Error: Unable to fetch tenant by ID - ", err);
      }
    },
    async confirmData() {
      try {
        if (await this.checkTabsValidationAndRefocus()) {
          if (this.contract.selectedUnits.length) {
            this.isConfirmed = true;
          } else {
            this.$root.$emit("snackbar", {
              text: this.$t(
                "No selected units, kindly, select at least one unit"
              ),
              color: "error"
            });
          }
        }
      } catch (err) {
        this.isLoading = false;
        console.log("Error: Unable to confirm data - ", err);
      }
    },
    async saveData() {
      try {
        this.isLoading = true;
        let data = cloneDeep(this.contract);
        delete data.selectedUnits;
        let contract_units = this.contract.selectedUnits.map(unit => {
          return {
            unit: unit.id,
            occupied_meters: unit.occupied_meters,
            meter_price: unit.unittype.is_rentable_by_meter ? unit.price : null,
            unit_price: unit.invoice_amount
          };
        });
        data = {
          ...data,
          contract_units,
          attachment: this.contract.attachment
            ? this.contract.attachment.id
            : undefined,
          insurance_amount: this.contract.insurance_amount || undefined,
          contact_reference: this.contract.reference || undefined,
          contract_start_date: formatDateForPicker(
            this.contract.contract_start_date
          ),
          contract_end_date: formatDateForPicker(
            this.contract.contract_end_date
          ),
          payment_start_date: formatDateForPicker(
            this.contract.payment_start_date
          ),
          reason: ContractEnums.NewContract,
          modified_payments: this.selectedInvoices.map(invoice => {
            invoice = {
              ...invoice,
              is_advanced_payment:
                invoice.is_advanced_payment_after_contracting,
              payment_date: formatDateForPicker(invoice.payment_date, true),
              status: invoice.is_paid ? "Paid" : "Expired",
              amount: invoice.actual_paid_amount
            };

            delete invoice.is_paid;
            delete invoice.actual_paid_amount;
            delete invoice.allowed_date;
            delete invoice.is_advanced_payment_after_contracting;
            return invoice;
          })
        };
        await this.$processReq(contractService.saveContract(data), false);
        this.$root.$emit("snackbar", {
          text: this.$t("Contract Created Successfully"),
          color: "success"
        });
        if (this.$route.params.goBack) {
          this.globalGoBack();
        } else {
          this.$router.push({ name: "tenant_management" });
        }
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log("Error: Unable to save contract - ", err);
      }
    },
    async loadTenants(event) {
      this.isLoadingTenants = true;
      try {
        let params = {
          limit: 10,
          offset: 0
        };
        if (event) {
          params = {
            ...params,
            search: event.target.value
          };
        } else {
          params.search = "";
        }

        const response = await this.$processReq(
          tenantServices.getTenantsList(params),
          false
        );

        this.tenants = response.data.body.results;
      } catch (err) {
        console.log("Error: Unable to get tenant list - ", err);
      }
      this.isLoadingTenants = false;
    },
    getImageName(imageUrl) {
      if (imageUrl) {
        let url = new URL(imageUrl);

        // eslint-disable-next-line no-useless-escape
        url = url.pathname.replace(/^.*(\\|\/|\:)/, "");
        return url;
      }

      return "";
    },
    async loadProperties(event) {
      this.isLoadingProperties = true;
      try {
        let params = {
          limit: 10,
          offset: 0
        };
        if (event) {
          params = {
            ...params,
            search: event.target.value
          };
        } else {
          params.search = "";
        }

        const response = await this.$processReq(
          propertiesService.getPropertiesList(params),
          false
        );

        this.properties = response.data.body.results;
      } catch (err) {
        console.log("Error: Unable to get properties list - ", err);
      }
      this.isLoadingProperties = false;
    },
    async loadInvoices() {
      try {
        this.isLoadingInvoices = true;
        let params = {
          contract_start_date: this.contract.contract_start_date,
          contract_end_date: this.contract.contract_end_date,
          payment_start_date: this.contract.payment_start_date,
          original_amount: this.contract.original_amount,
          amount: this.contract.amount,
          contract_termination_date: "",
          number_of_advance_payments: this.contract.advanced_payments_count,
          is_payment_made_in_sequence:
            this.contract.is_payment_made_in_sequence,
          is_payment_links_expiration_enabled:
            this.contract.is_payment_links_expiration_enabled,
          payment_frequency: this.contract.payment_frequency,
          property_id: this.contract.property
        };

        const response = await this.$processReq(
          invoiceService.fetchInvoices(params),
          false
        );

        const invoices = response.data.body;
        await this.$validator.reset("invoices_scope");
        this.selectedInvoices = invoices.open_balance_missing_payments.map(
          payment => {
            return {
              ...payment,
              is_paid: payment.status == this.$t("Paid") ? true : false,

              payment_date: payment.payment_due_date,
              allowed_date: moment(payment.payment_date).format("YYYY-MM-DD"),
              payment_due_date: payment.payment_due_date,
              amount: reformatAmountWithDecimals(
                payment.contract_original_amount
              ),
              paid_amount_before_discount: reformatAmountWithDecimals(
                payment.paid_amount_before_discount
              ),
              discount: reformatAmountWithDecimals(payment.discount),
              paid_amount: reformatAmountWithDecimals(payment.paid_amount),
              actual_paid_amount: reformatAmountWithDecimals(payment.amount),
              is_payment_made_in_sequence:
                this.contract.is_payment_made_in_sequence,
              payment_method: "payment link",
              receipt_voucher_reference: null,
              cash_treasury: null,
              bank: null,
              cheque_number: null,
              cheque_date: null,
              reference_number: null,
              is_advanced_payment_after_contracting: false,
              is_late_payment: false
            };
          }
        );
        this.isLoadingInvoices = false;
      } catch (err) {
        this.isLoadingInvoices = false;
        console.log("Error: Unable to show Invoices - ", err);
      }
    },
    addMore() {
      this.contract.selectedUnits.push({
        id: "",
        unit_number: "",
        invoice_amount: "",
        unittype: {
          is_rentable_by_meter: false
        }
      });
    },
    updateSelectedUnits({ data, index }) {
      this.resetValues();
      if (data) {
        const unit = this.units.find(
          unit => data === (unit.unit_id || unit.id)
        );
        this.contract.selectedUnits[index] = {
          ...unit,
          invoice_amount: unit.unittype.is_rentable_by_meter
            ? reformatAmountWithDecimals(0)
            : unit.invoice_amount
        };
        this.updateContractAmount();
      }
    },
    clearContractDates() {
      this.contract.contract_start_date = "";
      this.contract.payment_start_date = "";
      this.contract.contract_end_date = "";
    },
    initializeSelectedUnits(selectedUnits) {
      selectedUnits = this.properties[0].unit_details.filter(unit =>
        selectedUnits.includes(unit.id)
      );
      this.contract.selectedUnits = selectedUnits.map(unit => {
        return {
          ...unit,
          invoice_amount: unit.unittype.is_rentable_by_meter
            ? reformatAmountWithDecimals(0)
            : unit.invoice_amount
        };
      });
      this.updateContractAmount();
    },
    getMatchedPaymentFrequencyRate() {
      switch (this.contract.payment_frequency) {
        case "monthly":
          return 1;
        case "quarterly":
          return 3;
        case "semi-annually":
          return 6;
        case "annually":
          return 12;
        default:
          return 1;
      }
    },
    async checkTabsValidationAndRefocus() {
      // checking both scopes[new_contract_scope, invoices_scope]
      const isContractDetailsFormValid = await this.$validator.validateAll(
        "new_contract_scope"
      );
      const isInvoicesDetailsFormValid = await this.$validator.validateAll(
        "invoices_scope"
      );

      if (!isContractDetailsFormValid) {
        this.tab = 0; // focus on contract details tab
        return false;
      } else if (!isInvoicesDetailsFormValid) {
        this.tab = 1; // focus on Invoices details tab
        return false;
      }
      // otherwise all scopes are valid
      return true;
    },
    updateContractAmount() {
      this.contract.amount = reformatAmountWithDecimals(0);
      const selectedFrequencyRate = this.getMatchedPaymentFrequencyRate();
      this.contract.selectedUnits.forEach(unit => {
        if (unit.invoice_amount !== "") {
          const calculatedUnitAmount =
            reformatAmountWithDecimals(unit.invoice_amount) *
            selectedFrequencyRate;
          this.contract.amount = reformatAmountWithDecimals(
            +this.contract.amount + calculatedUnitAmount
          );
        }
      });
    },
    isUnitsInputValid() {
      if (!this.contract.selectedUnits.length) {
        return false;
      }
      this.contract.selectedUnits.forEach(unit => {
        if (unit.unittype.is_rentable_by_meter) {
          if (unit.occupied_meters && unit.occupied_meters < unit.totalSpace) {
            return false;
          }
        }
      });
      return true;
    },
    isExpiredContract() {
      if (
        this.contract.is_payment_made_in_sequence &&
        this.contract.is_payment_links_expiration_enabled
      ) {
        return true;
      } else return false;
    },
    async fetchPluginConfiguration() {
      try {
        const resp = await this.$processReq(
          pluginConfigService.getPluginConfig(),
          false
        );
        this.pluginConfig = resp.data?.body?.results[0] || {};
        this.setPluginConfigurations();
      } catch (err) {
        console.log("Error: Unable to fetch Configuration - ", err);
      }
    },
    setPluginConfigurations() {
      if (
        this.pluginConfig.payment_in_sequence_for_all_contracts ===
        "payment in sequence for all contracts"
      ) {
        this.disableSequenceOption = true;
        this.contract.is_payment_made_in_sequence = true;
      } else if (
        this.pluginConfig.payment_in_sequence_for_all_contracts ===
        "no payment in sequence for all contracts"
      ) {
        this.disableSequenceOption = true;
        this.contract.is_payment_made_in_sequence = false;
      }

      if (
        this.pluginConfig.payment_request_expires_for_all_contracts ===
        "expiry for all contracts"
      ) {
        this.disableExpirationOption = true;
        this.contract.is_payment_links_expiration_enabled = true;
      } else if (
        this.pluginConfig.payment_request_expires_for_all_contracts ===
        "no expiry for all contracts"
      ) {
        this.disableExpirationOption = true;
        this.contract.is_payment_links_expiration_enabled = false;
      }
      if (
        this.pluginConfig.payment_in_sequence_for_all_contracts ===
          "case by case" &&
        this.pluginConfig.payment_request_expires_for_all_contracts ===
          "case by case"
      ) {
        this.contract.is_payment_links_expiration_enabled = false;
      }
    },
    updateExpirationCheckbox() {
      if (!this.contract.is_payment_made_in_sequence) {
        this.disableExpirationOption = true;
        this.contract.is_payment_links_expiration_enabled = false;
      } else if (
        this.pluginConfig.payment_request_expires_for_all_contracts ===
        "case by case"
      ) {
        this.disableExpirationOption = false;
      }
    },
    resetCotractDates() {
      this.contract.contract_start_date = "";
      this.contract.contract_end_date = "";
    },
    async fetchPropertyUnits(propertyId) {
      try {
        this.isLoadingUnits = true;
        const resp = await this.$processReq(
          propertiesService.getPopertyUnitsList(propertyId),
          false
        );
        this.isLoadingUnits = false;
        const unitsList = resp.data.body;
        return unitsList;
      } catch (err) {
        this.isLoadingUnits = false;
        console.log("Error: Unable to get property Units list - ", err);
        return [];
      }
    }
  }
};
</script>

<style scoped>
.re-orangtbtn {
  color: white !important;
}
.re-orangtbtn:disabled {
  background-color: #f5f5f5 !important;
  color: black !important;
}
</style>
