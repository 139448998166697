import { PluginService } from "./index";

const cashTreasuryService = PluginService("/payment/cash-treasury");

export default {
  getCashTreasuryList(params) {
    return cashTreasuryService({
      method: "GET",
      url: "/",
      params
    });
  },
  getCashTreasuryById(id) {
    return cashTreasuryService({
      method: "GET",
      url: `/${id}/`
    });
  },
  addNewCashTreasury(params) {
    return cashTreasuryService({
      method: "POST",
      url: "/",
      data: params
    });
  },
  updateCashTreasury(params, id) {
    return cashTreasuryService({
      method: "PUT",
      url: `/${id}/`,
      data: params
    });
  }
};
