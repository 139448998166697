import { PluginService } from "./index";

const profileService = PluginService("/profiles");

export default {
  getProfileList(params) {
    return profileService({
      method: "GET",
      url: "/",
      params
    });
  },
  getProfileById(id) {
    return profileService({
      method: "GET",
      url: `/${id}/`
    });
  },
  addNewProfile(params) {
    return profileService({
      method: "POST",
      url: "/",
      data: params
    });
  },
  updateProfile(params, id) {
    return profileService({
      method: "PUT",
      url: `/${id}/`,
      data: params
    });
  },
  uploadImages(data) {
    return profileService({
      method: "POST",
      url: "/image/upload/",
      data
    });
  },
  downloadImage(id) {
    return profileService({
      method: "GET",
      url: `/image/download/${id}/`,
      responseType: "blob"
    });
  }
};
