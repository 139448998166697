export default {
  data() {
    return {
      page: 1,
      limit: 10,
      pagination: {
        rowsPerPage: 10,
        page: 1
      },
      sortingParams: {
        rowsPerPage: 10,
        descending: false
      },
      totalCount: 0,
      searchFilter: {},
      defaultValue: 10
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    order() {
      return this.sortingParams.descending || this.sortingParams.sortBy;
    }
  },

  methods: {
    setDefaultPagination(defaultValue) {
      this.pagination.rowsPerPage = defaultValue;
      this.limit = defaultValue;
      this.sortingParams.rowsPerPage = defaultValue;
      this.defaultValue = defaultValue;
    },
    setPaginationParams() {
      let data = {
        search: this.searchFilter.search,
        limit: this.limit,
        offset: (this.pagination.page - 1) * this.limit
      };
      if (this.row === "show-all") {
        data = {
          ...data,
          limit: this.totalCount,
          offset: 0
        };
      }
      return data;
    },
    setSortingParams() {
      let data = {
        ordering: !this.sortingParams.descending
          ? this.sortingParams.sortBy
          : `-${this.sortingParams.sortBy}`
      };
      return data;
    },
    showAllPagination() {
      if (this.row === "show-all") {
        this.pagination = {
          ...this.pagination,
          rowsPerPage: this.totalCount
        };
        this.sortingParams = {
          ...this.sortingParams,
          rowsPerPage: this.totalCount
        };
      }
    },
    setPagination(count) {
      this.pagination = {
        ...this.pagination,
        totalItems: count,
        page: this.pagination.page > 0 ? this.pagination.page : 1,
        rowsPerPage: this.row !== "" ? count : this.defaultValue
      };

      if (this.totalCount <= count) {
        this.totalCount = count;
      }
    }
  }
};
