<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-btn dark class="re-orangtbtn re-full" v-on="on">
        {{ $t("Filter") }} <v-icon right>filter_list</v-icon>
      </v-btn>
    </template>

    <v-card class="pb-4">
      <v-list class="v-list-filter">
        <v-list-tile avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ $t("Filter") }}</v-list-tile-title>
          </v-list-tile-content>

          <v-list-tile-action>
            <v-btn icon @click="menu = false">
              <v-icon> close </v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>

      <v-divider />

      <v-list>
        <v-list-tile class="list-item" v-if="!isTenant">
          <v-autocomplete
            v-model="initiator"
            :label="$t('Initiator')"
            clearable
            item-text="username"
            item-value="id"
            :items="initiators"
            :no-data-text="
              `${isLoadingOptions ? $t('Loading...') : $t('No data available')}`
            "
          />
        </v-list-tile>

        <v-list-tile class="list-item" v-if="!isTenant">
          <date-picker
            v-model="createdFrom"
            classes="filter-width"
            :label="$t('Created Date(From)')"
            :changed="createdFrom"
          />
          <date-picker
            v-model="createdTo"
            classes="filter-width"
            :label="$t('Created Date(To)')"
            :changed="createdTo"
          />
        </v-list-tile>

        <v-list>
          <v-list-tile v-if="isSuspended">
            <v-select
              v-model="suspended_resumed"
              :label="$t('Is Suspended')"
              item-text="label"
              item-value="value"
              :items="suspendFilter"
            />
          </v-list-tile>
        </v-list>
        <v-list-tile v-if="!isEditPrice" class="list-item">
          <v-autocomplete
            v-model="tenant"
            :label="$t('Tenant')"
            clearable
            item-text="tenant_name"
            item-value="id"
            :items="tenants"
            :no-data-text="
              `${isLoadingOptions ? $t('Loading...') : $t('No data available')}`
            "
            @input.native="fetchTenants"
          />
        </v-list-tile>

        <v-list-tile class="list-item">
          <v-autocomplete
            v-model="property"
            :label="$t('Property')"
            clearable
            item-text="property_name"
            item-value="id"
            :items="properties"
            :no-data-text="
              `${isLoadingOptions ? $t('Loading...') : $t('No data available')}`
            "
            @input.native="fetchProperties"
          />
        </v-list-tile>

        <v-list-tile v-if="isTenant">
          <v-select
            v-model="status"
            item-text="label"
            item-value="value"
            :label="$t('Status')"
            :items="statusFilter"
          />
        </v-list-tile>

        <v-list-tile v-if="isTenant" class="list-item">
          <v-autocomplete
            v-model="profile"
            :label="$t('Profile')"
            clearable
            item-text="profile_name"
            item-value="id"
            :items="profiles"
            :no-data-text="
              `${isLoadingOptions ? $t('Loading...') : $t('No data available')}`
            "
            @input.native="fetchProfiles"
          />
        </v-list-tile>

        <v-list-tile v-if="isTenant" class="list-item">
          <v-autocomplete
            v-model="propertyClassification"
            :label="$t('Property Classification')"
            clearable
            item-text="class_name"
            item-value="id"
            :items="propertyClassifications"
            :no-data-text="
              `${isLoadingOptions ? $t('Loading...') : $t('No data available')}`
            "
            @input.native="fetchPropertyClassifications"
          />
        </v-list-tile>

        <v-list-tile
          v-if="!(isAdvance || isModified || isSuspended || isTenant)"
          class="list-item"
        >
          <v-select
            v-model="unittype"
            :label="$t('Unit Type')"
            clearable
            item-text="unit_type_name"
            item-value="id"
            :items="unitTypes"
          />
        </v-list-tile>

        <v-list-tile
          v-if="
            !(
              isEditPrice ||
              isAdvance ||
              isManual ||
              isModified ||
              isSuspended ||
              isTenant
            )
          "
          class="list-item"
        >
          <date-picker
            v-model="startFrom"
            classes="filter-width"
            :label="
              !isRenew
                ? $t('Start Date(From)')
                : $t('Old contract start date (From)')
            "
            :changed="startFrom"
          />
          <date-picker
            v-model="startTo"
            classes="filter-width"
            :label="
              !isRenew
                ? $t('Start Date(To)')
                : $t('Old contract start date (To)')
            "
            :changed="startTo"
          />
        </v-list-tile>

        <v-list-tile
          v-if="
            !(
              isEditPrice ||
              isAdvance ||
              isManual ||
              isModified ||
              isSuspended ||
              isTenant
            )
          "
          class="list-item"
        >
          <date-picker
            v-model="endFrom"
            :label="
              !isRenew
                ? $t('End Date(From)')
                : $t('Old contract end date (From)')
            "
            classes="filter-width"
            :changed="endFrom"
          />
          <date-picker
            v-model="endTo"
            :label="
              !isRenew ? $t('End Date(To)') : $t('Old contract end date (To)')
            "
            classes="filter-width"
            :changed="endTo"
          />
        </v-list-tile>

        <v-list-tile v-if="isRenew" class="list-item">
          <date-picker
            v-model="newStartFrom"
            :label="$t('New contract start date (From)')"
            classes="filter-width"
            :changed="newStartFrom"
          />
          <date-picker
            v-model="newStartTo"
            :label="$t('New contract start date (To)')"
            classes="filter-width"
            :changed="newStartTo"
          />
        </v-list-tile>

        <v-list-tile v-if="isRenew" class="list-item">
          <date-picker
            v-model="newEndFrom"
            :label="$t('New contract end date (From)')"
            classes="filter-width"
            :changed="newEndFrom"
          />
          <date-picker
            v-model="newEndTo"
            :label="$t('New contract end date (To)')"
            classes="filter-width"
            :changed="newEndTo"
          />
        </v-list-tile>

        <v-list-tile v-if="isTerminate" class="list-item">
          <date-picker
            v-model="terminateFrom"
            :label="$t('Terminate date (From)')"
            classes="filter-width"
            :changed="terminateFrom"
          />
          <date-picker
            v-model="terminateTo"
            :label="$t('Terminate date (To)')"
            classes="filter-width"
            :changed="terminateTo"
          />
        </v-list-tile>

        <v-list-tile v-if="isManual" class="list-item">
          <v-select
            v-model="paymentMonth"
            :label="$t('Month')"
            :items="months"
          />
        </v-list-tile>
        <v-list-tile v-if="isTenant" class="list-item">
          <v-text-field v-model="civilId" :label="$t('Civil ID')" />
        </v-list-tile>
        <v-list-tile v-if="isSuspended || isTenant" class="list-item">
          <v-text-field v-model="reference" :label="$t('Contract Reference')" />
        </v-list-tile>

        <v-list-tile v-if="isNew" class="list-item">
          <v-checkbox
            v-model="is_suspended"
            :label="$t('Is Suspended')"
            :false-value="null"
            :true-value="true"
          />
        </v-list-tile>
        <v-list-tile v-if="isTenant">
          <v-select
            v-model="is_suspended"
            :label="$t('Is Suspended')"
            item-text="label"
            item-value="value"
            :items="suspendFilter2"
          />
        </v-list-tile>

        <v-list-tile v-if="isManual" class="list-item">
          <v-text-field v-model="paymentYear" :label="$t('Year')" />
        </v-list-tile>
      </v-list>

      <v-card-actions>
        <v-spacer />

        <v-btn
          dark
          class="re-orangtbtn"
          @click="
            $emit('fetchResult');
            menu = false;
          "
          >{{ $t("Search") }}</v-btn
        >
        <v-btn flat @click="reset" class="re-gray-btn re-box-shadow">
          {{ $t("Reset") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import debounce from "lodash/debounce";

import DatePicker from "../../../components/picker/DatePicker.vue";
import { getMonthLabels } from "../../../lib/date_time";
import profileService from "../../../services/plugins/profile";
import propertyService from "../../../services/plugins/properties";
import propertyClassificationService from "../../../services/plugins/property_classification";
import tenantService from "../../../services/plugins/tenant";
import unitTypeService from "../../../services/plugins/unit_type";
import userService from "../../../services/plugins/user";

export default {
  name: "contract-list-filter",
  emits: ["input"],
  props: {
    value: Object,
    filterType: {
      default: "new_contract",
      type: String
    }
  },
  components: {
    DatePicker
  },
  data() {
    return {
      menu: false,
      filter: {},
      properties: [],
      unitTypes: [],
      initiators: [],
      tenants: [],
      propertyClassifications: [],
      profiles: [],
      months: getMonthLabels(),
      isLoadingOptions: false,
      fetchInitiator: null,
      fetchProperties: null,
      fetchTenants: null,
      fetchPropertyClassifications: null,
      fetchProfiles: null
    };
  },
  mounted() {
    this.fetchProperties = debounce(this.loadroperties, 500);
    this.fetchTenants = debounce(this.loadTenants, 500);
    this.fetchInitiator = debounce(this.loadInitiator, 500);
    this.fetchPropertyClassifications = debounce(
      this.loadPropertyClassifications,
      500
    );
    this.fetchProfiles = debounce(this.loadProfiles, 500);
    this.fetchProperties();
    if (this.isTenant) {
      this.fetchPropertyClassifications();
      this.fetchProfiles();
    }
    this.unitsRequired && this.fetchUnitTypes();
    !this.isEditPrice && this.fetchTenants();
    !this.isTenant && this.fetchInitiator();
  },
  computed: {
    isRenew() {
      return this.filterType === "is_renew";
    },
    isTenant() {
      return this.filterType === "is_tenant";
    },
    isNew() {
      return this.filterType === "new_contract";
    },
    isTerminate() {
      return this.filterType === "terminate";
    },
    isEditPrice() {
      return this.filterType === "edit_price";
    },
    isAdvance() {
      return this.filterType === "advance_payment";
    },
    isManual() {
      return this.filterType === "manual_payment";
    },
    isModified() {
      return this.filterType === "is_modified";
    },
    isSuspended() {
      return this.filterType === "is_suspended";
    },
    unitsRequired() {
      return (
        !this.isTenant &&
        !this.isModified &&
        !this.isSuspended &&
        !this.isAdvance
      );
    },
    initiator: {
      get() {
        return this.value.initiator;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, initiator: value });
      }
    },
    suspendFilter() {
      return [
        {
          label: this.$t("Is Suspended"),
          value: true
        },
        {
          label: this.$t("Is Resumed"),
          value: false
        },
        {
          label: this.$t("Both suspended and resumed"),
          value: "all"
        }
      ];
    },
    suspendFilter2() {
      return [
        {
          label: this.$t("Suspended"),
          value: true
        },
        {
          label: this.$t("Not Suspended"),
          value: false
        },
        {
          label: this.$t("All"),
          value: ""
        }
      ];
    },
    statusFilter() {
      return [
        { label: this.$t("Historical"), value: "historical" },
        {
          label: this.$t("Running"),
          value: "running"
        },
        { label: this.$t("All"), value: "" }
      ];
    },
    createdFrom: {
      get() {
        return this.value.createdFrom;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, createdFrom: value });
      }
    },
    createdTo: {
      get() {
        return this.value.createdTo;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, createdTo: value });
      }
    },
    modifiedFrom: {
      get() {
        return this.value.modifiedFrom;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, modifiedFrom: value });
      }
    },
    modifiedTo: {
      get() {
        return this.value.modifiedTo;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, modifiedTo: value });
      }
    },
    property: {
      get() {
        return this.value.property;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, property: value });
      }
    },
    profile: {
      get() {
        return this.value.profile;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, profile: value });
      }
    },
    propertyClassification: {
      get() {
        return this.value.propertyClassification;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, propertyClassification: value });
      }
    },
    unittype: {
      get() {
        return this.value.unittype;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, unittype: value });
      }
    },
    is_suspended: {
      get() {
        return this.value.is_suspended;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, is_suspended: value });
      }
    },
    is_resumed: {
      get() {
        return this.value.is_resumed;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, is_resumed: value });
      }
    },
    startFrom: {
      get() {
        return this.value.startFrom;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, startFrom: value });
      }
    },
    startTo: {
      get() {
        return this.value.startTo;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, startTo: value });
      }
    },
    endFrom: {
      get() {
        return this.value.endFrom;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, endFrom: value });
      }
    },
    endTo: {
      get() {
        return this.value.endTo;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, endTo: value });
      }
    },
    tenant: {
      get() {
        return this.value.tenant;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, tenant: value });
      }
    },
    newStartFrom: {
      get() {
        return this.value.newStartFrom;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, newStartFrom: value });
      }
    },
    newStartTo: {
      get() {
        return this.value.newStartTo;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, newStartTo: value });
      }
    },
    newEndFrom: {
      get() {
        return this.value.newEndFrom;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, newEndFrom: value });
      }
    },
    newEndTo: {
      get() {
        return this.value.newEndTo;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, newEndTo: value });
      }
    },
    terminateFrom: {
      get() {
        return this.value.terminateFrom;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, terminateFrom: value });
      }
    },
    terminateTo: {
      get() {
        return this.value.terminateTo;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, terminateTo: value });
      }
    },
    paymentMonth: {
      get() {
        return this.value.paymentMonth;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, paymentMonth: value });
      }
    },
    paymentYear: {
      get() {
        return this.value.paymentYear;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, paymentYear: value });
      }
    },
    reference: {
      get() {
        return this.value.reference;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, reference: value });
      }
    },
    civilId: {
      get() {
        return this.value.civilId;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, civilId: value });
      }
    },
    suspended_resumed: {
      get() {
        return this.value.suspended_resumed;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, suspended_resumed: value });
      }
    },
    status: {
      get() {
        return this.value.status;
      },
      async set(value) {
        await this.$nextTick();
        this.$emit("input", { ...this.value, status: value });
      }
    }
  },
  methods: {
    reset() {
      let input = {};
      if (this.isSuspended) {
        input = {
          suspended_resumed: "all"
        };
      }
      this.$emit("input", input);
      this.$emit("fetchResult");
      this.menu = false;
    },
    async loadroperties(event) {
      try {
        this.isLoadingOptions = true;

        let params = {
          limit: 10,
          offset: 0
        };

        if (event) {
          params = {
            ...params,
            search: event.target.value || ""
          };
        }
        const resp = await this.$processReq(
          propertyService.getPropertiesList(params),
          false
        );

        this.properties = resp.data.body.results;
        this.isLoadingOptions = false;
      } catch (err) {
        this.isLoadingOptions = false;
        console.log("Error: Unable to get properties list - ", err);
      }
    },
    async fetchUnitTypes(event) {
      try {
        let params = {
          limit: 10,
          offset: 0
        };

        if (event) {
          params = {
            ...params,
            search: event.target.value || ""
          };
        }

        const resp = await this.$processReq(
          unitTypeService.getUnitTypeList(params),
          false
        );
        this.unitTypes = resp.data.body.results;
      } catch (err) {
        console.log("Error: Unable to get unittype list - ", err);
      }
    },
    async loadInitiator(event) {
      try {
        this.isLoadingOptions = true;
        let params = {
          limit: 10,
          offset: 0
        };

        if (event) {
          params = {
            ...params,
            search: event.target.value || ""
          };
        }

        const resp = await this.$processReq(
          userService.getUserList(params),
          false
        );

        this.initiators = resp.data.body.results;
        this.isLoadingOptions = false;
      } catch (err) {
        this.isLoadingOptions = false;
        console.log("Error: Unable to get user list - ", err);
      }
    },
    async loadTenants(event) {
      try {
        this.isLoadingOptions = true;
        let params = {
          limit: 10,
          offset: 0
        };

        if (event) {
          params = {
            ...params,
            search: event.target.value || ""
          };
        }

        const resp = await this.$processReq(
          tenantService.getTenantsList(params),
          false
        );

        this.tenants = resp.data.body.results;
        this.isLoadingOptions = false;
      } catch (err) {
        this.isLoadingOptions = false;
        console.log("Error: Unable to get tenant list - ", err);
      }
    },
    async loadPropertyClassifications(event) {
      try {
        this.isLoadingOptions = true;

        let params = {
          limit: 10,
          offset: 0
        };

        if (event) {
          params = {
            ...params,
            search: event.target.value || ""
          };
        }
        const resp = await this.$processReq(
          propertyClassificationService.getPropertyClassificationList(params),
          false
        );

        this.propertyClassifications = resp.data.body.results;
        this.isLoadingOptions = false;
      } catch (err) {
        this.isLoadingOptions = false;
        console.log(
          "Error: Unable to get property classifications list - ",
          err
        );
      }
    },
    async loadProfiles(event) {
      try {
        this.isLoadingOptions = true;

        let params = {
          limit: 10,
          offset: 0
        };

        if (event) {
          params = {
            ...params,
            search: event.target.value || ""
          };
        }
        const resp = await this.$processReq(
          profileService.getProfileList(params),
          false
        );

        this.profiles = resp.data.body.results;
        this.isLoadingOptions = false;
      } catch (err) {
        this.isLoadingOptions = false;
        console.log(
          "Error: Unable to get property classifications list - ",
          err
        );
      }
    }
  }
};
</script>
