<template>
  <v-layout row justify-center>
    <v-dialog v-model="showDialog" persistent max-width="75%">
      <v-card>
        <v-card-title class="headline re-card-title">{{
          $t("Tenants Records Validation")
        }}</v-card-title>
        <v-card-text v-if="isLoading">
          {{ $t("Please stand by") }}
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
        <v-card-text v-if="!isLoading && !!notValidRecords.length">
          <h4>
            {{
              $t(
                `These records contain invalid values. Please review and update the necessary information in the XLS file before re-uploading`
              )
            }}
          </h4>
          <v-layout row justify-center>
            <v-data-table
              hide-actions
              class="width100"
              :headers="headers"
              :items="notValidRecords"
              :no-data-text="$t('No data available')"
            >
              <template v-slot:items="props">
                <tr>
                  <td class="text-xs-center">
                    <div>{{ props.item.record_number }}</div>
                  </td>
                  <td class="text-xs-center">
                    <div>
                      {{
                        getCorrespondingFieldValue(props.item, "Tenant type")
                      }}
                      <span class="error--text">
                        {{
                          getCorrespondingFieldErrorMessage(
                            props.index,
                            "Tenant type"
                          )
                        }}
                      </span>
                    </div>
                  </td>
                  <td class="text-xs-center">
                    <div>
                      {{
                        getCorrespondingFieldValue(props.item, "Name English")
                      }}
                      <span class="error--text">
                        {{
                          getCorrespondingFieldErrorMessage(
                            props.index,
                            "Name English"
                          )
                        }}
                      </span>
                    </div>
                  </td>

                  <td class="text-xs-center">
                    <div>
                      {{
                        getCorrespondingFieldValue(props.item, "Name Arabic")
                      }}
                      <span class="error--text">
                        {{
                          getCorrespondingFieldErrorMessage(
                            props.index,
                            "Name Arabic"
                          )
                        }}
                      </span>
                    </div>
                  </td>
                  <td class="text-xs-center">
                    <div>
                      {{ getCorrespondingFieldValue(props.item, "Phone") }}
                      <span class="error--text">
                        {{
                          getCorrespondingFieldErrorMessage(
                            props.index,
                            "Phone"
                          )
                        }}
                      </span>
                    </div>
                  </td>
                  <td class="text-xs-center">
                    <div>
                      {{ getCorrespondingFieldValue(props.item, "Email") }}
                      <span class="error--text">
                        {{
                          getCorrespondingFieldErrorMessage(
                            props.index,
                            "Email"
                          )
                        }}
                      </span>
                    </div>
                  </td>
                  <td class="text-xs-center">
                    <div>
                      {{
                        getCorrespondingFieldValue(
                          props.item,
                          "Nationality(List of nationalities in ottu core)"
                        )
                      }}
                      <span class="error--text">
                        {{
                          getCorrespondingFieldErrorMessage(
                            props.index,
                            "Nationality(List of nationalities in ottu core)"
                          )
                        }}
                      </span>
                    </div>
                  </td>
                  <td class="text-xs-center">
                    <div>
                      {{ getCorrespondingFieldValue(props.item, "Civil ID") }}
                      <span class="error--text">
                        {{
                          getCorrespondingFieldErrorMessage(
                            props.index,
                            "Civil ID"
                          )
                        }}
                      </span>
                    </div>
                  </td>
                  <td class="text-xs-center">
                    <div>
                      {{
                        getCorrespondingFieldValue(
                          props.item,
                          "Commercial registration no"
                        )
                      }}
                      <span class="error--text">
                        {{
                          getCorrespondingFieldErrorMessage(
                            props.index,
                            "Commercial registration no"
                          )
                        }}
                      </span>
                    </div>
                  </td>
                  <td class="text-xs-center">
                    <div>
                      {{
                        getCorrespondingFieldValue(
                          props.item,
                          "Notification language"
                        )
                      }}
                      <span class="error--text">
                        {{
                          getCorrespondingFieldErrorMessage(
                            props.index,
                            "Notification language"
                          )
                        }}
                      </span>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-layout>
        </v-card-text>
        <v-card-text v-if="!isLoading && singleErrorMessage">
          <h3>
            {{
              $t(
                "Please, Check out the file extention and make sure you are using the correct XLS Sample"
              )
            }}
          </h3>
          <h4 class="error--text">
            {{ singleErrorMessage }}
          </h4>
        </v-card-text>
        <v-card-text v-if="!isLoading && isSucceed">
          <h3 class="text-xs-center">
            {{ $t("File has been imported successfully") }}
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-layout row justify-center>
            <v-btn
              color="primary"
              v-if="!isLoading && isSucceed"
              flat
              @click="closeAndRefetch"
              >Done</v-btn
            >
            <v-btn
              color="primary"
              v-if="!isLoading && !isSucceed"
              flat
              @click="closeDialog"
              >Close</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
export default {
  name: "tenants-XLS-validation-dialog",
  props: {
    notValidRecords: {
      type: Array,
      default: []
    },
    singleErrorMessage: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("Record Number"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Tenant Type"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Name English"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Name Arabic"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Phone"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Email"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Nationality"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Civil ID"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Commercial Registration Number"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Notification language"),
          align: "center",
          sortable: false
        }
      ];
    },
    isSucceed() {
      return !this.notValidRecords.length && !this.singleErrorMessage;
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    closeAndRefetch() {
      this.closeDialog();
      this.$emit("refetchTenants");
    },
    getCorrespondingFieldValue(tenantDetails, fieldName) {
      /**
       * 'nan' value is the value of column comes from backend as is when user didn't entered anything.
       * In other words, it's the empty value for a given column.
       */
      if (tenantDetails && tenantDetails[fieldName] !== "nan")
        return tenantDetails[fieldName];
      else return "";
    },
    getCorrespondingFieldErrorMessage(recordIndex, fieldName) {
      let recordErrors =
        this.notValidRecords.find((record, ind) => ind === recordIndex)
          ?.errors || [];
      let errorMessage =
        recordErrors.find(el => el.field === fieldName)?.field_error || "";
      return errorMessage;
    }
  }
};
</script>
<style lang="css" scoped>
.re-card-title {
  max-width: 100% !important;
  padding: 10px !important;
}
</style>
