<template>
  <v-menu
    :v-model="menu1"
    :close-on-content-click="false"
    :nudge-width="600"
    origin="center center"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn dark @click="fetchUnits" v-on="on" class="re-show">
        {{ totalUnitsCount }} {{ $t("Units") }}
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-data-table
          :headers="headers2"
          :items="units"
          class="re-table"
          hide-actions
          :loading="isLoading"
        >
          <template v-slot:items="data">
            <td
              class="d-sm-table-cell"
              :class="{
                'text-xs-left': !$vuetify.rtl,
                'text-xs-right': $vuetify.rtl
              }"
            >
              <v-checkbox
                v-model="selectedUnits"
                :label="data.item.unit_number"
                :value="data.item.id"
                item-value="unit_id"
                :disabled="!data.item.is_adding_contract_possible"
                :class="{
                  'hide-disabled': !data.item.is_adding_contract_possible
                }"
              >
              </v-checkbox>
            </td>
            <td class="text-xs-center d-sm-table-cell">
              {{ data.item.unittype.description }}
            </td>
            <td class="text-xs-center d-sm-table-cell">
              {{
                data.item.unittype.is_rentable_by_meter
                  ? reformatAmount(data.item.price_per_meter)
                  : reformatAmount(data.item.invoice_amount)
              }}
            </td>
            <td class="text-xs-center d-sm-table-cell">
              {{
                data.item.unittype.is_rentable_by_meter
                  ? `${data.item.occupancy_rate?.toFixed(2)}%`
                  : $t(
                      `${
                        data.item.status.charAt(0).toUpperCase() +
                        data.item.status.slice(1)
                      }`
                    )
              }}
            </td>
            <td class="text-xs-center d-sm-table-cell">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">more_vert</v-icon>
                </template>
                <v-list>
                  <span v-for="(action, index) in actions" :key="index">
                    <v-list-tile
                      :disabled="disableList(action.value, data.item)"
                      @click="$emit('handleNavigation', { action, data })"
                    >
                      <v-list-tile-title style="cursor: pointer">{{
                        action.title
                      }}</v-list-tile-title>
                    </v-list-tile>
                  </span>
                </v-list>
              </v-menu>
            </td>
          </template>
        </v-data-table>
      </v-list>
      <v-card-actions class="justify-center">
        <v-btn
          dark
          class="re-add-btn"
          @click="$emit('createContract', { selectedUnits, property })"
        >
          {{ $t("Create Contract") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { reformatAmountWithDecimals } from "../../helper";
import propertyService from "../../services/plugins/properties";
export default {
  name: "property-units",
  props: [
    "on",
    "totalUnitsCount",
    "property",
    "is_cash_payment_allowed",
    "unitsFilters"
  ],
  data() {
    return {
      menu1: false,
      isLoading: false,
      isFetchedOnce: false,
      selectedUnits: [],
      units: [],
      actions: [
        { title: this.$t("Edit Unit"), value: "edit_unit", route: "edit_unit" },
        { title: this.$t("Change Price"), route: "edit_price" }
      ]
    };
  },
  computed: {
    headers2() {
      return [
        {
          text: this.$t("Unit Number"),
          align: !this.$vuetify.rtl ? "left" : "right",
          sortable: false
        },
        { text: this.$t("Unit Description"), align: "center", sortable: false },
        { text: this.$t("Price"), align: "center", sortable: false },
        { text: this.$t("Status"), align: "center", sortable: false },
        { text: this.$t("Actions"), align: "center", sortable: false }
      ];
    }
  },
  methods: {
    disableList(value, item) {
      const isVacant = item.status === "vacant";
      if ((!this.is_cash_payment_allowed || isVacant) && value === "manual") {
        return true;
      }
      if (isVacant && (value === "renew" || value === "terminate")) {
        return true;
      }

      return false;
    },
    async fetchUnits() {
      if (!this.isFetchedOnce) {
        try {
          this.isLoading = true;
          let params = {
            ...this.unitsFilters
          };
          const resp = await this.$processReq(
            propertyService.getPopertyUnitsList(this.property, params),
            false
          );

          this.units = resp.data.body;
          this.isFetchedOnce = true;
          this.isLoading = false;
        } catch (err) {
          this.isLoading = false;
          console.log("Error: Unable to get property Units list - ", err);
        }
      }
    },
    reformatAmount(amount) {
      return reformatAmountWithDecimals(amount);
    }
  }
};
</script>

<style lang="css" scoped>
.re-show:after {
  right: -20px;
}
div[role="listitem"]:hover {
  background: #dcdcdc;
  color: orange;
}
.hide-disabled >>> .v-input--selection-controls__input {
  visibility: hidden;
}
</style>
